import { useEffect, useLayoutEffect } from "react";
import { Box, Container, LinearProgress } from "@mui/material";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";

import ChangePaymentMethodStepper from "../arrangement/ChangePaymentMethodStepper";
import ScriptLoader from "../account/ScriptLoader";

import { useNavigate } from "react-router-dom";


function PaymentMethodPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const { useGetSfs, loadSfsData, sfsLoaded } = useSfs();
  const {
    useGetArrangement,
    useGetArrangementHistory,
    loadPlanData,
    setPlan,
    planLoaded,
    lastSavedPlan,
  } = useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();
  const { arrangementHistory, isArrangementHistoryLoading } = useGetArrangementHistory();

  const navigate = useNavigate();

  useEffect(() => {
    // Ensure we have the last saved arrangement at start of the process
    setPlan({ ...lastSavedPlan });
  }, []);

  useLayoutEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    // Redirect to home and do not allow plan changes in payment window
    if (lastSavedPlan && lastSavedPlan?.inPaymentWindow === true) {
      navigate("/");
    }
  }, [lastSavedPlan]);

  useEffect(() => {
    if (
      !isAccountLoading &&
      !isArrangementLoading &&
      !isArrangementHistoryLoading &&
      !planLoaded &&
      sfsLoaded
    ) {
      loadPlanData(arrangement, arrangementHistory);
    }

    if (!sfsLoaded && !isSfsLoading) {
      loadSfsData(sfs);
    }
  }, [
    planLoaded,
    isSfsLoading,
    sfsLoaded,
    isArrangementLoading,
    isArrangementHistoryLoading,
    loadPlanData,
    loadSfsData,
    arrangement,
    arrangementHistory,
    sfs,
  ]);

  if (
    isAccountLoading || 
    isSfsLoading || 
    isArrangementLoading || 
    isArrangementHistoryLoading
  ) {
    return <LinearProgress />;
  }

  return (
    account &&
    planLoaded &&
    sfsLoaded && (
      <>
        <ScriptLoader /> {/* Load scripts based on consent */}
        <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
          <Box>
            <ChangePaymentMethodStepper
              account={account}
            />
          </Box>
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default PaymentMethodPage;
