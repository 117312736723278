import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Typography,
  Card,
  CardContent,
  Checkbox,
  Button,
} from "@mui/material";

import ScrollToTop from "../../nav/ScrollToTop";

import SupportLinksCarousel from "./SupportLinksCarousel";

/**
 * Settlement help
 * @param {*} param0
 * @returns
 */
function SettlementHelp({ handleNext, handleBack }) {

  const [ affordabilityConsent, setAffordabilityConsent ] = useState(false);

  return (
    <>
      <ScrollToTop/>
      <Box>
        {/* Card with heading and text */}
        <Typography 
          variant='h6'
          sx={{ fontWeight: "bold", pb: 3 }}
        >
          Before you confirm, we're here to help you
        </Typography>
        <Card>
          <CardContent>
            <Typography sx={{ pb: 2 }}>
              We believe you've answered some questions that signal you
              might need some assistance. We'll never ask you to repay more
              than you can afford - first and foremost, we're simply here to
              support you with this debt, and the improvement of your
              financial health.
            </Typography>
            <Typography sx={{ pb: 2 }}>
              If you feel that you're struggling with your finances, speaking with 
              someone impartial could provide you with options to help you address 
              your current balance, learn more about budgeting, understand your credit 
              file, and more - all of which are things you maybe weren’t already aware of.
            </Typography>
            <Typography>
              It can be tough to talk about money, we understand that - so,
              for independent, free advice - you may want to contact the
              following organisations:
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <SupportLinksCarousel/>
      <Box sx={{ pt: 5 }}>
        {/* Card with heading and text */}
        <Typography 
          variant='h6'
          sx={{ fontWeight: "bold", pb: 3 }}
        >
          Ready to set up your settlement plan?
        </Typography>
        <Card>
          <CardContent>
            {/* Affordability confirmation */}
            <Box id='affordability-declaration' sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  id='affordability-declaration-checkbox'
                  checked={affordabilityConsent}
                  onChange={() => {
                    setAffordabilityConsent(!affordabilityConsent);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
              <Box padding={0.5}>
                <Typography
                  id='affordability-declaration-statement'
                  component='h2'
                  variant='p'
                  sx={{ display: "inline-block", mt: 0.5, fontSize: 16 }}
                >
                  I can confirm that I have considered my need for additional support 
                  and I am happy to make a payment towards my account.
                </Typography>
              </Box>
            </Box>
            {/* End of affordability confirmation */}
          </CardContent>
        </Card>

        {/* Call to action */}
        <Stack
          sx={{
            mt: 3,
            textAlign: { xs: "center", sm: "left" },
          }}
          direction={{
            xs: "column",
            sm: "row",
          }}        
        >
          {/* Back button */}
          <Box>
            <Button
              id='back-btn'
              onClick={handleBack}
              variant='outlined'
              sx={{
                mb: { xs: 2, sm: 0 },
                mr: { xs: 0, sm: 2 },
                textTransform: "none",
                borderRadius: 0,
                border: "1px solid",
                width: { xs: "15em", lg: "20em" },
                backgroundColor: "#FFF",
                color: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  color: "#FFF",
                  border: "1px solid",
                },
              }}
            >
              Back
            </Button>
          </Box>
          {/* End of back button */}

          {/* Continue button */}
          <Box>
            <Button
              onClick={handleNext}
              variant='contained'
              disabled={!affordabilityConsent}
              sx={{
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "15em", lg: "20em" },
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Continue
            </Button>
          </Box>
          {/* End of continue button */}
        </Stack>
        {/* End of call to action */}

      </Box>
    </>
  );
};

SettlementHelp.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default SettlementHelp;
