import React from "react";
import { Box, Typography, Card, Button } from "@mui/material";
import MonetaryText from "../MonetaryText";
import { useSfs } from "../../context/SfsProvider";
import ScrollToTop from "../nav/ScrollToTop";
const SfsChange = () => {
  const {
    sfsTotals,
    setSfsMode,
    setActiveStep,
    employmentStatus,
    homeStatus,
    employmentStatuses,
    homeStatuses,
  } = useSfs();

  const handleEditHomeDetails = () => {
    setSfsMode("edit");
    setActiveStep(0);
  };

  // Function to handle editing of totals
  const handleEditTotals = (idx) => {
    setSfsMode("edit");
    setActiveStep(idx + 1); // Navigate to the corresponding totals section
  };

  return (
    <><ScrollToTop />
    <Box>
      {/* Render home details section */}
      <Card sx={{ mb: 2, borderRadius: "0px" }}>
        <Box
          sx={{
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            fontWeight: "bold",
            fontSize: "14px",
            p: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>My Home Details</Typography>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box sx={{ width: "50%", display: "inline-block" }}>
            <Typography fontSize={12}>Summary</Typography>
            <Typography fontWeight={"bold"} fontSize={14}>
              {`${employmentStatuses[employmentStatus]}, ${homeStatuses[homeStatus]}...`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "inline-block",
              textAlign: "right",
            }}
          >
            <Button
              onClick={handleEditHomeDetails}
              variant='contained'
              sx={{
                mt: -1.5,
                mb: 1,
                p: 0.5,
                width: {
                  xs: "150px",
                  sm: "160px",
                  md: "170px",
                  lg: "180px",
                  xl: "190px",
                },
                textTransform: "none",
                borderRadius: 0,
                backgroundColor: "primary.light",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.dark",
                  color: "primary.light",
                },
              }}
            >
              Change
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Render totals section */}
      {sfsTotals.totals ? (
        sfsTotals.totals.map((item, idx) => (
          <Card key={idx} sx={{ mb: 2, borderRadius: "0px" }}>
            <Box
              sx={{
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
                fontWeight: "bold",
                fontSize: "14px",
                p: 1,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>{`My ${item.name}`}</Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Box sx={{ width: "50%", display: "inline-block" }}>
                <Typography fontSize={12}>Total</Typography>
                <Typography fontSize={18} fontWeight='bold'>
                  <MonetaryText value={item.value} />
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "inline-block",
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={() => handleEditTotals(idx)}
                  variant='contained'
                  sx={{
                    mt: -1.5,
                    mb: 1,
                    p: 0.5,
                    width: {
                      xs: "150px",
                      sm: "160px",
                      md: "170px",
                      lg: "180px",
                      xl: "190px",
                    },
                    textTransform: "none",
                    borderRadius: 0,
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      color: "primary.light",
                    },
                  }}
                >
                  Change
                </Button>
              </Box>
            </Box>
          </Card>
        ))
      ) : (
        <Typography>No totals available</Typography>
      )}
      </Box>
    </>
  );
};

export default SfsChange;
