import { useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";

import ArrangementDirectDebitForm from "./ArrangementDirectDebitForm";
import ArrangementCardForm from "./ArrangementCardForm";
import ArrangementStandingOrder from "./ArrangementStandingOrder";
import ArrangementCheque from "./ArrangementCheque";
import ArrangementBankTransfer from "./ArrangementBankTransfer";
import ArrangementPostalOrder from "./ArrangementPostalOrder";
import ArrangementDetails from "./ArrangementDetails";

import CreateArrangementMethodInfoCard from "./CreateArrangementMethodInfoCard";
import ScrollToTop from "../../components/nav/ScrollToTop"

import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";


 /** 
  * Arrangement method component 
 */
function ArrangementMethod({ handleNext, handleBack }) {

  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  const {
    planMode,
    setPlanMode,
    setHasArrangement,
    plan,
    setPlan,
    paymentMethods,
    savePlanData
  } = useArrangement();

  const [ consent, setConsent ] = useState(false);
  const [ currentSortCode, setCurrentSortCode ] = useState(null);
  const [ currentAccountNumber, setCurrentAccountNumber ] = useState(null);

  const [ errorMessage, setErrorMessage ] = useState(null);

  const SORT_CODE_LENGTH = 6;
  const ACCOUNT_NUMBER_LENGTH = 8;

  const saveNewArrangement = () => {
    // Triggered when Continue is pressed in step 2 when creating an arrangement
    // Validate sort code and account number
    if (plan?.paymentMethod === paymentMethods["UK DIRECT DEBIT"]) {
      if (currentSortCode === null || currentSortCode.length < SORT_CODE_LENGTH) {
        setErrorMessage(`Sort code must contain ${SORT_CODE_LENGTH} numbers.`);
        return;
      } else if (currentAccountNumber === null || currentAccountNumber.length < ACCOUNT_NUMBER_LENGTH) {
        setErrorMessage(`Account number must contain ${ACCOUNT_NUMBER_LENGTH} numbers.`);
        return;
      }
    }

    const newPlan = {
      ...plan,
      sortCode: currentSortCode,
      accountNumber: currentAccountNumber,
    }

    setPlan(newPlan);
    savePlanData(newPlan);
    setPlanMode("amend");

    setHasArrangement(true);
    handleNext();
  }

  const amendArrangement = () => {
    // Triggered when Continue is pressed in step 2 when amending an arrangement
    savePlanData(plan);
    setHasArrangement(true);
    handleNext();
  }

  return (
    <>
      <ScrollToTop />
      <Typography component='label' htmlFor='email' fontWeight={"bold"}>
        {planMode === "create"
          ? "Time to setup your payment method"
          : "Confirm new payment plan"}
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Left column */}

          {planMode === "create" ? (
            <>
              {/* Plan creation - alternate payment method forms */}
              {plan?.paymentMethod === paymentMethods["UK DIRECT DEBIT"] && <ArrangementDirectDebitForm
                consent={consent}
                setConsent={setConsent}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                currentSortCode={currentSortCode}
                setCurrentSortCode={setCurrentSortCode}
                currentAccountNumber={currentAccountNumber}
                setCurrentAccountNumber={setCurrentAccountNumber}
              />}
              {plan?.paymentMethod === paymentMethods["DEBIT CARD PAYMENT"] && <ArrangementCardForm
                consent={consent}
                setConsent={setConsent}
              />}
              {plan?.paymentMethod === paymentMethods["STANDING ORDER"] && <ArrangementStandingOrder
                account={account}
                setConsent={setConsent}
              />}
              {plan?.paymentMethod === paymentMethods["CHEQUE PAYMENT"] && <ArrangementCheque
                account={account}
                setConsent={setConsent}
              />}
              {plan?.paymentMethod === paymentMethods["DIRECT TO BANK"] && <ArrangementBankTransfer
                account={account}
                setConsent={setConsent}
              />}
              {plan?.paymentMethod === paymentMethods["POSTAL ORDER"] && <ArrangementPostalOrder
                account={account}
                setConsent={setConsent}
              />}
            </>
          ) : (
            <>
              {/* Plan amendment - show plan details */}
              <ArrangementDetails setConsent={setConsent} />
            </>
          )}

          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Right column */}
          {planMode === "create" && <CreateArrangementMethodInfoCard
            account={account}
          />}

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 5, pb: 4 }}>
            <Container sx={{ pb: 2 }}>
              <Button
                onClick={planMode === "create" ? saveNewArrangement : amendArrangement}
                variant='contained'
                disabled={!consent}
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Continue
              </Button>
            </Container>

            <Container sx={{ pb: 2 }}>
              <Button
                id='back-btn'
                onClick={handleBack}
                variant='outlined'
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  border: "1px solid",
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "#FFF",
                  color: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                    color: "#FFF",
                    border: "1px solid",
                  },
                }}
              >
                Back
              </Button>
            </Container>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  );
}

export default ArrangementMethod;
