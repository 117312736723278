import { Container, Box, Stack, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import ActionButton from "../account/ActionButton";
import ArrangementConfirmationInfoCard from "./ArrangementConfirmationInfoCard";

import { useArrangement } from "../../context/ArrangementProvider";
import ScrollToTop from "../nav/ScrollToTop";

/** 
 * The repayment plan confirmation page
 * */
function ArrangementConfirmation({ activeStep, steps }) {

  const { planMode } = useArrangement();

  return (
    <>
      <ScrollToTop />
      <Stack
        sx={{
          mt: 2,
          mb: 5,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box
          id='payment-plan-container'
          sx={{ width: "100%", textAlign: "center", pt: 2, pb: 4 }}
        >
          <CheckCircle color='success' sx={{ fontSize: 72, mb: 2 }} />

          <Typography fontWeight={"bold"}>Payment plan confirmed</Typography>

          <Typography sx={{ py: 3, px: 1 }}>
            {planMode === "create"
              ? "Thank you for setting up your repayment plan. Please see the details of your new arrangement."
              : "Thank you for updating your repayment"}
          </Typography>

          <Container sx={{ py: 2 }}>
            {
              /* Stepper process complete so render home link */
              activeStep === steps.length - 1 && (
                <ActionButton
                  id='return-home-btn'
                  buttonColor='primary.dark'
                  buttonTextColor='primary.contrastText'
                  buttonTitle='Return home'
                  hoverColor='primary.light'
                  hoverTextColor='primary.dark'
                  target='/'
                />
              )
            }
          </Container>
        </Box>

        <Box sx={{ width: "100%" }}>
          <ArrangementConfirmationInfoCard />
        </Box>
      </Stack>
    </>
  );
}

export default ArrangementConfirmation;
