import { createContext, useContext } from "react";

const CookieContext = createContext();
export const useCookie = () => useContext(CookieContext);

const CookieProvider = ({ children }) => {
  const setCookie = (cookieName, cookieValue, numExpiryDays, path) => {
    const date = new Date();
    date.setTime(date.getTime() + numExpiryDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()};`;
    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=${path}`;
  };

  const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      const currentCookie = cookieArray[i].trim();
      if (currentCookie.startsWith(name)) {
        return currentCookie.substring(name.length);
      }
    }
    return "";
  };

  const removeCookie = (cookieName, path = "/") => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
  };

  const contextValue = {
    setCookie,
    getCookie,
    removeCookie,
  };

  return (
    <CookieContext.Provider value={contextValue}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieProvider;
