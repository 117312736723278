import { createContext, useContext } from 'react';

import useSWRImmutable, { SWRConfig } from 'swr/immutable';

import { useAuth } from './AuthProvider';
import environment from '../env';

/**
 * Party context
 * */
const PartyContext = createContext();

/**
 * Party context hook
 * */
export const useParty = () => useContext(PartyContext)

/**
 * Party provider
 * @param {*} param0 
 * @returns 
 */
const PartyProvider = ({ children }) => {

  const { accessToken } = useAuth();

  /**
   * Get party SWR hook
   * @returns 
   */
  function useGetParty() {
    const url = accessToken ? `${environment.CUSTOMER_PORTAL_API}/party` : null;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": environment.CUSTOMER_PORTAL_API_KEY,
       "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get party request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get party response", { data, error, isLoading })
    return {
      party: data,
      error,
      isLoading
    }
  }
  const contextValue = {
    useGetParty
  }

  return (
    <PartyContext.Provider value={ contextValue }>
      {children}
    </PartyContext.Provider>
  );
}

export default PartyProvider;