import { Box } from "@mui/material";

import ScriptLoader from "../account/ScriptLoader";
import { useNav } from "../../context/NavProvider";

import PageNotFound from "../error/PageNotFound";

/**
 * Not found page component
 * */
function NotFoundPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  return (
    <>
      <ScriptLoader /> {/* Load scripts based on consent */}
      <Box sx={{ pt: 6, pb: 100 }}>
        <PageNotFound />
      </Box>
      {setShowFooter(true)}
    </>
  );
}

export default NotFoundPage;
