import { Stack } from "@mui/material";
import PaymentCard from "./PaymentCard";

import { useClient } from "../../../context/ClientProvider";


function PaymentCardList({ paymentTokens }) {

  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();

  function getCardBranding(name) {
    let cardBrand = null;
    for (const card of clientConfig.layout.payment.cards) {
      // Card branding found
      if (card.name === name) {
        cardBrand = card;
        break;
      };
      // Capture generic branding data
      if (card.name === "GENERIC") {
        // Capture generic branding data
        cardBrand = card;
      };
    };
    return cardBrand;
  };

  return (
    clientConfig && (
      <Stack
        sx={{
          alignItems: "left",
          justifyContent: "left",
        }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {paymentTokens.map((paymentToken) => {
          return (
          <PaymentCard
            key={paymentToken.paymentTokenId}
            paymentToken={paymentToken}
            brandImages={getCardBranding(paymentToken.card.brand)}
          />
        )})}
      </Stack>
    )
  );
};

export default PaymentCardList;