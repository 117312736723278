import { useState } from "react";
import { useAccount } from "../../context/AccountProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MainPage from '../drawer/MainPage';
import PreferencesPage from '../drawer/PreferencesPage';
import UpdateContactInfoPage from '../drawer/UpdateContactInfoPage';
import UpdateEmailPage from '../drawer/UpdateEmailPage';
import UpdateContactPreferencePage from '../drawer/UpdateContactPreferencePage'
import SuccessPage from '../drawer/SuccessPage';
import UpdateHomeNumberPage from './UpdateHomeNumberPage';
import UpdateMobileNumberPage from './UpdateMobileNumberPage';
import useAppData from "../../hooks/useAppData";
import {
  Drawer,
  useMediaQuery, 
  LinearProgress,
  Box,
  IconButton,
} from "@mui/material";
import PropTypes from 'prop-types';

const DrawerComponent = ({open, onClose, handleSignOut, selectedPreferences, isHookLoading}) => {
  const { postEmailAddress, postContactNumber, postContactPreference} = useAccount();
  const { account, clientConfig, party, contactInfo } = useAppData();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState("main"); 
  const [ successLocation, setSuccessLocation ] = useState("")
  const [email, setEmail] = useState(''); 
  const [mobileNumber, setMobileNumber] = useState('');
  const [homeNumber, setHomeNumber] = useState('');
  const [preferredContact, setPreferredContact] = useState(''); 
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const preferences = {
    email: "Email",
    letter: "Letter",
    sms: "SMS",
    phone: "Phone"
  }

  const resetContactValues = () =>{
    setEmail("");
    setMobileNumber("");
    setHomeNumber("");
    setPreferredContact("");
    setError("");
  }; 

  const handleCloseSuccess = () => {
    resetContactValues();
    setCurrentPage("main");
    onClose();
  }

  const handleOpenUpdateEmail = () => {
    setCurrentPage("updateEmail")
    setSuccessLocation("email address")
  }

  const handleOpenUpdatePreferences = () => {
    setCurrentPage("updateContactPreference")
    setSuccessLocation("contact preferences")
  }

  const handleOpenUpdateMobileNumber = () => {
    setCurrentPage("updateMobileNumber")
    setSuccessLocation("mobile number")
  }

  const handleOpenUpdateHomeNumber = () => {
    setCurrentPage("updateHomeNumber")
    setSuccessLocation("home number")
  }
  
  const handleBackToMain = () => {
    setCurrentPage("main");
  };

  const handleOpenSettings = () => {
    setPreferredContact("");
    let preference = contactInfo.contactPreference?.preferredContactMethod || "Unknown";
    try {
      preference = JSON.parse(preference);
    } catch (error) {
      console.info("Error parsing preference:", error);
    }
    setPreferredContact(preference ?? "Unknown");
    if (preference === "Unknown" | ""){
      setCurrentPage("updateContactPreference");
    }
    else{
      setCurrentPage("preferences");
    }
  };

  const handleOpenUpdateContactInfo = () => {
    resetContactValues();
    

    const mobileContact = contactInfo?.contactNumber?.find(contact => contact.type === "Mobile" & contact.status === "True")?.number || 0;
    const homeContact = contactInfo?.contactNumber?.find(contact => contact.type === "Home" & contact.status === "True")?.number || 0;
    const emailContact = Array.isArray(contactInfo?.emailAddress) ? contactInfo?.emailAddress[0].email : contactInfo?.emailAddress.email || "";
    setEmail(emailContact === "" ? "No Email" : emailContact);
    setMobileNumber(mobileContact === 0 ? "No Number" : mobileContact);
    setHomeNumber(homeContact === 0 ? "No Number" : homeContact);
    setCurrentPage("updateContactInfo");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'mobile') {
      setMobileNumber(value);
    } else if (name === 'home') {
      setHomeNumber(value);
    } else if (name === 'preferredContact'){
      setPreferredContact(value);
    }
  };

  const customerFullName = () => {
    return `${party?.customerName.prefix} ${party?.customerName.first} ${party?.customerName.last}`
  }

  const handleUpdateMobileNumber = async () => {
    setError('');
    
    const type = "Mobile";
    let currentMobileNumber = contactInfo.contactNumber?.find(contact => contact.type === type);
    
    if(!currentMobileNumber){
      const newMobileNumber = newNumberContainer(type, 3);
      currentMobileNumber = newMobileNumber;
    }

    if (!validateNumber(mobileNumber, currentMobileNumber?.number ?? 0, type)) return;

    try {
      setIsSaving(true);
      
      currentMobileNumber.number = mobileNumber;
      currentMobileNumber.phoneId = null;
      currentMobileNumber.partyId = party?.partyId;
      currentMobileNumber.type = 'Mobile';
      currentMobileNumber.status = 'True';
      currentMobileNumber.statusId = '2';
      const contactNumberRequest = {...currentMobileNumber};
      const [ isSuccess ] = await postContactNumber(contactNumberRequest);
      if (isSuccess) {
        setIsSaving(false);
        setCurrentPage("success");
      } else {
        setIsSaving(false);
        setMobileNumber('');
        setError('Failed to update mobile number.');
      }
      
    } catch (err) {
      setError(err.message || 'Failed to update mobile number.');
    }
  }
  
  const handleUpdateHomeNumber = async () => {
    setError('');
    const type = "Home";
    let currentHomeNumber = contactInfo.contactNumber?.find(contact => contact.type === type);

    if(!currentHomeNumber){
      const newHomeNumber = newNumberContainer(type, 1);
      currentHomeNumber = newHomeNumber;
    }

    if(!validateNumber(homeNumber, currentHomeNumber?.number ?? 0, type)) return;

    try {
      setIsSaving(true);
      currentHomeNumber.number = homeNumber;
      currentHomeNumber.phoneId = null;
      currentHomeNumber.partyId = party?.partyId;
      currentHomeNumber.type = 'Home';
      currentHomeNumber.statusId = '2';
      const contactNumberRequest = {...currentHomeNumber};
      const [isSuccess] = await postContactNumber(contactNumberRequest);
      if (isSuccess) {
        setIsSaving(false);
        setCurrentPage("success");
      } else {
        setIsSaving(false);
        setHomeNumber('');
        setError('Failed to update home number.');
      }
      
    } catch (err) {
      setError(err.message || 'Failed to update home number.');
    }
  }

  const newNumberContainer = (type, typeId) =>{
    return {
      accountId: party?.partyId,
      extension: 0,
      number: 0,
      type: type,
      debtor: customerFullName(),
      debtorId: party?.partyId,
      phoneId: null,
      status: 'True',
      attempts: 0,
      statusId: 2,
      typeId: typeId,
      onHold: false,
      correspondence: false,
      applyPreferences: false,
      addedDate: "2023-12-27",
      consent: null,
      phonesPreferences: null
    };
  }

  const validateNumber = (number, numberFromApi, type) => {
    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(number)){
      setError('You can only enter numbers in this field.');
      return false;
    }

    if(!number) {
      setError(`Please enter a valid ${type.toLocaleLowerCase()} number.`);
      return false;
    }

    if(number === numberFromApi) {
      setError(`Please enter a valid ${type.toLocaleLowerCase()} number.`);
      return false;
    }
    
    if (number.length < 11 || number.length > 11){
      setError('Please enter 11 digits.');
      return false;
    }
    if (type === "Mobile"){
      if(!number.toString().startsWith("07")){
        setError(`Please enter a valid ${type.toLocaleLowerCase()} number starting with zero.`);
        return false;
      }
    }
    if (type === "Home"){
      if(!number.toString().startsWith("0")){
        setError(`Please enter a valid ${type.toLocaleLowerCase()} number starting with zero.`);
        return false;
      }
    }
    return true;
  }

  const canSelectPreference = (pref) =>{
    let canSelect = false
    switch(pref) {
      case 'email':
        const validEmail = Array.isArray(contactInfo?.emailAddress) ? 
        contactInfo?.emailAddress.find(item => item.statusCd === "Good") : 
        contactInfo?.emailAddress.statusCd === "Good" || false 
        canSelect = validEmail ? true : false;
          return canSelect
      case 'sms':
        const validMobile = contactInfo?.contactNumber?.find(
          contact => contact.type === "Mobile" && 
          contact.status === "True") || false;
        canSelect = validMobile  ? true : false;
        alert(canSelect);
        return canSelect;
      case 'phone':
        const validHome = contactInfo?.contactNumber?.find(
          contact => contact.type === "Home" && 
          contact.status === "True") || false;
        canSelect = validHome  ? true : false;
        return canSelect;
      case 'letter':
        return true;
      default:
        canSelect = false;
    };
    return canSelect;
  }

  const handleUpdateContactPreference = async (selectedPreference) => {
    setError('');

   if(!canSelectPreference(selectedPreference)){
    let suffix = selectedPreference === "email" ? "address" : "number";
    setError(`You currently do not have a valid ${selectedPreference} ${suffix} on your account.`);
    return;
   } 
    try{
      setIsSaving(true);
      let debtorId = party?.partyId;
      let contactPreferenceRequest = {
        debtorId: debtorId,
        newContact: preferences[selectedPreference]
      }
      const isSuccess = await postContactPreference(contactPreferenceRequest);
      if (isSuccess) {
        setIsSaving(false);
        setCurrentPage("success");
      } else {
        setIsSaving(false);
        setError('Failed to update contact preference.');
      }
    }catch (err){
      setError(err.message || 'Failed to update contact preference.');
    }
    
  }

  const validateEmail = () => {
    if (email === contactInfo.emailAddress?.email){
      setError("Please enter a valid email address.")
      return false;
    }

    if (!email) {
      setError('Please enter a valid email address.');
      return false;
    }
    
    const emailRegex = /^(?!.*\.\.)(?!.*--)(?!\.$)(?!^\.)(?!@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return false;
    }

    if(email.length > 50){
      setError('Please enter an email address with 50 or less characters.');
      return false;
    }
    return true;
  }

  const handleUpdateEmailAddress = async () => {
    let currentEmailAddress = contactInfo.emailAddress;

    if(!currentEmailAddress.email){
      let newEmailContainer = {
        accountId: party?.partyId,
        active: true,
        comment: "",
        consentDate: new Date().toISOString(),
        consentGiven: true,
        consentSource: customerFullName(),
        correspondence: false,
        debtorAssociationId: party?.partyId,
        debtorId: party?.partyId,
        debtorName: customerFullName(),
        primary: true,
        sent: 0,
        statusCd: "Good",
        typeCd: "Home",
        writtenConsent:null
      }
      currentEmailAddress = newEmailContainer;
    }

    if(!validateEmail()){
      setEmail(""); 
      return;
    }
      
    try {
      setIsSaving(true);
      currentEmailAddress.email = email;
      currentEmailAddress.correspondence = true;
      currentEmailAddress.consentSourceId = party?.partyId;
      const contactEmailRequest = {...currentEmailAddress};
      const [isSuccess] = await postEmailAddress(contactEmailRequest)
      if (isSuccess) {
        setIsSaving(false);
        setCurrentPage("success");
      } else {
        setIsSaving(false);
        setEmail('');
        setError('Failed to update email address.');
      }
      } catch (err) {
        setError(err.message || 'Failed to update email address.');
      }
  };
    
  if (isHookLoading) {
    return <LinearProgress />;
  }
  return (
    <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        backgroundColor: '#f0f0f0',
        width: { xs: "100vw", md: 400 },
        height: { xs: "100vh", md: "100%" },
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    {isSaving && <LinearProgress />}
    {isMobile && (
      <Box sx={{ display: "flex", justifyContent: "flex-start", p: 1 }}>
        <IconButton onClick={onClose}>
          <ArrowForwardIcon sx= {{color: "primary.dark"}} />
        </IconButton>
      </Box>
    )}
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        p: 2,
      }}
    >
        {currentPage === 'main' && (
          <MainPage 
            party={party}
            account={account}
            handleOpenSettings={handleOpenSettings}
            handleOpenUpdateContactInfo={handleOpenUpdateContactInfo}
            handleSignOut={handleSignOut}
            clientConfig={clientConfig}
          />
        )}
        {currentPage === 'preferences' && (
          <PreferencesPage 
            selectedPreferences={selectedPreferences}
            handleBackToMain={handleBackToMain}
            clientConfig={clientConfig}
            preferredContact={preferredContact}
            handleOpenUpdatePreferences={handleOpenUpdatePreferences}
          />
        )}
        {currentPage === 'updateContactInfo' && (
          <UpdateContactInfoPage 
            handleOpenUpdateMobileNumber={handleOpenUpdateMobileNumber}
            handleOpenUpdateHomeNumber={handleOpenUpdateHomeNumber}
            handleOpenUpdateEmail={handleOpenUpdateEmail}
            handleBackToMain={handleBackToMain}
            email={email}
            mobileNumber={mobileNumber}
            homeNumber={homeNumber}
          />
        )}
        {currentPage === 'updateMobileNumber' && (
          <UpdateMobileNumberPage 
            handleBackToMain={handleBackToMain}
            handleInputChange={handleInputChange}
            clientConfig={clientConfig}
            handleUpdateMobileNumber={handleUpdateMobileNumber}
            error={error}
          />
        )}
        {currentPage === 'updateHomeNumber' && (
          <UpdateHomeNumberPage 
            handleBackToMain={handleBackToMain}
            handleInputChange={handleInputChange}
            clientConfig={clientConfig}
            handleUpdateHomeNumber={handleUpdateHomeNumber}
            error={error}
          />
        )}
        {currentPage === 'updateEmail' && (
          <UpdateEmailPage 
            handleBackToMain={handleBackToMain}
            handleUpdateEmailAddress={handleUpdateEmailAddress}
            handleInputChange={handleInputChange}
            clientConfig={clientConfig}
            error={error}
            email={email}
          />
        )}
        {currentPage === 'updateContactPreference' && (
          <UpdateContactPreferencePage 
            handleBackToMain={handleBackToMain}
            clientConfig={clientConfig}
            error={error}
            handleUpdateContactPreference ={handleUpdateContactPreference}
            selectedPreferences={selectedPreferences}
            preferredContact={preferredContact}
            handleInputChange={handleInputChange}
          />
        )}
        {currentPage === 'success' && (
          <SuccessPage 
            handleBackToMain={handleBackToMain}
            successLocation={successLocation}
            clientConfig={clientConfig}
            handleCloseSuccess={handleCloseSuccess}
            
          />
        )}
      </Box>
    </Drawer>
  );
};
DrawerComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  selectedPreferences: PropTypes.array,
  isHookLoading: PropTypes.bool
};

export default DrawerComponent;