import React from "react";
import { Box, Typography } from "@mui/material";
import { useClient } from "../../context/ClientProvider";
import Container from "@mui/material/Container";
import { useAccount } from "../../context/AccountProvider";
import { useParty } from "../../context/PartyProvider";

/**
 * Banner component
 * @returns
 */
export default function Banner() {
  const { useGetAccount } = useAccount();
  const { useGetParty } = useParty();

  const { account } = useGetAccount();
  const { party } = useGetParty();

  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();
  const bannerContainer = {
    backgroundSize: "cover",
    backgroundPosition: "center right",
    width: "100vw",
    maxWidth: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "11%",
  };

  return (
    <Container
      disableGutters
      style={bannerContainer}
      sx={{
        backgroundImage: {
          md: `url(${clientConfig?.layout.auth.banner})`,
          xs: `url(${clientConfig?.layout.auth.banner})`,
        },
        height: 150,
        backgroundSize: "cover",
        backgroundPosition: "center right",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box sx={{ pb: 2 }}>
        <Typography color={"white"} fontSize={34} fontWeight={"bold"}>
          Hi {party?.customerName.first}
        </Typography>
        <Typography color={"white"}>
          About my{" "}
          <Typography variant='span' color='white' fontWeight={"500"}>
            {account?.creditorName}
          </Typography>{" "}
          account
        </Typography>
      </Box>
    </Container>
  );
}
