import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate } from "react-router-dom";

const ChangePaymentMethodConfirmation = () => {

  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 10 }}>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon sx={{ mb: 4, fontSize: "82px", color: "#30CC00" }} />
        <Typography marginBottom={1} fontWeight={600} variant='h5' gutterBottom>
          Payment details updated
        </Typography>
        <Typography marginBottom={4} variant='subtitle1' gutterBottom>
          Thank you for updating your payment details{" "}
        </Typography>
        <Button
          variant='contained'
          sx={{
            borderRadius: 0,
            width: "320px",
            backgroundColor: "primary.dark",
          }}
          onClick={() => {navigate("/")}}
        >
          Return Home
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePaymentMethodConfirmation;