import { useState } from "react";
import { Box, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

import { useClient } from "../../context/ClientProvider";


export default function MaintenancePage() {

  const ACCESS_COUNT = 10;
  const [ accessCounter, setAccessCounter ] = useState(0);

  const { useGetConfig, setMaintenanceMode } = useClient();
  const { clientConfig } = useGetConfig();

  const handleAccessClick = () => {
    const newCount = accessCounter + 1;
    setAccessCounter(newCount);
    if (newCount === ACCESS_COUNT) {
      setMaintenanceMode(false);
    };
  }; 

  return clientConfig && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        borderBottom: "15px solid #005969",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${clientConfig?.layout?.auth?.banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          margin: "0",
          display: "flex",
          height: 110,
        }}
      >
        <CardMedia
          component='img'
          src={clientConfig?.layout?.header?.logo}
          alt='logo'
          sx={{
            filter: "invert(100%)",
            margin: "auto",
            color: "#FFF",
            width: "200px"
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Box>
          <Typography sx={{ color: "primary.dark", fontWeight: "bold" }} variant='h2'>
            Maintenance Alert!
          </Typography>
        </Box>
        <Box sx={{ pt: "5px" }}>
          <Typography>
            We're currently performing some essential maintenance on our website.
            Our tech wizards are hard at work behind the scenes to enhance your
            experience.
          </Typography>
        </Box>
        <Box sx={{ pt: "5px" }}>
          <Typography>
            Please bear with us during this period of time. We promise to be back
            online shortly, better and brighter than ever!
          </Typography>
        </Box>

        <CardMedia
          component='img'
          src={clientConfig?.maintenance?.image}
          alt='middle icon'
          sx={{ width: "200px", margin: "20px 0" }}
        />

        <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
          Thanks for your patience & understanding.
        </Typography>
      </Box>
      <Box onClick={() => handleAccessClick()} sx={{ height: "10px", width: "10px" }} />
    </Box>
  );
}
