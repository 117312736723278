import PropTypes from "prop-types";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Checkbox,
} from "@mui/material";

import ScrollToTop from "../../nav/ScrollToTop";
import SettlementForm from "./SettlementForm";
import SettlementInfoCard from "./SettlementInfoCard";
import SettlementHelp from "./SettlementHelp";

import { useSettlement } from "../../../context/SettlementProvider";

/**
 * Settlement setup component
 * */
function SettlementSetup({
  createSettlementConsent,
  setCreateSettlementConsent,
  handleAffordabilityNext,
  handleNext,
  handleBack,
  showSettlementHelp
}) {
  const {
    affordabilityQuestion1,
    affordabilityQuestion2,
    affordabilityQuestion3,
  } = useSettlement();

  const disableContinue = () => {
      // Have affordabilty questions been answered?
    if (
      affordabilityQuestion1 &&
      affordabilityQuestion2 &&
      affordabilityQuestion3
    ) {
      // Has confirmation been checked?
      if (createSettlementConsent) {
        return false;
      }
    };
    return true;
  };

  return !showSettlementHelp ? (
    <>
      <ScrollToTop />
      <Typography
        variant="h5"
        component="label"
        htmlFor="email"
        fontWeight={"bold"}
      >
        Settlement Plan
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Left column */}
          <SettlementForm />
          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Right column */}
          <SettlementInfoCard />

          {/* Affordability confirmation */}
          <Box id='declaration' sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                id='declaration-checkbox'
                checked={createSettlementConsent}
                onChange={() => {
                  setCreateSettlementConsent(!createSettlementConsent);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            <Box padding={0.5}>
              <Typography
                id='declaration-statement'
                component='h2'
                variant='p'
                sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
              >
                I can confirm that the settlement offer I am accepting is affordable and I
                have taken into account other debts & priority arrears.
              </Typography>
            </Box>
          </Box>
          {/* End of affordability confirmation */}

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 5, pb: 4 }}>
            <Container sx={{ pb: 2 }}>
              <Button
                id='continue-btn'
                onClick={handleAffordabilityNext}
                variant='contained'
                disabled={disableContinue()}
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Continue
              </Button>
            </Container>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  ) : <SettlementHelp handleNext={handleNext} handleBack={handleBack}/>;
};

SettlementSetup.propTypes = {
  createSettlementConsent: PropTypes.bool,
  setCreateSettlementConsent: PropTypes.func,
  handleAffordabilityNext: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  showSettlementHelp: PropTypes.bool,
};

export default SettlementSetup;
