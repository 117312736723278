import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import PaymentAmount from "./PaymentAmount";
import PaymentFrame from "./PaymentFrame";
import PaymentConfirmation from "./PaymentConfirmation";

import { useUtils } from '../../context/UtilsProvider';
import { useAuth } from '../../context/AuthProvider';
import { usePayment } from "../../context/PaymentProvider";
import { useAccount } from "../../context/AccountProvider";

/**
 * Payment Stepper component
 * @returns  
 */
export default function PaymentStepper() {

  const { publicIpAddress } = useUtils();
  const { sessionId } = useAuth();
  const { useGetAccount } = useAccount();
  const { account } = useGetAccount()

  const {
    activeStep,
    setActiveStep,
    paymentSteps,
    paymentAmount,
    updatePaymentAmount,
    paymentTokens,
  } = usePayment();

  const navigate = useNavigate();

  const [isComplete, setIsComplete] = useState(false);

  // State for payment token management
  const [ selectedCard, setSelectedCard ] = useState(null);
  const [ saveNewCard, setSaveNewCard ] = useState(false);
  const [ paymentRequest, setPaymentRequest ] = useState(null);

  useEffect(() => {
    // Initialise default payment request body
    const newPaymentRequest = {
      "accountId": account?.accountId?.toString(),
      "amount": 0,
      "currencyCode": "GBP",
      "shopperId": account?.accountId?.toString(),
      "sessionId": sessionId,
      "shopperIpAddress": publicIpAddress,
    };
    // Add tokenOptIn to request body if no saved cards
    if (paymentTokens.length === 0) {
      newPaymentRequest["tokenOptIn"] = "ask";
    };
    setPaymentRequest(newPaymentRequest);
  }, []);

  useEffect(() => {
    // Update payment request body when payment amount changes
    if (paymentRequest) {
      setPaymentRequest({
        ...paymentRequest,
        amount: Math.round(paymentAmount*100),
      });
    }
  }, [paymentAmount]);

  useEffect(() => {
    // Update payment request body when a payment card is selected
    if (selectedCard && paymentRequest) {
      setPaymentRequest({
        ...paymentRequest,
        paymentTokenId: selectedCard.paymentTokenId,
      });
    }
  }, [selectedCard]);

  useEffect(() => {
    // Update payment request body when save new card is clicked
    if (selectedCard && paymentRequest) {
      const newPaymentRequest = { ...paymentRequest };
      if (saveNewCard) {
        delete newPaymentRequest.paymentTokenId;
        setPaymentRequest({ ...newPaymentRequest, tokenOptIn: "ask" });
      } else if (!saveNewCard) {
        delete newPaymentRequest.tokenOptIn;
        setPaymentRequest({ ...newPaymentRequest, paymentTokenId: selectedCard.paymentTokenId });
      }
    }
  }, [saveNewCard]);

  {/* Test Logging */}
  useEffect(() => {
    console.log("STEPPER PAYMENT REQUEST: ", paymentRequest);
  }, [paymentRequest]);

  useEffect(() => {
    if (selectedCard) {
      console.log("STEPPER SELECTED CARD: ", selectedCard.paymentTokenId);
    };
  }, [selectedCard]);
  {/* Test Logging End */}

  useEffect(() => {
    if (isComplete) {
      navigate('/');
      window.location.reload();
    }
  }, [isComplete, navigate]);

  /**
   * Handle stepper next button
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Handle stepper back button
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Handle complete button
   */
  const handleComplete = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsComplete(true);  // Trigger the completion state
  };

  return account && (
    <Box sx={{ width: "100%", marginTop: 2, marginBottom: 35 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          my: 3,
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {paymentSteps.map((step) => (
          <Step id={step.id} key={step.title}>
            <StepLabel
              StepIconProps={{
                sx: {
                  fontSize: "45px",
                  "&.Mui-active": {
                    color: "primary.dark",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              sx={{
                "& .MuiStepLabel-labelContainer": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .MuiStepLabel-root": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              {step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ textAlign: 'center' }}>
        <>
          {activeStep === 0 && (
            <PaymentAmount
              handleNext={handleNext}
              balance={account.currentBalance || 0}
              paymentAmount={paymentAmount}
              updatePaymentAmount={updatePaymentAmount}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              saveNewCard={saveNewCard}
              setSaveNewCard={setSaveNewCard}
            />
          )}

          {activeStep === 1 && (
            <PaymentFrame
              handleNext={handleNext}
              paymentRequest={paymentRequest}
            />
          )}

          {activeStep === 2 && (
            <PaymentConfirmation
              amount={paymentAmount}
              handleComplete={handleComplete}
              accountId={account?.accountReference?.toString()}
            />
          )}
        </>
      </Box>
    </Box>
  );
}
