import { Button } from "@mui/material";

// Style applied to all buttons regardless of selected state
const baseStyle = {
  marginRight: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
  marginBottom: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
  textTransform: "none",
  borderRadius: 0,
  fontSize: { xs: 17, sm: 17, md: 18, lg: 19, xl: 16 },
  width: { xs: "100%", sm: "48%", md: "100%", lg: "48%", xl: "48%" },
};

const SfsCategoryButton = ({ category, changeCategory }) => {
  // Conditional style based on selected state
  const selectedStyle = category.selected
    ? {
        backgroundColor: "primary.dark",
        color: "white",
        borderColor: "primary.dark",
        "&:hover": {
          color: "primary.dark",
          backgroundColor: "primary.light",
          borderColor: "primary.light",
        },
      }
    : {
        color: "primary.dark",
        borderColor: "primary.dark",
        "&:hover": {
          backgroundColor: "primary.light",
          borderColor: "primary.light",
        },
      };

  return category ? (
    <Button
      id={`${category.name
        .toLowerCase()
        .replace("& ", "")
        .replace(" ", "-")}-btn`}
      onClick={() => {
        changeCategory(category);
      }}
      variant='outlined'
      sx={{
        backgroundColor: "#FFF",
        ...baseStyle,
        ...selectedStyle,
      }}
    >
      {category.name}
    </Button>
  ) : null;
};

export default SfsCategoryButton;
