import { Card, Typography, Container, Box } from "@mui/material";
import ActionButton from "./ActionButton";

/**
 * Account link card
 * @param {*} param0
 * @returns
 */
function ActionLinkCard({
  id,
  backgroundColor,
  textColor,
  title,
  description,
  disposableIncome,
  buttonId,
  buttonColor,
  reviewDate,
  buttonTextColor,
  buttonTitle,
  hoverColor,
  hoverTextColor,
  target = "/",
  icon,
}) {
  return (
    <Card
      id={id}
      sx={{
        backgroundColor: `${backgroundColor}`,
        color: `${textColor}`,
        borderRadius: 0,
      }}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            ml: 2,
            pt: 1.2,
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>

        {icon && (
          <span style={{ marginRight: 16 }} aria-label='info'>
            {icon}
          </span>
        )}
      </span>
      <Typography
        sx={{
          fontSize: 16,
          mt: 1.5,
          mx: 2,
        }}
      >
        {description}
      </Typography>
      {disposableIncome !== undefined && (
        <Typography
          sx={{
            fontSize: 16,
            mt: 1.5,
            mx: 2,
          }}
        >
          {disposableIncome >= 0
            ? "If your financial situation has changed, you can click the button below to complete a new form."
            : "If your financial situation has changed, you can click the button below to complete a new form or if you prefer, you can speak to a helpful member of our team."}
        </Typography>
      )}
      {reviewDate && <Typography>{reviewDate}</Typography>}
      <Container sx={{ textAlign: "center", pt: 2, pb: 3 }}>
        <Box sx={{ mb: 2 }}>
          {disposableIncome < 0 && (
            <ActionButton
              id={buttonId}
              buttonColor={buttonColor}
              buttonTextColor={buttonTextColor}
              buttonTitle='Call Us'
              hoverColor={hoverColor}
              hoverTextColor={hoverTextColor}
              target='tel:01737237370'
              outlined={true}
            />
          )}
        </Box>
        <ActionButton
          id={buttonId}
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
          buttonTitle={buttonTitle}
          hoverColor={hoverColor}
          hoverTextColor={hoverTextColor}
          target={target}
          outlined={true}
        />
      </Container>
    </Card>
  );
}

export default ActionLinkCard;
