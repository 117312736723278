import { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import styled from "@emotion/styled";
import useSWRMutation from "swr/mutation";
import Footer from "./Footer";
import DrawerComponent from '../drawer/DrawerComponent';
import { useNav } from "../../context/NavProvider";
import { useUtils } from "../../context/UtilsProvider";
import { useAuth } from "../../context/AuthProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import useAppData from "../../hooks/useAppData";

const AppBarStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.up("md")]: {
    backgroundColor: "#FFF",
  },
}));

function ResponsiveAppBar() {
  const { authState, authStates, useGetAuthUser } = useAuth();
  const { user, isLoading } = useGetAuthUser();
  const { navigation, settings, showFooter } = useNav();
  const { accessToken, endSession, postSignOut } = useAuth();
  const { clientConfig, account, party, mutateEmailAddress, mutateContactNumber, mutateContactPreference, contactInfo, disableProfile } = useAppData();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {
    sfsMode,
    disposableIncome,
    sfsCreatedDate,
    monthsSinceReview,
    setSfsLoaded,
  } = useSfs();
  const { planMode, planStatus, plan, setLastSavedPlan, setPlanLoaded } = useArrangement();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerKey, setDrawerKey] = useState(0);
  const { createAlert, alertSeverityTypes } = useUtils();
  const navigate = useNavigate();
  const { trigger } = useSWRMutation("/auth/signout", postSignOut);
 
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = () => {
    // Refresh the entire application
    window.location.href = "/";
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    try {
      const response = await trigger("");
      console.info("post sign out response: ", response);
      setSfsLoaded(false);
      setPlanLoaded(false);
      setLastSavedPlan(null);
      setDrawerOpen(false);
      endSession();
      createAlert("User sign out successful", alertSeverityTypes.success);
      navigate("/auth");
    } catch (error) {
      const message = "Oops! Something went wrong, please try again.";
      console.error("post sign out error", error);
      createAlert(message, alertSeverityTypes.error);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false); 
  };

  const toggleDrawer = (open) => (event) => {
    mutateContactNumber();
    mutateContactPreference();
    mutateEmailAddress();
    setDrawerKey((prevKey) => prevKey + 1); 
    
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };
  
  return (
    <>
    <AppBar position="static">
      <AppBarStyle>
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{ justifyContent: { xs: "space-between", md: "flex-start" } }}>
            {accessToken && (
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {navigation &&
                    navigation.map((item, idx) => (
                      <MenuItem key={idx} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">
                          <Link
                            id={item.id}
                            to={item.href}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                            }}
                          >
                            {item.name}
                          </Link>
                        </Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                alignItems: 'center',
                width: 130,
                maxHeight: { xs: 80, md: 100 },
                maxWidth: { xs: 130, md: 150 },
                py: 3,
                position: { xs: 'absolute', md: 'relative' },
                left: { xs: '50%', md: '0' },
                transform: { xs: 'translateX(-50%)', md: 'none' }
              }}
            >
              <Link onClick={handleClick}>
                <Box
                  component="img"
                  src={clientConfig?.layout.header.logoMobile}
                  alt="Logo"
                  sx={{
                    display: {
                      xs: "block",
                      md: "none"
                    }
                  }}
                />
                <Box
                  component="img"
                  src={clientConfig?.layout.header.logo}
                  alt="Logo"
                  sx={{
                    display: {
                      xs: "none",
                      md: "block"
                    }
                  }}
                />
              </Link>
            </Box>
            
          {accessToken && (
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: 'left' }}>
                {navigation && navigation.map((item, idx) => (
                  !item.hide && (
                    <Button
                      key={idx}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        ml: 5,
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        fontSize: { xs: 14, md: 16 },
                      }}
                    >
                      <Link
                        id={item.id}
                        to={item.href}
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        {item.name}
                      </Link>
                    </Button>
                  )
                ))}
              </Box>
            )}
            {accessToken && (
              <Box sx={{ display: "flex", ml: "auto" }}>
                {disableProfile?.required ? (
                  <Button
                    id='logout'
                    onClick={handleSignOut}
                    variant='contained'
                    sx={{
                      textTransform: "none",
                      width: { md: "7em" },
                      backgroundColor: { xs: "primary.main", md: "primary.dark"},
                      borderRadius: "0px",
                      color: "primary.contrastText",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        color: "primary.dark",
                      },
                    }}
                  >
                    Sign out
                  </Button>
                ) : (
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={toggleDrawer(true)}
                    sx={{
                      fontSize: "2rem",
                      color: {
                        xs: "white",
                        md: "black",
                      },
                    }}
                  >
                    <Person2OutlinedIcon sx={{ fontSize: "2rem" }} />
                  </IconButton>
                )}
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBarStyle>
    </AppBar>

    {accessToken && (
      <DrawerComponent
        key={drawerKey}
        open={drawerOpen}
        onClose={handleDrawerClose}
        handleSignOut={handleSignOut}
        account={account}
        party={party}
        clientConfig={clientConfig}
        toggleDrawer={toggleDrawer}
        contactInfo={contactInfo}
      />
    )}
    
    <Outlet />

    {showFooter && <Footer />}
  </>
);
}

export default ResponsiveAppBar;
