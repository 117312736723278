import Alert from "@mui/material/Alert";

function AlertMessage({ severity, message }) {
  return (
    <Alert
      severity={severity}
      variant="outlined"
    >
      {message}
    </Alert>
  )
}

export default AlertMessage;