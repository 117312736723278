import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import environment from '../env';
import { useAuth } from './AuthProvider';
import useSWRImmutable from 'swr/immutable';

const TransactionHistoryContext = createContext();

/**
 * Custom hook to use the TransactionHistoryContext
 */
export const useTransactionHistory = () => useContext(TransactionHistoryContext);

const TransactionHistoryProvider = ({ children }) => {
    const { accessToken } = useAuth();
    const [transactions, setTransactions] = useState([]);

    const apiKey = environment.CUSTOMER_PORTAL_API_KEY;
    const apiUrl = environment.CUSTOMER_PORTAL_API;

    const defaultOptions = {
        method: "GET",
        headers: {
            "x-api-key": apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error, isLoading } = useGetTransactionHistory();

    useEffect(() => {
        if (data && Array.isArray(data.transactionHistory)) {
            setTransactions(data.transactionHistory);
        }
    }, [data]);

    function useGetTransactionHistory() {
        const url = accessToken ? `${apiUrl}/transaction/history` : null;
        const { data, error, isLoading } = useSWRImmutable([url, defaultOptions]);
        return { data, error, isLoading };
    }

    const contextValue = useMemo(() => ({
        transactions,
        isLoading,
        error,
    }), [transactions, isLoading, error]);

    return (
        <TransactionHistoryContext.Provider value={contextValue}>
            {children}
        </TransactionHistoryContext.Provider>
    );
};

TransactionHistoryProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TransactionHistoryProvider;
