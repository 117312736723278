import React, { useState, useEffect } from "react";
import { TextField, Box, Typography } from "@mui/material";

const DateInput = ({ value, onChange, sx }) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  // Initialize day, month, year from value prop
  useEffect(() => {
    if (value) {
      const [initYear, initMonth, initDay] = value.split("-");
      setDay(initDay);
      setMonth(initMonth);
      setYear(initYear);
    }
  }, [value]);

  const handleDayChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,2}$/.test(inputValue)) {
      // Allows 0 to 2 digits
      setDay(inputValue);
      setError("");
    }
  };

  const handleMonthChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,2}$/.test(inputValue)) {
      // Allows 0 to 2 digits
      setMonth(inputValue);
      setError("");
    }
  };

  const handleYearChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,4}$/.test(inputValue)) {
      // Allows 0 to 4 digits
      setYear(inputValue);
      setError("");
    }
  };

  const handleBlur = () => {
    if (day && month && year) {
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      if (!isValidDate(formattedDate)) {
        setError("Invalid date");
      } else {
        setError("");
        if (onChange) {
          onChange(formattedDate);
        }
      }
    }
  };

  const isValidDate = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(dateRegex)) return false;

    const date = new Date(dateString);
    const isValid = !isNaN(date.getTime());

    return isValid;
  };

  return (
    <>
      <Box display='flex' alignItems='center' gap={1} sx={sx}>
        <TextField
          margin='dense'
          value={day}
          onChange={handleDayChange}
          onBlur={handleBlur}
          placeholder='DD'
          variant='outlined'
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            min: 1,
            max: 31,
          }}
          sx={{ width: { xs: "80px", lg: "100px" }, marginTop: "0px" }}
        />
        <TextField
          margin='dense'
          value={month}
          onChange={handleMonthChange}
          onBlur={handleBlur}
          placeholder='MM'
          variant='outlined'
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            min: 1,
            max: 12,
          }}
          sx={{
            width: { xs: "80px", lg: "100px" },
            marginLeft: "8px",
            marginTop: "0px",
          }}
        />
        <TextField
          margin='dense'
          value={year}
          onChange={handleYearChange}
          onBlur={handleBlur}
          placeholder='YYYY'
          variant='outlined'
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 1 }}
          sx={{
            width: { xs: "120px", lg: "140px" },
            marginLeft: "8px",
            marginTop: "0px",
          }}
        />
      </Box>
      <Typography sx={{ mt: "2px", color: "error.main" }}>
        {error ? `* ${error}` : null}
      </Typography>
    </>
  );
};

export default DateInput;
