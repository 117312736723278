import { Box, Typography, CardMedia, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { usePayment } from "../../../context/PaymentProvider";


function PaymentCard({ paymentToken, brandImages }) {

  const { toggleDeleteCardDialog, selectPaymentCard } = usePayment();

  const expiryMonth = paymentToken?.card?.cardExpiry?.expiryMonth;
  const expiryYear = paymentToken?.card?.cardExpiry?.expiryYear?.slice(-2);
  const expiryDate = `${expiryMonth}/${expiryYear}`;

  const handleRemoveClick = (paymentToken) => {
    selectPaymentCard(paymentToken);
    toggleDeleteCardDialog();
  };

  return (
    <Box
      key={paymentToken.paymentTokenId}
      sx={{
          position: "relative",
          mb: 4,
          backgroundImage: `url(${brandImages?.background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "150px",
          width: "250px",
          borderRadius: "10px",
          padding: 2,
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
      }}
    >
      <Typography sx={{ fontSize: "1rem", fontWeight: "bold", marginTop: 5 }}>
        {paymentToken.card.mask}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "left", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "0.85rem", lineHeight: "2rem" }}>Expiry: {expiryDate}</Typography>
      </Box>

      <CardMedia
        sx={{
            width: "38%",
            height: "18%",
            position: "absolute",
            bottom: 15,
            backgroundSize: "contain",
            marginRight: brandImages.marginRight,
            right: brandImages.right
        }}
        image={brandImages.logo}
        alt={`${paymentToken.card.brand} logo`}
      />

      <IconButton
        sx={{
            position: "absolute",
            top: 8,
            right: 8,
            width: "15px",
            height: "15px",
            textAlign: "center",
            padding: 1.5,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "white",
            },
        }}
        onClick={() => handleRemoveClick(paymentToken)}
      >
        <CloseIcon sx={{ fontSize: "16px" }} />
      </IconButton>

    </Box>
  )
};

export default PaymentCard;