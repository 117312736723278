import { LinearProgress } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { useNav } from '../../context/NavProvider';
import { Navigate } from 'react-router-dom';

export const AuthGuard = ({ children }) => {

  const { endSession, useGetAuthUser } = useAuth();
  const { user, error, isLoading } = useGetAuthUser();

  const { setShowFooter } = useNav();
  setShowFooter(false);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!user || error || user?.errorMessage) {
    endSession();
    return <Navigate to="/auth" replace />;
  }

  return children;
};