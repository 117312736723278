import { createContext, useContext } from 'react';
import environment from '../env';
import useSWRImmutable from 'swr/immutable';
import useSWR, { SWRConfig } from 'swr/immutable';
import { useAuth } from './AuthProvider';

const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext);

/**
 * Account provider
 * @param {*} param0 
 * @returns 
 */
const AccountProvider = ({ children }) => {
  const { accessToken } = useAuth();

  const registrationReasons = [
    "View & amend your payment plan 24/7",
    "Access to detailed account information",
    "24/7 support through our chat bot assistant KAI",
    "Easily update & manage personal details"
  ]

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": environment.CUSTOMER_PORTAL_API_KEY,
      "Authorization": `Bearer ${accessToken}`,
    },
  };

  /**
   * Get account SWR hook
   * @returns 
   */
  function useGetAccount() {
    const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account`;
    const { data, error, isLoading } = useSWRImmutable([url, defaultOptions]);
    return {
      account: data,
      error,
      isAccountLoading: isLoading,
    };
  }

    /**
   * Get users email address
   * @returns 
   */
    function useGetContactEmail() {
      const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/email-address`;
      const { data, mutate, error, isLoading } = useSWR([url, defaultOptions])
      return {
        emailAddress: data,
        mutate,
        error,
        isGetEmailLoading: isLoading,
      };
    }
  
    /**
   * Save user's email address
   * @param {dict} email - The email object to save
   * @returns {boolean}
   */
    async function postEmailAddress(email) {
      let requestOptions = {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify(email),
      };
      console.info("post email request: ", {email, requestOptions });
      const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/save-email-address`;
      try {
        const response = await fetch(url, requestOptions);
        
        if (response.ok) {
          const data = await response.json(); 
          console.log("post email address response: ", data);
          return [true, data]; 
        } else {
          const errorData = await response.json();
          console.log("Error response from API: ", errorData);
          return false; 
        }
      } catch (error) {
        console.log(`Error in email address: ${error}`);
        return false; 
      }
    }

    /**
     * Get users contact preferences
     * @returns 
     */
    function useGetContactPreference() {
      const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/preferred-contact`;
      const { data, mutate, error, isLoading } = useSWR([url, defaultOptions])
      return {
        contactPreference: data,
        mutate,
        error,
        isGetPreferredContactLoading: isLoading,
      };
    }

    /**
   * Save user's preferred contact
   * @param {object} contactPreference
   * @returns {boolean}
   */
    async function postContactPreference(contactPreference) {
      const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/save-preferred-contact`;
      let requestOptions = {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify(contactPreference),
      };
      console.info("post preferred contact request: ", { contactPreference });
      try {
        const response = await fetch(url, requestOptions);
        
        if (response.ok) {
          const data = await response.json(); 
          console.log("post preferred contact response: ", data);
          return true; 
        } else {
          const errorData = await response.json();
          console.log("Error response from API: ", errorData);
          return false; 
        }
      } catch (error) {
        console.log(`Error in preferred contact: ${error}`);
        return false; 
      }
    }

  /**
     * Get users contact number
     * @returns 
     */
  function useGetContactNumber() {
    const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/contact-number`;
    const { data, mutate, error, isLoading } = useSWR([url, defaultOptions])
    return {
      contactNumber: data,
      mutate,
      error,
      isGetContactNumberLoading: isLoading,
    };
  }

  /**
* Save user's contact number
* @param {dict} contactNumber - The preferred contact to save
* @returns {boolean}
*/
  async function postContactNumber(contactNumber) {
  let requestOptions = {
    ...defaultOptions,
    method: "POST",
    body: JSON.stringify(contactNumber),
  };
  console.info("post contact number request: ", {contactNumber, requestOptions });
  const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account/save-contact-number`;
  try {
    const response = await fetch(url, requestOptions);
    
    if (response.ok) {
      const data = await response.json(); 
      console.log("post contact number response: ", data);
      return [true, data]; 
    } else {
      const errorData = await response.json();
      console.log("Error response from API: ", errorData);
      return false; 
    }
  } catch (error) {
    console.log(`Error in post contact number: ${error}`);
    return false; 
  }
}

  const contextValue = {
    registrationReasons,
    useGetAccount,
    useGetContactEmail,
    postEmailAddress,
    useGetContactNumber,
    postContactNumber,
    useGetContactPreference,
    postContactPreference
  };

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
