import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

const SuccessPage = ({successLocation, clientConfig, handleCloseSuccess}) => (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh', 
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
          {`Update my ${successLocation}`}
        </Typography>

        <Box
          sx={{
            backgroundColor: '#4caf50',
            width: 100,
            height: 100,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 10px rgba(0,0,0,0.2)', 
            mb: 3,
          }}
        >
          <CheckIcon sx={{ fontSize: 60, color: '#fff' }} />
        </Box>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
          That's all done!
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          {`Thank you for updating your ${successLocation}.`}
        </Typography>
      </Box>
      <Button
        onClick={handleCloseSuccess}
        fullWidth
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: clientConfig?.theme.primary,
          "&:hover": {
            backgroundColor: clientConfig?.theme.primary,
          },
        }}
      >
        Close
      </Button>
    </Container>
)
SuccessPage.propTypes = {
  successLocation: PropTypes.string,
  clientConfig: PropTypes.object.isRequired,
  handleCloseSuccess: PropTypes.func.isRequired,
};

export default SuccessPage;
