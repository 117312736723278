import {
  Box,
  Typography,
} from "@mui/material";
import ScrollToTop from "../nav/ScrollToTop";
import { usePayment } from "../../context/PaymentProvider";
import { useAccount } from "../../context/AccountProvider";

/** 
 * The payment information card component 
 * */
const PaymentInformation = ({}) => {
  const { useGetAccount } = useAccount();
  const { supportedCards, security } = usePayment();
  const { account } = useGetAccount();

  return account && (
    <><ScrollToTop />
    <Box
      sx={{
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box
        id='payment-info-header'
        sx={{ textAlign: "left", px: 3, pb: 1, backgroundColor: "#fff" }}
      >
        <Typography
          id='original-creditor'
          fontSize={20}
          fontWeight={"bold"}
          paddingY={1}
        >
          {account.creditorName}
        </Typography>
        <Typography fontSize={13}>Reference number:</Typography>
        <Typography id='reference-num' fontSize={16} fontWeight={"bold"}>
          {account.accountReference}
        </Typography>
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          id='payment-info-note'
          component='label'
          htmlFor='balance'
          fontSize={13}
          paddingY={0.5}
        >
          <Typography
            id='payment-info-note-text'
            variant='span'
            color='black'
            fontWeight='bold'
            fontSize={16}
          >
            Note:{" "}
          </Typography>
          Where you have a repayment plan in place, we will adjust the remaining duration of any plan accordingly.
        </Typography>

        <Box id='supported-cards' fullWidth paddingY={1}>
          <Typography fontWeight='bold' fontSize={16} paddingBottom={2}>
            Supported cards
          </Typography>
          {supportedCards.map((item) => (
            <Box
              id={item.id}
              component='img'
              alt={item.name}
              src={item.img}
              sx={{
                width: {
                  xs: "100px",
                  md: `${Math.floor(100 / supportedCards.length)}%`,
                },
                display: "inline",
              }}
            />
          ))}
        </Box>

        <Box id='security' fullWidth paddingTop={2}>
          <Typography fontWeight='bold' fontSize={16} paddingBottom={1}>
            Security
          </Typography>
          {security.map((item) => (
            <Box
              id={item.id}
              component='img'
              alt={item.name}
              src={item.img}
              sx={{
                width: {
                  xs: "100px",
                  md: `${Math.floor(100 / supportedCards.length)}%`,
                },
                display: "inline",
                p: 1,
              }}
            />
          ))}
        </Box>
      </Box>
      </Box>
    </>
  );
};

export default PaymentInformation;
