import { useEffect } from "react";

import { Container } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import PaymentStepper from "../payment/PaymentStepper";

import { useNav } from "../../context/NavProvider";
import { useAccount } from "../../context/AccountProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { usePayment } from "../../context/PaymentProvider";

import ScriptLoader from "../account/ScriptLoader";
import useWorldpayScript from "../../hooks/useWorldpayScript";

/**
 * Payment page component
 * */
function PaymentPage() {

  useWorldpayScript();

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { useGetAccount } = useAccount();
  const { useGetSfs, loadSfsData, sfsLoaded, disposableIncome } = useSfs();
  const {
    useGetArrangement,
    useGetArrangementHistory,
    loadPlanData,
    planLoaded,
    plan
  } = useArrangement();

  const { account, isAccountLoading } = useGetAccount();
  const { sfs, isSfsLoading } = useGetSfs();
  const { arrangement, isArrangementLoading } = useGetArrangement();
  const { arrangementHistory, isArrangementHistoryLoading } = useGetArrangementHistory();

  const {
    useGetPaymentTokens,
    paymentTokens,
    loadPaymentTokens,
    paymentTokensLoaded,
  } = usePayment();
  const { data, isTokensLoading } = useGetPaymentTokens();

  useEffect(() => {
    if (
      !isAccountLoading &&
      !isArrangementLoading &&
      !isArrangementHistoryLoading &&
      !planLoaded &&
      sfsLoaded
    ) {
      loadPlanData(arrangement, arrangementHistory);
    }

    if (!sfsLoaded && !isSfsLoading) {
      loadSfsData(sfs);
    }

    if (!paymentTokensLoaded && !isTokensLoading) {
      loadPaymentTokens(data.tokens);
    }
  }, [
    planLoaded,
    isSfsLoading,
    sfsLoaded,
    isArrangementLoading,
    isArrangementHistoryLoading,
    loadPlanData,
    loadSfsData,
    arrangement,
    arrangementHistory,
    sfs,
    paymentTokensLoaded,
    isTokensLoading,
  ]);

  if (
    isAccountLoading || 
    isSfsLoading || 
    isArrangementLoading ||
    isArrangementHistoryLoading ||
    isTokensLoading
  ) {
    return <LinearProgress />;
  }

  return (
    account &&
    planLoaded &&
    sfsLoaded &&
    paymentTokensLoaded && (
      <>
        <ScriptLoader /> {/* Load scripts based on consent */}
        <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
          <PaymentStepper />
        </Container>
        {setShowFooter(true)}
      </>
    )
  );
}

export default PaymentPage;
