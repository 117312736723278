import {
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

import { useSettlement } from "../../../context/SettlementProvider";

function SettlementAffordability() {

  const {
    affordabilityQuestion1,
    setAffordabilityQuestion1,
    question1Options,
    affordabilityQuestion2,
    setAffordabilityQuestion2,
    question2Options,
    affordabilityQuestion3,
    setAffordabilityQuestion3,
    question3Options,
  } = useSettlement();

  return (
    <Box>
      {/* Affordability question 1 */}
      <Box sx={{ pt: 1, pb: 1, width: "100%" }}>
        <Typography fontSize={16} fontWeight='bold'>
          Are your priority bills up to date?
        </Typography>
        <Typography fontSize={14} paddingY={0.5}>
          e.g. your mortgage, your rent, and/or your energy bills such as
          your Gas, Electricity & Council Tax?
        </Typography>
      </Box>

      <Box sx={{ width: { xs: "100%", md: "49%" } }}>
        <Select
          id='affordability-question-1-select'
          fullWidth
          displayEmpty
          value={affordabilityQuestion1 || ""}
          onChange={(event) => setAffordabilityQuestion1(event.target.value)}
          size='small'
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <Typography fontWeight='bold'>Please select</Typography>
              );
            } else {
              return selected;
            }
          }}
        >
          {question1Options.map((item) => (
            <MenuItem key={item.id} id={item.id} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {/* End of affordability question 1 */}

      {/* Affordability question 2 */}
      <Box sx={{ pt: 3, pb: 1, width: "100%" }}>
        <Typography fontSize={16} fontWeight='bold'>
          What is the source of the funds you are using to pay?
        </Typography>
      </Box>

      <Box sx={{ width: { xs: "100%", md: "49%" } }}>
        <Select
          id='affordability-question-2-select'
          fullWidth
          displayEmpty
          value={affordabilityQuestion2 || ""}
          onChange={(event) => setAffordabilityQuestion2(event.target.value)}
          size='small'
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <Typography fontWeight='bold'>Please select</Typography>
              );
            } else {
              return selected;
            }
          }}
        >
          {question2Options.map((item) => (
            <MenuItem key={item.id} id={item.id} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {/* End of affordability question 2 */}

      {/* Affordability question 3 */}
      <Box sx={{ pt: 3, pb: 1, width: "100%" }}>
        <Typography fontSize={16} fontWeight='bold'>
          Are you up to date with repayments to any other creditors?
        </Typography>
        <Typography fontSize={14} paddingY={0.5}>
          e.g. are you repaying another loan at the moment or repaying other forms of lending?
        </Typography>
      </Box>

      <Box sx={{ pb: 2, width: { xs: "100%", md: "49%" } }}>
        <Select
          id='affordability-question-3-select'
          fullWidth
          displayEmpty
          value={affordabilityQuestion3 || ""}
          onChange={(event) => setAffordabilityQuestion3(event.target.value)}
          size='small'
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <Typography fontWeight='bold'>Please select</Typography>
              );
            } else {
              return selected;
            }
          }}
        >
          {question3Options.map((item) => (
            <MenuItem key={item.id} id={item.id} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {/* End of affordability question 3 */}
    </Box>
  )}
  
export default SettlementAffordability;
