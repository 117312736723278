import { NumericFormat } from "react-number-format";

const MonetaryText = ({ value }) => {
  return (
    <NumericFormat
      value={value}
      displayType="text"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      thousandsGroupStyle="thousand"
      thousandSeparator=","            
      prefix="£"
    />
  )
}

export default MonetaryText;