import PropTypes from "prop-types";
import { useEffect } from "react";
import { Box, Typography, TextField, Card, CardContent } from "@mui/material";

import { useUtils } from "../../context/UtilsProvider";
import { useClient } from "../../context/ClientProvider";

import environment from "../../env";


function ArrangementStandingOrder({ account, setConsent }) {

  const { capitaliseWords } = useUtils();
  const { clientSortCode, clientAccountNumber } = useClient();

  useEffect(() => {
    // Enable confirm button by default
    setConsent(true);
  }, []);

  return account && (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 2,
      }}
    >
      <Box>
        {/* Standing order account details */}
        <CardContent>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
            Standing order details
          </Typography>
          <Typography>
            {`Please use online banking to pay us directly using these details, quoting your ${capitaliseWords(environment?.CLIENT_NAME)}
              reference number `}
            <Typography
              variant='span'
              color='black'
              fontWeight='bold'
            >
              {account?.accountReference}.
            </Typography>
          </Typography>
          <Box
            id="standingOrderSortCode"
            sx={{ mt: 2 }}            
          >
            <Typography
              component='label'
              htmlFor='sortCode'
              fontWeight={"bold"}
            >
              Sort code
            </Typography>
            <Box>
              <TextField
                disabled
                margin='dense'
                required
                id='sortCode'
                name='sortCode'
                value={clientSortCode}
                sx={{
                  backgroundColor: "lightgrey",
                  width: { xs: "100%", md: "40%" }
                }}
              />
            </Box>
          </Box>
          <Box
            id="standingOrderAccountNumber"
            sx={{ mt: 2 }}                          
          >
            <Typography
              component='label'
              htmlFor='accountNumber'
              fontWeight={"bold"}
              sx={{ mt: 1 }}
            >
              Account number
            </Typography>
            <Box>
              <TextField
                disabled
                margin='dense'
                required
                id='accountNumber'
                name='accountNumber'
                value={clientAccountNumber}
                sx={{
                  mb: 5,
                  backgroundColor: "lightgrey",
                  width: { xs: "100%", md: "40%" }
                }}
              />
            </Box>
          </Box>
        </CardContent>
        {/* End of standing order account details */}
      </Box>
    </Card>
  );
};

ArrangementStandingOrder.propTypes = {
  account: PropTypes.object.isRequired,
  setConsent: PropTypes.func.isRequired,
};

export default ArrangementStandingOrder;