import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const UpdateEmailPage = ({ handleBackToMain, handleUpdateEmailAddress, handleInputChange, clientConfig, error, email }) => {
  
  if(email === "No Email"){
    email = ""
  }
  return(
  <Box
  sx={{
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "background.default",
    height: "100%",
  }}
>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      mb: 2,
      p: 1,
    }}
  >
    <IconButton onClick={handleBackToMain} sx={{ mr: 1 }}>
      <ArrowBackIcon sx= {{color: "primary.dark"}}/>
    </IconButton>
    <Typography variant="body1" fontWeight="bold">Update my email address</Typography>
  </Box>

  <Typography variant="body2" sx={{ mb: 2 }}>
    Please enter your new email address in the field below to update your contact information and ensure you receive all important communications.
  </Typography>
  <Typography variant="body2" fontWeight="bold"
  sx={{
    display: "flex",
    alignItems: "left",
    width: "100%",
    mb: 1
  }}>Email address</Typography>
  <TextField
    name="email" 
    label="Enter your new email address." 
    error={Boolean(error)}
    helperText={error}
    value={email}
    onChange={handleInputChange} sx={{
    display: "flex",
    alignItems: "left",
    width: "100%",
    mb: 1,
    
  }}>
  </TextField>
  <Button
        onClick={handleUpdateEmailAddress}
        fullWidth
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: clientConfig?.theme.primary,
          "&:hover": {
            backgroundColor: clientConfig?.theme.primary,
          },
        }}
      >
        Update email
      </Button>
</Box>
)};

UpdateEmailPage.propTypes = {
  handleBackToMain: PropTypes.func.isRequired, 
  handleUpdateEmailAddress: PropTypes.func.isRequired, 
  handleInputChange: PropTypes.func.isRequired, 
  clientConfig: PropTypes.object,
  error: PropTypes.string,
  email: PropTypes.string
}

export default UpdateEmailPage