import React, { useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useNav } from "../../context/NavProvider";
import { useClient } from "../../context/ClientProvider";
import ScriptLoader from "../account/ScriptLoader";

function ContactUsPage() {
  const { setShowFooter } = useNav();
  const {
    intro,
    openingHours,
    clientEmail,
    clientTelephoneNumber,
    clientAddress,
  } = useClient();

  useEffect(() => {
    setShowFooter(true);
  }, [setShowFooter]);

  return (
    <>
      <ScriptLoader />
      <Container sx={{ mt: 4, mb: 7 }}>
        <Box sx={{ pb: 2 }}>
          <Skeleton variant='rectangular' xs={12} height={300} />

          <Typography
            variant='h1'
            fontWeight='bold'
            fontSize={32}
            sx={{ py: 4 }}
          >
            Contact us
          </Typography>

          {intro.map((item, index) => (
            <Typography key={index} marginBottom={2}>
              {item}
            </Typography>
          ))}

          <Typography
            variant='h2'
            fontWeight='bold'
            fontSize={26}
            sx={{ py: 2 }}
          >
            Opening hours
          </Typography>
          <TableContainer sx={{ pb: 5 }}>
            <Table
              sx={{ maxWidth: "25em", borderRadius: "50%" }}
              aria-label='opening hours table'
            >
              <TableHead sx={{ backgroundColor: "primary.dark" }}>
                <TableRow sx={{ "td, th": { color: "primary.contrastText" } }}>
                  <TableCell>Day</TableCell>
                  <TableCell>Opening Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {openingHours.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "td, th": { border: "1px solid lightgrey" } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.days}
                    </TableCell>
                    <TableCell>{row.time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant='h2' fontWeight='bold' fontSize={26}>
            Would you prefer to talk?
          </Typography>
          <Typography sx={{ pt: 1 }}>
            If you would like to speak to one of our customer service
            representatives, please call us at:
          </Typography>
          <Typography color={"error.dark"} sx={{ pb: 4 }}>
            {clientTelephoneNumber}
          </Typography>

          <Typography variant='h2' fontWeight='bold' fontSize={26}>
            Email us
          </Typography>
          <Typography sx={{ pt: 1 }}>
            If you'd like to discuss your account with us by email, you can do
            so by emailing:
          </Typography>
          <Typography color={"error.dark"} sx={{ pb: 4 }}>
            {clientEmail}
          </Typography>

          <Typography variant='h2' fontWeight='bold' fontSize={26}>
            Write to us
          </Typography>
          <Typography sx={{ py: 1 }}>
            If you wish to write to us, you can do so by letter to the following
            address:
          </Typography>
          {clientAddress.map((item, index) => (
            <Typography key={index} fontWeight={"bold"}>
              {item}
            </Typography>
          ))}
        </Box>
      </Container>
    </>
  );
}

export default ContactUsPage;
