import React from 'react';
import { Box, Typography, List, ListItem, Checkbox, Grid, IconButton, Button } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from 'prop-types';

const PreferencesPage = ({ handleBackToMain, handleOpenUpdatePreferences, preferredContact }) => (
  <Box
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      height: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        mb: 2, 
        p: 1, 
      }}
    >
      <IconButton onClick={handleBackToMain} sx={{ mr: 1 }}>
        <ArrowBackIcon sx= {{color: "primary.dark"}}/>
      </IconButton>
      <Typography variant="body1" fontWeight="bold">Preferences</Typography>
    </Box>

    <List
      sx={{
        width: "100%",
        maxWidth: "350px",
        mx: "auto",
      }}
    >
      <Typography variant="body2" sx={{ p: 1, backgroundColor: "white" }}>
        How do you want to be contacted?
      </Typography>
      {[
        { label: "Email", key: "email" },
        { label: "Postal Letters", key: "letter" },
        { label: "Phone", key: "phone" },
        { label: "SMS Text Message", key: "sms" },
      ].map((item) => (
        <ListItem key={item.key} sx={{ backgroundColor: "white", borderRadius: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center", backgroundColor: "white" }}>
              <Checkbox
                checked={item.key === preferredContact.toLowerCase()}
                icon={<CircleOutlinedIcon />}
                checkedIcon={<CheckCircleIcon />}
                sx={{
                  mr: 2,
                  '&.Mui-disabled': {
                    color: "primary.dark",
                  }
                }}
                disabled
              />
              <Typography variant="body2">
                  {item.label}
              </Typography>
            </Grid>

            {(item.key === "letter") && (
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleOpenUpdatePreferences}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </ListItem>
      ))}
    </List>
  </Box>
);

PreferencesPage.propTypes = {
  handleBackToMain: PropTypes.func.isRequired,
  handleOpenUpdatePreferences: PropTypes.func.isRequired,
  preferredContact: PropTypes.string,
};

export default PreferencesPage;
