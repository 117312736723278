import { useEffect } from "react"
import { useAuth } from "../context/AuthProvider";

const useWorldpayScript = () => {
  const { accessToken } = useAuth();
  useEffect(() => {
    if (accessToken && accessToken !== null) {
      const script = document.createElement("script");
      script.src = "https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [accessToken])
}

export default useWorldpayScript;
