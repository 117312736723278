import { 
  Card,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import MonetaryText from "../../MonetaryText";


function BalanceWidget({ balance }) {
  return (
    <Grid>
      <Card
        id="balance-container"
        sx={{
          textAlign: "center",
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
          borderRadius: 0
        }}>
        <Typography
          id="balance-label"
          fontWeight={"bold"}
          fontSize={28}
          paddingTop={0.5}
        >
          Current balance
        </Typography>
        <Typography
          id="balance"
          fontSize={46}
          fontWeight={"bold"}
          paddingBottom={-1}
        >
          <MonetaryText value={balance}/>
        </Typography>
      </Card>
    </Grid>
  )
}

export default BalanceWidget;