import { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  Checkbox,
} from "@mui/material";

import ActionButton from "../account/ActionButton";
import ArrangementForm from "./ArrangementForm";
import ArrangementInfoCard from "./ArrangementInfoCard";

import { useArrangement } from "../../context/ArrangementProvider";


/**
 * Arrangement setup component
 * */
function ArrangementSetup({ handleNext }) {

  const { planMode } = useArrangement();
  const [ planConsent, setPlanConsent ] = useState(false);

  return (
    <>
      <Typography component='label' htmlFor='email' fontWeight={"bold"}>
        {planMode === "create"
          ? "Create your payment plan"
          : "Change your payment plan"}
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Left column */}
          <ArrangementForm />
          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Right column */}
          <ArrangementInfoCard />

          {/* Affordability confirmation */}
          <Box id='declaration' sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                id='declaration-checkbox'
                checked={planConsent}
                onChange={() => {
                  setPlanConsent(!planConsent);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            <Box padding={0.5}>
              <Typography
                id='declaration-statement'
                component='h2'
                variant='p'
                sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
              >
                I can confirm the plan that I am proposing is affordable and I
                have taken into account other debts & priority arrears.
              </Typography>
            </Box>
          </Box>

          {/* End of affordability confirmation */}

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 2, pb: 4 }}>
            <Container sx={{ pb: 2 }}>
              <Button
                id='continue-btn'
                onClick={handleNext}
                variant='contained'
                disabled={!planConsent}
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "5em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Continue
              </Button>
            </Container>

            <Container>
              <ActionButton
                id='change-btn'
                buttonColor='#FFF'
                buttonTextColor='secondary.main'
                buttonTitle='Change Financial Statement'
                outlined='true'
                hoverColor='secondary.main'
                hoverTextColor='#FFF'
                target='/sfs'
              />
            </Container>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  );
}

export default ArrangementSetup;
