import { useAccount } from "../context/AccountProvider";
import { useClient } from "../context/ClientProvider";
import { useParty } from "../context/PartyProvider";

const useAppData = () => {
    const { useGetAccount, useGetContactNumber, useGetContactEmail, useGetContactPreference } = useAccount();
    const { useGetConfig, disableProfile } = useClient(); 
    const { useGetParty } = useParty(); 
    const { party, error: partyError, isLoading: partyLoading } = useGetParty();
    const { account, error: accountError,  isLoading: isAccountLoading} = useGetAccount();
    const { clientConfig, error: clientConfigError,  isLoading: isClientConfigLoading} = useGetConfig();
    const { contactNumber, isLoading: isGetContactNumberLoading, contactNumberError, mutate: mutateContactNumber } = useGetContactNumber();
    const { emailAddress, isLoading: isGetEmailLoading, emailError, mutate: mutateEmailAddress } = useGetContactEmail();
    const { contactPreference, isLoading: isGetPreferredContactLoading, contactPreferenceError, mutate: mutateContactPreference } = useGetContactPreference();
    const contactInfo = {emailAddress, contactNumber, contactPreference };
    return {
        account,
        accountError,
        isAccountLoading,
        clientConfig,
        clientConfigError,
        isClientConfigLoading,
        party,
        partyError,
        partyLoading,
        contactNumber, 
        isGetContactNumberLoading, 
        contactNumberError, 
        mutateContactNumber,
        emailAddress, 
        isGetEmailLoading,
        emailError, 
        mutateEmailAddress,
        contactPreference,
        isGetPreferredContactLoading,
        contactPreferenceError,
        mutateContactPreference,
        contactInfo,
        disableProfile
    };
};

export default useAppData;
