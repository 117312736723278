import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Container,
  Box,
  Stack,
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";

import ArrangementCalculator from "./ArrangementCalculator";
import { useArrangement } from "../../context/ArrangementProvider";

const handleSubmit = (event) => {
  event.preventDefault();
};

function onChange(e) {
  return null;
}

function ArrangementForm() {
  /* The create repayment form component */

  const {
    planMode,
    plan,
    setPlan,
    paymentFrequencies = {},
    paymentMethods,
    calculateStartDate,
    calculateEndDate,
  } = useArrangement();

  const [ errorMessage, setErrorMessage ] = useState(null);

  function setPlanPaymentMethod(paymentMethod) {
    setErrorMessage(null);
    setPlan({ ...plan, paymentMethod });
  }

  function setPlanWeeklyDay(weeklyDay) {
    setErrorMessage(null);
    setPlan({ ...plan, weeklyDay });
  }

  function setPlanMonthlyDay(monthlyDay) {
    setErrorMessage(null);
    setPlan({ ...plan, monthlyDay });
  }

  function setPlanPaymentFrequency(paymentFrequency) {
    setErrorMessage(null);
    setPlan({ ...plan, paymentFrequency });
  }

  useEffect(() => {
    // Recalculate payment start date when payment selections change
    const newStartDate = calculateStartDate(
      plan?.paymentMethod,
      plan?.paymentFrequency,
      plan?.monthlyDay,
      plan?.weeklyDay
    );
    // Recalculate payment end date when start date changes
    const newEndDate = calculateEndDate(
      newStartDate,
      plan?.paymentFrequency,
      plan?.numberOfInstalments,
    );
    setPlan({
      ...plan,
      dateCreated: newStartDate,
      nextPaymentDate: newStartDate,
      endDate: newEndDate,
      paymentMethod: plan?.paymentMethod,
      paymentFrequency: plan?.paymentFrequency,
      monthlyDay: plan?.monthlyDay,
      weeklyDay: plan?.weeklyDay,
      numberOfInstalments: plan?.numberOfInstalments,
    });
  }, [
    plan?.paymentMethod,
    plan?.paymentFrequency,
    plan?.monthlyDay,
    plan?.weeklyDay,
    plan?.numberOfInstalments,
  ]);

  return plan ? (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 3,
      }}
    >
      {/* Arrangement calculator section */}
      <Container
        sx={{
          marginY: 2,
        }}
      >
        <ArrangementCalculator setErrorMessage={setErrorMessage} />
      </Container>
      {/* End of arrangement calculator section */}

      <Box component='form' onSubmit={handleSubmit} noValidate>
        {/* Calculator error display */}
        <Box sx={{ mx: 3 }}>
          <Typography sx={{ color: "red" }}>
            {errorMessage && `* ${errorMessage}`}
          </Typography>
        </Box>
        <Stack
          sx={{
            paddingX: 1,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          {/* Arrangement form left column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component='label'
              htmlFor='instalments'
              fontWeight='bold'
            >
              {`Number of £${plan?.instalmentAmount?.toFixed(2)} instalments`}
            </Typography>
            <TextField
              margin='dense'
              required
              fullWidth
              size='small'
              id='instalments'
              name='instalments'
              value={plan?.numberOfInstalments}
              autoFocus
              InputProps={{
                readOnly: true,
                style: {
                  borderRadius: "0px",
                  backgroundColor: "#E2E2E2",
                },
              }}
            />

            <Typography
              component='label'
              htmlFor='frequency'
              fontWeight={"bold"}
              sx={{ mt: { xs: 4, md: 2 } }}
            >
              Payment frequency
            </Typography>
            <Select
              sx={{ mt: 1, borderRadius: "0px" }}
              id='frequency'
              size='small'
              value={plan?.paymentFrequency}
              onChange={(event) => setPlanPaymentFrequency(event.target.value)}
            >
              {Object.keys(paymentFrequencies).map((key) => (
                <MenuItem key={key} value={key}>
                  {paymentFrequencies[key]}
                </MenuItem>
              ))}
            </Select>

            {planMode === "create" && (
              <>
                <Typography
                  component='label'
                  htmlFor='method'
                  fontWeight={"bold"}
                  sx={{ mt: { xs: 4, md: 2 } }}
                >
                  Payment method
                </Typography>
                <Select
                  sx={{ mt: 1, borderRadius: "0px" }}
                  id='method'
                  size='small'
                  value={plan?.paymentMethod}
                  onChange={(event) => setPlanPaymentMethod(event.target.value)}
                >
                  <MenuItem value={paymentMethods["UK DIRECT DEBIT"]}>Direct Debit</MenuItem>
                  <MenuItem value={paymentMethods["DEBIT CARD PAYMENT"]}>Card Payment</MenuItem>
                  <MenuItem value={paymentMethods["STANDING ORDER"]}>Standing Order</MenuItem>
                  <MenuItem value={paymentMethods["CHEQUE PAYMENT"]}>Cheque</MenuItem>
                  <MenuItem value={paymentMethods["DIRECT TO BANK"]}>Bank Transfer</MenuItem>
                  <MenuItem value={paymentMethods["POSTAL ORDER"]}>Postal Order</MenuItem>
                </Select>
              </>
            )}

          </CardContent>
          {/* End of arrangement form left column */}

          {/* Arrangement form right column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography component='label' htmlFor='amount' fontWeight={"bold"}>
              Final Payment amount
            </Typography>
            <TextField
              margin='dense'
              required
              InputProps={{
                style: {
                  borderRadius: 0,
                  backgroundColor: "#E2E2E2",
                },
              }}
              fullWidth
              size='small'
              id='amount'
              name='amount'
              value={`£${plan?.finalPaymentAmount?.toFixed(2)}`}
              autoFocus
              onChange={onChange}
            />

            <>
              <Typography
                component='label'
                htmlFor='pay-day'
                fontWeight={"bold"}
                sx={{ mt: { xs: 4, md: 2 }, fontWeight: "bold" }}
              >
                Pay on day
              </Typography>
              {plan?.paymentFrequency !== "monthly" && (
                <Select
                  sx={{ mt: 1, borderRadius: "0px" }}
                  id='pay-weekday'
                  size='small'
                  value={plan?.weeklyDay}
                  onChange={(event) => setPlanWeeklyDay(event.target.value)}
                >
                  <MenuItem value={"Monday"}>Monday</MenuItem>
                  <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                  <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                  <MenuItem value={"Thursday"}>Thursday</MenuItem>
                  <MenuItem value={"Friday"}>Friday</MenuItem>
                </Select>
              )}

              {plan?.paymentFrequency === "monthly" && (
                <Select
                  sx={{ mt: 1, borderRadius: "0px" }}
                  id='pay-day'
                  size='small'
                  value={plan?.monthlyDay}
                  onChange={(event) => setPlanMonthlyDay(event.target.value)}
                >
                  {Array.from({ length: 28 }, (_, i) => i + 1).map(
                    (item, idx) => (
                      <MenuItem key={idx} value={item}>
                        Day {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}

              {/* Payment date message */}
              <Box sx={{ pt: 1 }}>
                <Typography sx={{ fontSize: 14 }}>
                  * Your new repayment plan will start on {" "}
                  <strong>{dayjs(plan?.dateCreated).format("DD/MM/YYYY")}</strong>{"."}
                  {plan?.paymentFrequency === "monthly" ?
                    " If this date falls on a weekend or bank holiday, the payment will be due the following working day." : ""}
                </Typography>
              </Box>
            </>
          </CardContent>
          {/* End of arrangement form right column */}
        </Stack>
      </Box>
    </Card>
  ) : null;
}

export default ArrangementForm;
