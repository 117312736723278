import { Box, Typography, Container, TextField } from "@mui/material";

import { useAccount } from "../../../context/AccountProvider";
import { useSettlement } from "../../../context/SettlementProvider";

const SettlementHeadline = ({ setErrorMessage }) => {
  /* The settlement headline component */

  const { settlementPlan, settlementOffer, settlementDiscount } = useSettlement();

  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  const instalmentLabel = `£${settlementPlan?.instalmentAmount.toFixed(2)}`;
  const numInstalments = settlementPlan?.numberOfInstalments;

  return settlementPlan && (
    <Box
      sx={{
        textAlign: "center",
        borderRadius: 0,
        backgroundColor: "primary.dark",
        color: "primary.contrastText",
        py: 1,
      }}
    >
      <Box sx={{ mt: 1 }}>
        <Typography fontSize={32} fontWeight={"bold"}>
          Settle in x<b>{numInstalments}</b>
        </Typography>
      </Box>
      <Container sx={{ textAlign: "center", padding: 2 }}>
        <TextField
          disabled
          required
          type='text'
          id='settlement-payment-amount'
          name='amount'
          value={instalmentLabel}
          autoFocus
          inputProps={{
            style: {
              color: "primary.dark",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 36,
              height: "35px",
            },
          }}
          sx={{
            backgroundColor: "#F5F5F5",
            width: "200px",
            borderRadius: "0px",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
            },
          }}
        />
      </Container>
      <Typography margin={2} marginTop={1}>
        <Box fontSize={14} marginLeft={1} pr={1}>
          <Typography>
            This settlement plan would clear the settlement offer of <b>£{settlementOffer.toFixed(2)}</b> in <b>{numInstalments}</b>{" "}
            instalment{numInstalments > 1 ? "s" : ""} of <b>£{settlementPlan?.instalmentAmount.toFixed(2)}</b>. This represents a{" "}
            <b>{settlementDiscount}%</b> discount of your overall balance of <b>£{account?.currentBalance.toFixed(2)}</b>
          </Typography>
        </Box>
      </Typography>

    </Box>
  );
};

export default SettlementHeadline;
