import React from 'react'
import { Fab, Zoom, useScrollTrigger } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function ScrollToTopButton() {
    const trigger = useScrollTrigger({
        target: window,
        disableHysterisis: true,
        threshold: 100,
    });
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <Zoom in={trigger}>
            <Fab
                color="primary"
                size="medium"
                onClick={handleClick}
                style={{
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}
export default ScrollToTopButton;