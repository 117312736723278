import { Box, Typography } from "@mui/material";

import dayjs from "dayjs";

import { useAccount } from "../../../context/AccountProvider";
import { useUtils } from "../../../context/UtilsProvider";
import { useSettlement } from "../../../context/SettlementProvider";

/** 
 * Settlement confirmation information card component
 * */
const SettlementConfirmationInfoCard = () => {

  const { useGetAccount } = useAccount();
  const { settlementPlan, settlementPaymentMethods } = useSettlement();

  const { account } = useGetAccount();
  const { capitaliseWords } = useUtils();

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "80%", md: "70%" },
        m: { xs: "0", sm: "0 auto", md: "0" },
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box sx={{ pl: 2, pb: 1, borderBottom: "1px solid lightgrey", backgroundColor: "#FFF"}}>
        <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
          {account?.creditorName}
        </Typography>
        <Typography fontSize={13}>Reference number:</Typography>
        <Typography fontSize={16} fontWeight={"bold"}>
          {account?.accountReference}
        </Typography>
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingY={0.5}
        >
          Repayment plan amount
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            pt: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{`£${settlementPlan?.instalmentAmount.toFixed(2)}`}</Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Next repayment due date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {settlementPlan?.dateCreated.format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY") ?
            "TODAY": settlementPlan?.dateCreated.format("DD/MM/YYYY")}
        </Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan payment method
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {capitaliseWords(settlementPlan?.paymentMethod ? Object.keys(settlementPaymentMethods).find(
            key => settlementPaymentMethods[key] === settlementPlan.paymentMethod) : "")}
        </Box>
      </Box>
    </Box>
  );
};

export default SettlementConfirmationInfoCard;
