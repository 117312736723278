import { Box, Typography, Button,  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";

function GeneralError({ error }) {

  const errorMessage = error.statusText || error.message;
  console.log("General error: ", errorMessage);

  const navigate = useNavigate();

  const { endSession } = useAuth();

  const handleClick = () => {
    endSession();
    navigate("/");
  };

  const errorHeadline = `Oops something went wrong...`;
  const errorSubMessage1 = `We're sorry, but it looks like something went wrong on our end. To help you get back to a working state, please click the button below to login again.`;
  const errorSubMessage2 = `This should quickly resolve the issue and restore your access.`;

  return (
    <Box>
      <Box sx={{ mx: { xs: 1, sm: 2, md: 3, lg: 4, xl: 10 } }}>
        <Typography
          color="primary.dark"
          variant="h1"
          sx={{ fontWeight: "bold", fontSize: { xs: 24, sm: 26, md: 28, lg: 30, xl: 32 } }}
        >
          {errorHeadline}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: 18, sm: 20, md: 22, lg: 24, xl: 26 } }}
          >
            {errorSubMessage1}
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: 18, sm: 20, md: 22, lg: 24, xl: 26 } }}
          >
            {errorSubMessage2}
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            id="error-reset-button"
            onClick={handleClick}
            sx={{
              textTransform: "none",
              borderRadius: 0,
              width: { xs: "15em", lg: "20em" },
              backgroundColor: "primary.dark",
              color: "#FFF",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.dark",
              }
            }}
          >
            Click here
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default GeneralError;