import { React, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  Checkbox,
  Button,
  Paper,
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from 'prop-types';

const ContactPreferencesPage = ({
  handleBackToMain,
  preferredContact, 
  handleUpdateContactPreference,
  error
}) => {
  const getInitialPreference = (contact) => 
    contact && contact.toLowerCase() !== 'unknown' ? contact.toLowerCase() : '';

  const [selectedPreference, setSelectedPreference] = useState(getInitialPreference(preferredContact));

  useEffect(() => {
    if (preferredContact) {
      setSelectedPreference(getInitialPreference(preferredContact));
    }
  }, [preferredContact]);

  const handlePreferenceChange = (preference) => {
    setSelectedPreference(preference);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={handleBackToMain}>
          <ArrowBackIcon sx= {{color: "primary.dark"}}/>
        </IconButton>
        <Typography variant="body1" fontWeight="bold">Update my contact preferences</Typography>
      </Box>

      <Typography variant="body2" sx={{ mb: 2 }}>
        Please select your contact preference by choosing an option from the list below.
      </Typography>

      <List>
        {['Email', 'Letter', 'Phone', 'SMS'].map((preference) => (
          <Paper key={preference} elevation={3} sx={{ mb: 2, p: 1 }}>
            <ListItem>
              <Checkbox
                checked={selectedPreference === preference.toLowerCase()}
                onChange={() => handlePreferenceChange(preference.toLowerCase())}
                disabled={preference === "SMS"}
              />
              <Typography variant="body2">
                {preference}
              </Typography>
            </ListItem>
          </Paper>
        ))}
      </List>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {handleUpdateContactPreference(selectedPreference)}}
        sx={{ mt: 2 }}
      >
        Update contact preference
      </Button>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

ContactPreferencesPage.propTypes = {
  handleBackToMain: PropTypes.func.isRequired,
  preferredContact: PropTypes.string, 
  handleUpdateContactPreference: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default ContactPreferencesPage;