import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

import { useSettlement } from "../../../context/SettlementProvider";


function SettlementCardForm({ consent, setConsent }) {
  /* The create settlement card method form component */

  const { selectedOption } = useSettlement();

  const cardPaymentOptions = [
    { id: "1", label: "Online through our website: myaccount.intrum.co.uk", url: "https://myaccount.intrum.co.uk" },
    { id: "2", label: "Calling our automated IVR system: 01737 237 370", url: "tel:+1737237370" },
    { id: "3", label: "Contacting a member of our dedicated staff: 01737 237 370", url: "tel:+1737237370" },
  ];

  const line1 = selectedOption === "1" ?
    `When setting up a payment plan using a payment card, please note that each instalment will need to be paid manually. 
    This means that you will need to make the instalment payment on your chosen repayment date.` :
    `When setting up a payment plan using a payment card, please note that payment of each instalment will need to be made manually. 
    So for convenience, you may wish to set up a Direct Debit, this way each instalment will be taken automatically.`;

  const line2 = selectedOption === "1" ?
    `Whether you prefer the ease of online transactions or the assurance of speaking with a real person, we offer multiple 
    options to make managing your payments as convenient as possible` :
    `Whether you prefer the ease of online transactions or the assurance of speaking with a real person, we offer multiple 
    options to ensure that managing your payments is as convenient as possible. Our team is here to assist you every step 
    of the way, so don't hesitate to reach out if you have any questions or need further assistance.`; 

  return (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        p: 2,
      }}
    >
      <Typography component='h1' fontWeight='bold'>
        Paying by card
      </Typography>

      <Box textAlign='center' px={3} pt={2}>

        <Typography textAlign='left' paddingBottom={2}>
          {line1}
        </Typography>

        <Typography textAlign='left'>
          You can make your payments easily through the following options:
        </Typography>

        <Box paddingX={5}>
          <List sx={{ listStyleType: "circle" }}>
            {cardPaymentOptions.map(item => (
              <ListItem key={item.id} button component={Link} href={item.url} target="_blank" disablePadding sx={{ display: "list-item" }}>
                <ListItemText primary={item.label}/>
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography textAlign='left'>
          {line2}
        </Typography>
      </Box>

      {/* Payment method confirmation */}
      <Box
        id='declaration'
        sx={{ display: "flex", flexDirection: "row", pl: 2 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id='declaration-checkbox'
            checked={consent}
            onChange={() => {
              setConsent(!consent);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box id='declaration-statement' padding={0.5}>
          <Typography
            component='h2'
            variant='p'
            sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
          >
            I confirm that I am the account holder and have the authority to set
            up card payments on this account.
          </Typography>
        </Box>
      </Box>
      {/* End of payment method confirmation */}
    </Card>
  );
};

SettlementCardForm.propTypes = {
  consent: PropTypes.bool,
  setConsent: PropTypes.func,
};

export default SettlementCardForm;
