import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


function PageNotFound() {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const headline = `Oops that page isn't available...`;
  const subMessage = `It looks like the page you requested doesn't exist. To help you get back to where you were, please click the button below.`;

  return (
    <Box>
      <Box sx={{ mx: { xs: 1, sm: 2, md: 3, lg: 4, xl: 10 } }}>
        <Typography
          color="primary.dark"
          variant="h1"
          sx={{ fontWeight: "bold", fontSize: { xs: 24, sm: 26, md: 28, lg: 30, xl: 32 } }}
        >
          {headline}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: 18, sm: 20, md: 22, lg: 24, xl: 26 } }}
          >
            {subMessage}
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            id="page-not-found-button"
            onClick={handleClick}
            sx={{
              textTransform: "none",
              borderRadius: 0,
              width: { xs: "15em", lg: "20em" },
              backgroundColor: "primary.dark",
              color: "#FFF",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.dark",
              }
            }}
          >
            Click here
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PageNotFound;