import { Box, Link } from "@mui/material";

import { useRouteError } from "react-router-dom";
import ScriptLoader from "../account/ScriptLoader";

import { useClient } from "../../context/ClientProvider";

import GeneralError from "../error/GeneralError";

/**
 * Error page component
 * @returns
 */
function ErrorPage() {

  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();

  const error = useRouteError();
  console.error(error);

  return (
    <>
      <ScriptLoader /> {/* Load scripts based on consent */}
      <Box backgroundColor="#FFF">

        {/* Banner & Logo */}
        <Box
          disableGutters
          sx={{
            backgroundImage: `url(${clientConfig?.layout.auth.banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            mb: { xs: 5 },
          }}
        >
          <Box
            sx={{
              m: "0 auto",
              width: 130,
              maxHeight: { xs: 80, md: 100 },
              maxWidth: { xs: 130, md: 150 },
              filter: {
                xs: "invert(1)",
              },
              py: 3,
            }}
          >
            <Link to='/'>
              <img src={clientConfig?.layout.header.logo} alt='Logo' />
            </Link>
          </Box>
        </Box>
        {/* End of Banner & Logo */}

        <GeneralError error={error} />

      </Box>
    </>
  );
}

export default ErrorPage;
