import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";

import { useUtils } from "../../context/UtilsProvider";

import { useArrangement } from "../../context/ArrangementProvider";

/**
 * The create arrangement payment method component
 * @returns 
 */
const CreateArrangementMethodInfoCard = ({ account }) => {

  const { capitaliseWords } = useUtils();
  const { plan, paymentMethods } = useArrangement();

  return account && (
    <Box
      sx={{
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box
        sx={{
          pb: 1,
          borderBottom: "1px solid lightgrey",
          backgroundColor: "white",
        }}
      >
        <Container sx={{ width: "60%", display: "inline-block" }}>
          <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
            {account?.creditorName}
          </Typography>
          <Typography fontSize={13}>Reference number:</Typography>
          <Typography fontSize={16} fontWeight={"bold"}>
            {account?.accountReference}
          </Typography>
        </Container>
        {plan?.paymentMethod === paymentMethods["UK DIRECT DEBIT"] &&
          <Container sx={{ display: "inline-block", width: "40%" }}>
            <Box
              component='img'
              alt='Direct Debit Logo'
              src='/images/direct-debit.png'
              sx={{
                width: "100px",
                height: "30px",
              }}
            />
          </Container>}
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          component='label'
          htmlFor='paymentAmount'
          sx={{
            fontSize: 13,
            py: 0.5,
          }}
        >
          Payment amount
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            pt: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {plan?.instalmentAmount.toFixed(2)}
        </Box>

        <Typography
          component='label'
          htmlFor='startDate'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan start date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {plan?.dateCreated.format("DD/MM/YYYY")}
        </Box>

        <Typography
          component='label'
          htmlFor='endDate'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Estimated end date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {plan?.endDate.format("DD/MM/YYYY")}
        </Box>

        <Typography
          component='label'
          htmlFor='paymentMethod'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan payment method
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {capitaliseWords(plan?.paymentMethod ? Object.keys(paymentMethods).find(key => paymentMethods[key] === plan.paymentMethod) : "")}
        </Box>

      </Box>
    </Box>
  );
};

CreateArrangementMethodInfoCard.propTypes = {
  account: PropTypes.object,
};

export default CreateArrangementMethodInfoCard;
