import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Divider,
    TextField,
    LinearProgress,
    Container,
    Stack,
    Button,
    FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useClient } from '../../context/ClientProvider';
import { useParty } from '../../context/PartyProvider';
import { useAccount } from '../../context/AccountProvider';
import { generateTransactionPdf } from '../widgets/account/TransactionHistoryPdf';
import { generateTransactionExcel } from '../widgets/account/TransactionHistoryExcel';
import { useTransactionHistory } from '../../context/TransactionHistoryProvider';
import { useNav } from '../../context/NavProvider';
import { useUtils } from "../../context/UtilsProvider";
import { styled } from '@mui/system';

const PAGE_LIMIT = 20;

const PoundIconContainer = styled(Box)(({
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    marginRight: '8px',
    justifyContent: 'center',
}));

const PoundIcon = styled(Box)(({
    width: '24px',
    height: '24px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '4px',
}));

const VerticalDivider = styled(Divider)(({
    backgroundColor: '#ccc',
    height: '19px',
    marginLeft: '8px',
    marginRight: '8px',
    position: 'relative',
    top: '29px',
    right: '20px',
}));

const TransactionHistory = () => {
    const { useGetParty } = useParty();
    const { party } = useGetParty();
    const { transactions, isLoading } = useTransactionHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDateError, setFromDateError] = useState('');
    const [toDateError, setToDateError] = useState('');
    const { clientLogo } = useClient();
    const { setShowFooter } = useNav();
    const { useGetAccount } = useAccount();
    const { account } = useGetAccount();
    const { capitaliseWords } = useUtils();

    useEffect(() => {
        if (!isLoading) {
            setShowFooter(true);
        } else {
            setShowFooter(false);
        }
        return () => {
            setShowFooter(false);
        };
    }, [isLoading, setShowFooter]);

    useEffect(() => {
        const today = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(today.getMonth() - 6);

        const firstTransactionDate = transactions.length > 0
            ? new Date(Math.min(...transactions.map(transaction => new Date(transaction.datePaid))))
            : today;

        const initialFromDate = sixMonthsAgo < firstTransactionDate ? firstTransactionDate : sixMonthsAgo;
        setFromDate(initialFromDate > today ? today.toISOString().split('T')[0] : initialFromDate.toISOString().split('T')[0]);
        setToDate(today.toISOString().split('T')[0]);
    }, [transactions]);

    if (isLoading) {
        return <LinearProgress />;
    }

    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.datePaid);
        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);

        return (
            (!fromDate || transactionDate >= startDate) &&
            (!toDate || transactionDate <= endDate)
        );
    });

    const startIndex = (currentPage - 1) * PAGE_LIMIT;
    const paginatedTransactions = filteredTransactions.slice(
        startIndex,
        startIndex + PAGE_LIMIT
    );

    const totalPages = filteredTransactions.length > 1 ? Math.ceil(filteredTransactions.length / PAGE_LIMIT) : 1;
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            window.scrollTo(0, 0);
            setCurrentPage(newPage);
        }
    };

    const handleDownloadPDF = async () => {
        await generateTransactionPdf(filteredTransactions, clientLogo, party, account, fromDate, toDate);
    };

    const handleDownloadExcel = () => {
        generateTransactionExcel(filteredTransactions);
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const validateFromDate = (selectedDate) => {
        const today = new Date().toISOString().split('T')[0];
        const firstTransactionDate = transactions.length > 0
            ? new Date(Math.min(...transactions.map(transaction => new Date(transaction.datePaid))))
            : new Date(today);

        let errorMessage = '';

        if (selectedDate > today) {
            errorMessage = "You cannot select a date in the future.";
        } else if (new Date(selectedDate) < firstTransactionDate) {
            errorMessage = `You cannot select a date that exceeds ${formatDate(firstTransactionDate)}`;
        } else if (toDate && selectedDate > toDate) {
            errorMessage = "From date cannot be later than To date.";
        }

        return errorMessage;
    };

    const validateToDate = (selectedDate) => {
        const today = new Date().toISOString().split('T')[0];
        let errorMessage = '';

        if (selectedDate > today) {
            errorMessage = "You cannot select a date in the future.";
        } else if (fromDate && selectedDate < fromDate) {
            errorMessage = `To date cannot be earlier than from date: ${formatDate(new Date(fromDate))}`;
        }

        return errorMessage;
    };

    const handleFromDateChange = (e) => {
        const selectedDate = e.target.value;
        const error = validateFromDate(selectedDate);

        if (error) {
            setFromDateError(error);
        } else {
            setFromDateError('');
        }

        setFromDate(selectedDate);
    };

    const handleToDateChange = (e) => {
        const selectedDate = e.target.value;
        const error = validateToDate(selectedDate);

        if (error) {
            setToDateError(error);
        } else {
            setToDateError('');
        }

        setToDate(selectedDate);
    };

    return (
        <>
            <Container sx={{ mt: 4, mb: 7 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                        Transactions
                    </Typography>
                    <Typography paddingTop={1} paddingBottom={3} sx={{ color: '#555' }}>
                        In this section, you can easily access and review all your transactions with us, including all payment activities. You’ll also have the option to download your full transaction history for your records, helping you stay informed and up-to-date with your account at any time.
                    </Typography>
                </Box>
                <Box sx={{ width: '100%', paddingBottom: '350px', display: 'flex', justifyContent: 'start' }}>
                    <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, width: '100%', flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Grid container spacing={2} xs={12} sm={20} sx={{ flexGrow: 1, mb: { xs: 2, sm: 0 } }}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" sx={{ mb: 1 }}>From date</Typography>
                                    <TextField
                                        id="from-date"
                                        type="date"
                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ backgroundColor: '#fff', minWidth: '200px' }}
                                    />
                                    {fromDateError && <FormHelperText error>{fromDateError}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" sx={{ mb: 1 }}>To date</Typography>
                                    <TextField
                                        id="to-date"
                                        type="date"
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ backgroundColor: '#fff', minWidth: '200px' }}
                                    />
                                    {toDateError && <FormHelperText error>{toDateError}</FormHelperText>}
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, width: '100%' }}>
                            <Button
                                variant="contained"
                                onClick={handleDownloadPDF}
                                disabled={filteredTransactions.length === 0}
                                sx={{ backgroundColor: 'primary.dark', borderRadius: '0px' }}
                            >
                                Download PDF
                            </Button>
                            <Button
                                onClick={handleDownloadExcel}
                                variant='outlined'
                                disabled={filteredTransactions.length === 0}
                                sx={{ color: 'primary.dark', borderRadius: '0px' }}
                            >
                                Download Excel
                            </Button>
                        </Box>

                        {filteredTransactions.length === 0 ? (
                            <Typography color="error">
                                You currently do not have any transactions in the selected date range.
                            </Typography>
                        ) : (
                            <Box sx={{ width: '100%', mt: 3 }}>
                                {paginatedTransactions.map((transaction, index) => (
                                    <Box
                                        key={transaction.id}
                                        sx={{
                                            backgroundColor: '#fff',
                                            padding: '15px',
                                            marginBottom: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <PoundIconContainer sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PoundIcon sx={{ backgroundColor: 'primary.dark' }}>
                                                £
                                            </PoundIcon>
                                            <VerticalDivider orientation="vertical" flexItem />
                                        </PoundIconContainer>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>
                                                {capitaliseWords(transaction.payMethod)}
                                            </Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#888' }}>
                                                Paid in: Payment successful
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: 'primary.main' }}>
                                                - £{parseFloat(transaction.totalPaid).toFixed(2)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ flexShrink: 0 }}>
                                            <Typography sx={{ fontSize: '14px', color: '#ccc', textAlign: 'right' }}>
                                                {transaction.datePaid}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, width: '100%', alignItems: 'center' }}>
                                        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                            Previous
                                        </Button>
                                        <Typography variant="caption" sx={{ mx: 2 }}>
                                            Page {currentPage} of {totalPages}
                                        </Typography>
                                        <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                                            Next
                                        </Button>
                                    </Box>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Container>
            {setShowFooter(true)}
        </>
    );
};

export default TransactionHistory;
