import { useState } from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography, Stack, Button } from "@mui/material";

import SettlementDirectDebitForm from "./SettlementDirectDebitForm";
import SettlementCardForm from "./SettlementCardForm";
import SettlementMethodInfoCard from "./SettlementMethodInfoCard";

import ScrollToTop from "../../../components/nav/ScrollToTop"

import { useAccount } from "../../../context/AccountProvider";
import { useArrangement } from "../../../context/ArrangementProvider";
import { useSettlement } from "../../../context/SettlementProvider";

 /** 
  * Settlement method component 
 */
function SettlementMethod({
  paymentMethodConsent,
  setPaymentMethodConsent,
  handleNext,
  handleBack
}) {
  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  const {
    setHasArrangement,
    setPlan,
    savePlanData
  } = useArrangement();

  const {
    settlementPlan,
    settlementPaymentMethods,
    settlementOffer,
  } = useSettlement();

  const [ currentSortCode, setCurrentSortCode ] = useState(null);
  const [ currentAccountNumber, setCurrentAccountNumber ] = useState(null);

  const [ errorMessage, setErrorMessage ] = useState(null);

  const SORT_CODE_LENGTH = 6;
  const ACCOUNT_NUMBER_LENGTH = 8;

  const saveNewSettlement = () => {
    // Triggered when Continue is pressed in step 2 when creating a settlement
    // Validate sort code and account number
    if (settlementPlan?.paymentMethod === settlementPaymentMethods["UK DIRECT DEBIT"]) {
      if (currentSortCode === null || currentSortCode.length < SORT_CODE_LENGTH) {
        setErrorMessage(`Sort code must contain ${SORT_CODE_LENGTH} numbers.`);
        return;
      } else if (currentAccountNumber === null || currentAccountNumber.length < ACCOUNT_NUMBER_LENGTH) {
        setErrorMessage(`Account number must contain ${ACCOUNT_NUMBER_LENGTH} numbers.`);
        return;
      }
    }

    const newSettlementPlan = {
      ...settlementPlan,
      sortCode: currentSortCode,
      accountNumber: currentAccountNumber,
    }
    console.log("SAVING NEW SETTLEMENT: ", newSettlementPlan)

    // Now save as you would a standard arrangement
    setPlan(newSettlementPlan);
    savePlanData(newSettlementPlan);

    setHasArrangement(true);
    handleNext();
  }

  return (
    <>
      <ScrollToTop />
      <Typography
        variant="h5"
        component="label"
        htmlFor="email"
        fontWeight={"bold"}
      >
        Settlement Plan
      </Typography>

      {/* Headline account figures */}
      <Stack
        sx={{
          my: 3,
          textAlign: { xs: "center", sm: "left" },
        }}
        direction={{
          xs: "column",
          sm: "row",
        }}        
      >
        {/* Account balance */}
        <Box>
          <Box sx={{ pb: 1 }}>
            <Typography
              component='label'
              htmlFor='balance'
              fontSize={16}
              fontWeight="bold"
            >
              Balance
            </Typography>
          </Box>
          <Box
            id='balance'
            sx={{
              backgroundColor: "#E2E2E2",
              width: { xs: "100%", sm: "200px" },
              height: "36px",
              borderRadius: 0,
              border: "1px solid #BBBBBB",
              px: 1,
              py: 0.5,
              fontWeight: "bold",
              fontSize: 18,
              mr: { xs: 0, sm: 2},
            }}
          >
            {`£${account?.currentBalance?.toFixed(2)}`}
          </Box>
        </Box>
        {/* End of account balance */}

        {/* Settlement offer */}
        <Box>
          <Box sx={{ pb: 1, pt: { xs: 1, sm: 0 } }}>
            <Typography
              component='label'
              htmlFor='settlement-offer'
              fontSize={16}
              fontWeight="bold"
            >
              Settlement offer
            </Typography>
          </Box>
          <Box
            id='settlement-offer'
            sx={{
              backgroundColor: "#E2E2E2",
              width: { xs: "100%", sm: "200px" },
              height: "36px",
              borderRadius: 0,
              border: "1px solid #BBBBBB",
              px: 1,
              py: 0.5,
              fontWeight: "bold",
              fontSize: 18,
              color: "green",
            }}
          >
            {`£${settlementOffer.toFixed(2)}`}
          </Box>
        </Box>
        {/* End of settlement offer */}

      </Stack>
      {/* End of headline account figures */}

      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Left column */}

          {/* Alternate payment method forms */}
          {settlementPlan?.paymentMethod === settlementPaymentMethods["UK DIRECT DEBIT"] && <SettlementDirectDebitForm
            consent={paymentMethodConsent}
            setConsent={setPaymentMethodConsent}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            currentSortCode={currentSortCode}
            setCurrentSortCode={setCurrentSortCode}
            currentAccountNumber={currentAccountNumber}
            setCurrentAccountNumber={setCurrentAccountNumber}
          />}
          {settlementPlan?.paymentMethod === settlementPaymentMethods["DEBIT CARD PAYMENT"] && <SettlementCardForm
            consent={paymentMethodConsent}
            setConsent={setPaymentMethodConsent}
          />}

          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Right column */}
          <SettlementMethodInfoCard />

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 5, pb: 4 }}>
            <Container sx={{ pb: 2 }}>
              <Button
                onClick={saveNewSettlement}
                variant='contained'
                disabled={!paymentMethodConsent}
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Continue
              </Button>
            </Container>

            <Container sx={{ pb: 2 }}>
              <Button
                id='back-btn'
                onClick={handleBack}
                variant='outlined'
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  border: "1px solid",
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "#FFF",
                  color: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                    color: "#FFF",
                    border: "1px solid",
                  },
                }}
              >
                Back
              </Button>
            </Container>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  );
};

SettlementMethod.propTypes = {
  paymentMethodConsent: PropTypes.bool,
  setPaymentMethodConsent: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default SettlementMethod;
