import React, { useEffect } from "react";
import { useCookie } from "../../context/CookieProvider";
import { useSfs } from "../../context/SfsProvider";

const ScriptLoader = () => {
  const { getCookie } = useCookie();
  const { useGetSfs } = useSfs();
  const { sfs } = useGetSfs();

  useEffect(() => {
    const consent = getCookie("google_analytics");
    if (consent === "accepted") {
      const gaScript = document.createElement("script");
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=G-GC0GN8WQPK";
      gaScript.async = true;
      document.head.appendChild(gaScript);

      gaScript.onload = () => {
        console.log("Debtor ID data:", {
          debtorId: sfs?.debtorId || "unknown"
        });

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        gtag("config", "G-GC0GN8WQPK");

        window.dataLayer.push({
          event: "sfs_debtor_id",
          debtorId: sfs?.debtorId || "unknown",
        });

        console.log("Pushed data to dataLayer:", window.dataLayer);
      };

      const gtmScript = document.createElement("script");
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=GTM-MKVPQH6N`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);
    }
  }, [getCookie, sfs]);

  return null;
};

export default ScriptLoader;
