import { createContext, useContext, useState } from 'react';
import useSWR, { SWRConfig } from 'swr/immutable';
import environment from '../env';
import { useAuth } from './AuthProvider';

// Create context object and make available through custom hook
const PaymentContext = createContext();
export const usePayment = () => useContext(PaymentContext)

const PaymentProvider = ({ children }) => {
  const { accessToken } = useAuth();

  // Payment Stepper
  const [ activeStep, setActiveStep ] = useState(0);
  
  const paymentSteps = [
    { id: "payment-amount", title: "Payment amount" },
    { id: "enter-card-details", title: "Enter card details" },
    { id: "payment-confirmation", title: "Payment confirmation" },
  ];

  // Card payment related state
  const [ paymentAmountOption, setPaymentAmountOption ] = useState("");
  const [ paymentAmount, setPaymentAmount ] = useState(0);
  const [ paymentId, setPaymentId ] = useState(null);
  const [ providerResponse, setProviderResponse ] = useState(null);
  const noCreditorNoPlan = "No, and I dont have a plan in place";
  const furtherBorrowing = "Further borrowing e.g. Loans";

  const priorityBillsOptions = [
    { id: "priority-bills-yes", label: "Yes", value: "Yes" },
    { id: "priority-bills-no-with-plan", label: "No, but I have a plan in place", value: "No, but I have a plan in place" },
    { id: "priority-bills-no", label: noCreditorNoPlan, value: noCreditorNoPlan },
  ];

  const [ priorityBillsAnswer, setPriorityBillsAnswer ] = useState("");

  const sourceOfFundsOptions = [
    { id: "source-employment-income", label: "Employment Income", value: "Employment Income" },
    { id: "source-savings", label: "Savings", value: "Savings" },
    { id: "source-further-borrowing", label: furtherBorrowing, value: furtherBorrowing },
    { id: "source-inheritance", label: "Inheritance", value: "Inherhitance" },    
    { id: "source-gift", label: "Gift", value: "Gift" },
    { id: "source-pension-retirement", label: "Pension and Retirement Funds", value: "Pension and Retirement Funds" },
    { id: "source-government-payments", label: "Government Payments", value: "Government Payments" },    
    { id: "source-legal-settlements", label: "Legal Settlements", value: "Legal Settlements" },
    { id: "source-sale-assets", label: "Sale of Assets", value: "Sale of Assets" },    
    { id: "source-other-sources", label: "Other Sources", value: "Other Sources" },
  ];
  const [ sourceOfFundsAnswer, setSourceOfFundsAnswer ] = useState("");

  const otherCreditorsOptions = [
    { id: "creditors-no", label: "Yes", value: "Yes" },
    { id: "creditors-yes-with-plan", label: "No, but I have a plan in place", value: "No, but I have a plan in place" },
    { id: "creditors-yes-no-plan", label: noCreditorNoPlan, value: noCreditorNoPlan },
    { id: "creditors-na", label: "N/A", value: "N/A" },
  ];
  const [ otherCreditorsAnswer, setOtherCreditorsAnswer ] = useState("");

  const supportedCards = [
    { id: "visa-logo", name: "Visa", img: "/images/visa-logo.png" },
    { id: "mastercard-logo", name: "Mastercard", img: "/images/mastercard-logo.png" },
    { id: "maestro-logo", name: "Maestro", img: "/images/maestro-logo.png" },
  ];

  const security = [
    { id: "verified-by-visa-logo", name: "Verified By Visa", img: "/images/verified-by-visa-logo.png" },
    { id: "mastercard-securecode-logo", name: "Mastercard Securecode", img: "/images/mastercard-securecode-logo.png" },
  ];

  const updatePaymentAmount = (newPaymentAmount) => {
    setPaymentAmount(parseFloat(newPaymentAmount));
  };

  const apiUrl = environment.CUSTOMER_PORTAL_API;
  const apiKey = environment.CUSTOMER_PORTAL_API_KEY;

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": apiKey,
      "Authorization": `Bearer ${accessToken}`,
      "organisation": environment.CLIENT_NAME,
    }
  };

  /**
   * Post payment SWR hook
   * @returns 
   */
  function usePostPayment(request) {
    const url = accessToken ? `${apiUrl}/payment` : null;
    const requestOptions = {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify(request)
    };
    console.info("Post payment request", {request, requestOptions, url})
    const { data, error, isLoading } = useSWR([url, requestOptions])
    console.info("Post payment response", { data, error, isLoading })  
    return {
      payment: data,
      isLoading,
      isError: error
    }
  }

  /**
   * Get payment status SWR hook
   * @returns 
   */
  function useGetPayment(paymentId) {
    const url = accessToken ? `${apiUrl}/payment/${paymentId}` : null;
    console.info("Get payment status request", {paymentId, defaultOptions, url})
    const { data, mutate, error, isLoading } = useSWR([url, defaultOptions])
    console.info("Get payment status response", { data, error, isLoading})
    return {
      paymentStatus: data,
      mutate,
      isLoading,
      isError: error
    }
  }

  // Card payment tokens related state and methods
  const [ paymentTokens, setPaymentTokens ] = useState([]);
  const [ paymentTokensLoaded, setPaymentTokensLoaded ] = useState(false);
  const [ showDeleteCardDialog, setShowDeleteCardDialog ] = useState(false);
  const [ selectedPaymentCard, setSelectedPaymentCard ] = useState(null);
  const maxTokens = 3;

  /**
   * Load payment tokens into state
   * @returns 
   */
  function loadPaymentTokens(tokens) {
    setPaymentTokens(tokens);
    setPaymentTokensLoaded(true);
  };

  /**
   * Remove a payment token from state
   * @returns 
   */
  function removePaymentToken(paymentTokenId) {
    // Delete via portal api and payments hub
    deletePaymentToken(paymentTokenId);
    // Delete from local state
    const updatedPaymentTokens = paymentTokens.filter(
      token => token.paymentTokenId !== paymentTokenId);
    setPaymentTokens(updatedPaymentTokens);
  };

  /**
   * Toggle delete payment card dialog state
   * @returns 
   */
  function toggleDeleteCardDialog() {
    setShowDeleteCardDialog(!showDeleteCardDialog);
  };

  /**
   * Set selected payment card
   * @returns 
   */
  function selectPaymentCard(paymentToken) {
    setSelectedPaymentCard(paymentToken);
  };

  /**
   * Get payment tokens SWR hook
   * @returns 
   */
  function useGetPaymentTokens() {
    const url = accessToken ? `${apiUrl}/payment/tokens` : null;
    console.info("Get payment tokens request", { defaultOptions, url })
    const { data, error, isLoading } = useSWR([ url, defaultOptions ])
    console.info("Get payment tokens response", { data, error, isLoading })
    return {
      data,
      isTokensLoading: isLoading,
      isError: error
    }
  };

  /**
   * Post payment token SWR hook
   * @returns 
   */
  function usePostPaymentToken(request) {
    const url = accessToken ? `${apiUrl}/payment/tokens` : null;
    const requestOptions = {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify(request)
    };
    console.info("Post payment token request", { request, requestOptions, url })
    const { data, error, isLoading } = useSWR([ url, requestOptions ])
    console.info("Post payment token response", { data, error, isLoading })  
    return {
      response: data,
      isLoading,
      isError: error
    }
  };

  /**
   * Delete payment token
   * @param {*} paymentTokenId
   * @returns 
   */
  async function deletePaymentToken(paymentTokenId) {
    const url = accessToken ? `${apiUrl}/payment/tokens` : null;
    let requestOptions = {
      ...defaultOptions,
      method: 'DELETE',
    }
    console.info("delete payment token request: ", { url, requestOptions });
    await fetch(`${url}/${paymentTokenId}`, requestOptions)
      .then(res => res.json())
      .then(data => {
        console.log("delete payment token response: ", data);
      })
      .catch(error => {
        console.log(`Error in delete payment token: ${error}`)
      })
  };

  const contextValue = {
    activeStep,
    setActiveStep,
    paymentSteps,
    paymentAmountOption,
    setPaymentAmountOption,
    paymentAmount,
    setPaymentAmount,
    paymentId,
    setPaymentId,
    providerResponse,
    setProviderResponse,
    priorityBillsOptions,
    priorityBillsAnswer,
    setPriorityBillsAnswer,
    sourceOfFundsOptions,
    sourceOfFundsAnswer,
    setSourceOfFundsAnswer,
    otherCreditorsOptions,
    otherCreditorsAnswer,
    setOtherCreditorsAnswer,
    supportedCards,
    security,
    updatePaymentAmount,
    usePostPayment,
    useGetPayment,
    noCreditorNoPlan,
    furtherBorrowing,
    useGetPaymentTokens,
    usePostPaymentToken,
    paymentTokens,
    loadPaymentTokens,
    removePaymentToken,
    paymentTokensLoaded,
    showDeleteCardDialog,
    toggleDeleteCardDialog,
    selectedPaymentCard,
    selectPaymentCard,
    maxTokens,
  }

  return (
    <PaymentContext.Provider value={ contextValue }>
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentProvider;