import { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

import SettlementSetup from "./SettlementSetup";
import SettlementMethod from "./SettlementMethod";
import SettlementConfirmation from "./SettlementConfirmation";

import { useSettlement } from "../../../context/SettlementProvider";

const settlementSteps = [
  { id: "create-settlement", title: "Create settlement plan" },
  { id: "settlement-payment-details", title: "Settlement payment details" },
  { id: "settlement-plan-confirmation", title: "Settlement plan confirmation" },
];

/**
 * Settlement Stepper
 * @returns 
 */
const SettlementStepper = () => {

  const [ activeStep, setActiveStep ] = useState(0);
  const [ createSettlementConsent, setCreateSettlementConsent ] = useState(false);
  const [ paymentMethodConsent, setPaymentMethodConsent ] = useState(false);
  const [ showSettlementHelp, setShowSettlementHelp ] = useState(false);

  const {
    affordabilityQuestion1,
    affordabilityQuestion2,
    affordabilityQuestion3,
  } = useSettlement();

  /**
  * Handle affordability next
  * Special next handler to include affordability check
  * @returns 
  */
  const handleAffordabilityNext = () => {
    if (
      affordabilityQuestion1 === "No, and I dont have a plan in place" ||
      affordabilityQuestion2 === "Further borrowing e.g. Loans" ||
      affordabilityQuestion3 === "No, and I dont have a plan in place"
    ) {
      // Trigger show help, don't move to next step
      setShowSettlementHelp(true);
    } else {
      // Affordability answers ok move to next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  /**
  * Handle affordability back
  * Special back handler to include affordability check
  * @returns 
  */
  const handleAffordabilityBack = () => {
    setCreateSettlementConsent(false);
    setShowSettlementHelp(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setCreateSettlementConsent(false);
    setPaymentMethodConsent(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Step Header */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          mt: 7,
          mb: 5,
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {settlementSteps.map((step) => {
          return (
            <Step id={step.id} key={step.title}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    fontSize: "45px",
                    "&.Mui-active": {
                      color: "primary.dark",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
                sx={{
                  "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiStepLabel-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                {step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* Step 1 */}
      {activeStep === 0 && (
        <SettlementSetup
          createSettlementConsent={createSettlementConsent}
          setCreateSettlementConsent={setCreateSettlementConsent}
          handleAffordabilityNext={handleAffordabilityNext}
          handleNext={handleNext}
          handleBack={handleAffordabilityBack}
          showSettlementHelp={showSettlementHelp}
        />
      )}

      {/* Step 2 */}
      {activeStep === 1 && (
        <SettlementMethod
          paymentMethodConsent={paymentMethodConsent}
          setPaymentMethodConsent={setPaymentMethodConsent}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}

      {/* Step 3 */}
      {activeStep === 2 && (
        <SettlementConfirmation
          activeStep={activeStep}
          steps={settlementSteps}
        />
      )}
    </Box>
  );
};

export default SettlementStepper;
