import dayjs from "dayjs";
import { Box, Stepper, Step, StepLabel, useMediaQuery } from "@mui/material";

import SfsAboutYou from "./SfsAboutYou";
import SfsIncome from "./SfsIncome";
import SfsExpenditureFixed from "./SfsExpenditureFixed";
import SfsExpenditureFlexible from "./SfsExpenditureFlexible";
import SfsAdditionalDebt from "./SfsAdditionalDebt";

import { useSfs } from "../../context/SfsProvider";

const steps = [
  { id: "about-you", title: "About you", shortTitle: "About You" },
  { id: "income", title: "Income", shortTitle: "Income" },
  {
    id: "expenditure-fixed",
    title: "Expenditure (Fixed)",
    shortTitle: "Expenditure (Fixed)",
  },
  {
    id: "expenditure-flex",
    title: "Expenditure (Flexible)",
    shortTitle: "Expenditure (Flexible)",
  },
  {
    id: "additional-debt",
    title: "Additional Debt",
    shortTitle: "Additional Debt",
  },
];

const SfsStepper = () => {
  const { activeStep, useGetSfs, setActiveStep, setSfsMode, saveSfsData } =
    useSfs();
  const { sfs } = useGetSfs();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    saveSfsData(sfs);
    setSfsMode("review");
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Step Header */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          mt: 7,
          mb: 5,
          "& .MuiStepConnector-root": {
            top: "18px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {steps.map((step) => (
          <Step id={step.id} key={step.title}>
            <StepLabel
              StepIconProps={{
                sx: {
                  fontSize: { xs: "25px", sm: "45px" },
                  "&.Mui-active": {
                    color: "primary.dark",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              sx={{
                "& .MuiStepLabel-labelContainer": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .MuiStepLabel-label": {
                  fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "15px" },
                },
                "& .MuiStepLabel-root": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              {isSmallScreen ? step.shortTitle : step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step 1 */}
      {activeStep === 0 && <SfsAboutYou handleNext={handleNext} />}

      {/* Step 2 */}
      {activeStep === 1 && (
        <SfsIncome handleNext={handleNext} handleBack={handleBack} />
      )}

      {/* Step 3 */}
      {activeStep === 2 && (
        <SfsExpenditureFixed handleNext={handleNext} handleBack={handleBack} />
      )}

      {/* Step 4 */}
      {activeStep === 3 && (
        <SfsExpenditureFlexible
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}

      {/* Step 5 */}
      {activeStep === 4 && (
        <SfsAdditionalDebt
          handleBack={handleBack}
          handleComplete={handleComplete}
        />
      )}
    </Box>
  );
};

export default SfsStepper;
