import dayjs from 'dayjs';
import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { useParty } from './PartyProvider';
import environment from '../env';
import useSWRImmutable, { SWRConfig } from 'swr/immutable';

// Create context object and make available through custom hook
const SfsContext = createContext();

export const useSfs = () => useContext(SfsContext)

const employmentStatuses = {
  "AGY": "Agency",
  "CAR": "Carer",
  "EFT": "Full Time Employed",
  "EPT": "Part Time Employed",
  "FRC": "Armed Forces",
  "HSE": "House Person",
  "ILL": "Not Working Due To Health",
  "OTH": "Other",
  "REF": "Refused",
  "RET": "Retired",
  "SE": "Self Employed",
  "STU": "Student",
  "UNB": "Unemployed With Benefits",
  "UNE": "Unemployed Without Benefits",
  "UNK": "Unknown",
}

const homeStatuses = {
  "HOMELESS": "Homeless",
  "LWF": "Living With Friends",
  "LWO": "Living With Parents",
  "MJ": "Mortgage Joint",
  "MO": "Mortgage Free (Owner)",
  "MS": "Mortgage Sole",
  "REF": "Refused",
  "RENT": "Rent (Private)",
  "RENTS": "Rent (Social)",
}

const frequencies = {
  "W": "Weekly",
  "M": "Monthly",
  "F": "Fortnightly",
  "A": "Annually",
  "4W": "4-Weekly",
  "Q": "Quarterly",
}
const employmentTypeIdMap = {
    "Unknown": 398
      }

    const employmentTitleIdMap = {
    "Unknown": 25519
    }
/**
 * Standard financial statement provider
 * @param {*} param0 
 * @returns 
 */
const SfsProvider = ({ children }) => {

  const { accessToken } = useAuth();
  const { useGetParty } = useParty();
  const { partyId } = useGetParty();

  // General SFS state
  const [ sfsLoaded, setSfsLoaded ] = useState(false);
  const [ sfsMode, setSfsMode ] = useState(null);
  const [ sfsCreatedDate, setSfsCreatedDate ] = useState(null);
  const [ monthsSinceReview, setMonthsSinceReview ] = useState(null);

  // About you state
  const [ employmentStatus, setEmploymentStatus ] = useState(null);
  const [ employmentTypeId, setEmploymentTypeId ] = useState(employmentTypeIdMap.Unknown);
  const [ employmentTitleId, setEmploymentTitleId ] = useState(employmentTitleIdMap.Unknown);
  const [ selectedEmployment, setSelectedEmployment ] = useState({
      occupationTypeId: employmentTypeIdMap.Uknown,
      occupationTitleId: employmentTitleIdMap.Uknown,    
    });

  const [ partnerEmploymentStatus, setPartnerEmploymentStatus ] = useState(null);
  const [ homeStatus, setHomeStatus ] = useState(null);
  const [ household, setHousehold ] = useState(null);

  // Financial sections state
  const [ sfsIncome, setSfsIncome ] = useState(null);
  const [ sfsExpenditureFixed, setSfsExpenditureFixed ] = useState(null);
  const [ sfsExpenditureFlexible, setSfsExpenditureFlexible ] = useState(null);
  const [ sfsAdditionalDebt, setSfsAdditionalDebt ] = useState(null);
  const [ sfsTotals, setSfsTotals ] = useState(null);
  const [ disposableIncome, setDisposableIncome ] = useState(0.00);

  const [ categories, setCategories ] = useState(null);
  const [ selectedCategory, setSelectedCategory ] = useState(null);

  const apiUrl = environment.CUSTOMER_PORTAL_API
  const apiKey = environment.CUSTOMER_PORTAL_API_KEY

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": apiKey,
      "Authorization": `Bearer ${accessToken}`,
    },
  };
  
  /**
   * Post standard financial standard SWR callback
   * @param {*} url 
   * @param {*} arg 
   * @returns 
   */
  async function postSfs(url, sfs) {
    let requestOptions = {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(sfs)
    }
    console.info("post sfs request: ", { url, requestOptions });
    await fetch(`${apiUrl}/standard-financial-statement`, requestOptions)
      .then(res => res.json())
      .then(data => {
        console.log("post sfs response: ", data);
      })
      .catch(error => {
        console.log(`Error in post sfs: ${error}`)
      })
  }

  /**
   * Get standard financial statement SWR hook
   * @returns 
   */
  function useGetSfs() {
    const url = accessToken ? `${apiUrl}/standard-financial-statement` : null;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": apiKey,
        "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get sfs request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get sfs response", { data, error, isLoading })
    return {
      sfs: data,
      error,
      isSfsLoading: isLoading,
    }
  }

  /**
   * Get occupation types SWR hook
   * @returns 
   */
  function useGetOccupationTypes() {
    const url = accessToken ? `${apiUrl}/standard-financial-statement/occupation-types` : null;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": apiKey,
        "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get occupation types request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get occupation types response", { data, error, isLoading })
    return {
      occupationTypesData: data,
      error,
      isOccupationTypesLoading: isLoading,
    }
  }

  /**
   * Get occupation titles SWR hook
   * @returns 
   */
  function useGetOccupationTitles() {
    const url = accessToken ? `${apiUrl}/standard-financial-statement/occupation-titles` : null;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": apiKey,
        "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get occupation titles request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get occupation titles response", { data, error, isLoading })
    return {
      occupationTitlesData: data,
      error,
      isOccupationTitlesLoading: isLoading,
    }
  }

  /**
   * Co-ordinate load of external SFS data into state
   * @returns 
   */
  const loadSfsData = (sfs) => {
    console.log("Load SFS Data: ", sfs);

    //sfs = null; // For testing

    setSfsCreatedDate(sfs?.createdWhen || null);
    const months = sfs?.createdWhen ? dayjs().diff(dayjs(sfs?.createdWhen), "months") : null;
    setSfsMode(months !== null && months < 12 ? "review" : "edit");
    setMonthsSinceReview(months);

    //setMonthsSinceReview(6); // For testing

    // About You
    
    setEmploymentStatus(sfs?.customersEmploymentStatus || "UNK");

    setEmploymentTypeId(sfs?.employmentType || employmentTypeIdMap.Unknown);
    setEmploymentTitleId(sfs?.employmentTitle || employmentTitleIdMap.Unknown);
    setSelectedEmployment({
      occupationTypeId: sfs?.employmentType || employmentTypeIdMap.Unknown,
      occupationTitleId: sfs?.employmentTitle || employmentTitleIdMap.Unknown,    
    });

    setPartnerEmploymentStatus(sfs?.partnersEmploymentStatus || "UNK");
    setHomeStatus(sfs?.homeStatus || "");
    const maxHouseholdValues = {
       adults: 6,
       childrenUnder16: 8,
       children16to18: 8,
       numberOfVehicles: 5
    }

    setHousehold([
      { id: "adults", value: sfs?.adults || 0, label: "Adults", maxValue: maxHouseholdValues.adults},
      { id: "childrenUnder16", value: sfs?.childrenUnder16 || 0, label: "Children under 16", maxValue: maxHouseholdValues.childrenUnder16},
      { id: "children16to18", value: sfs?.children16to18 || 0, label: "Children 16 - 18", maxValue: maxHouseholdValues.children16to18},
      { id: "numberOfVehicles", value: sfs?.numberOfVehicles || 0, label: "Vehicles", maxValue: maxHouseholdValues.numberOfVehicles},
    ]);
    
    // Financial sections
    const sfsIncomeData = loadSfsIncome(sfs ? sfs.income : null);
    const sfsFixedData = loadSfsExpenditureFixed(sfs ? sfs.fixed : null);
    const sfsFlexibleData = loadSfsExpenditureFlexible(sfs ? sfs.flexible : null);
    const sfsDebtsData = loadSfsAdditionalDebt(sfs ? sfs.debts : null);
    loadSfsTotals(sfsIncomeData, sfsFixedData, sfsFlexibleData, sfsDebtsData);
  }


  /**
   * Dump a section of SFS into an object
   * @returns 
   */
  const dumpSfsSection = (section, debts=false) => {
    let sectionObj = Object();
    section.map((item) => {
      sectionObj[`${item.key}Freq`] = item.frequency;
      if (!debts) {
        sectionObj[`${item.key}Amount`] = item.value;
      } else {
        sectionObj[`${item.key}RepaymentAmount`] = item.value;      
        sectionObj[`${item.key}OutstandingAmount`] = item.balance;
      }
    });
    return sectionObj;
  };

  /**
   * Co-ordinate dump of internal SFS data into POST body
   * @returns 
   */
  const saveSfsData = (sfs) => {
    console.log("Dump SFS Data: ", sfs);

    const today = dayjs().format("YYYY-MM-DD");
    setSfsCreatedDate(today);
    setMonthsSinceReview(0);

    const newObj = {
      debtorId: sfs?.debtorId || partyId,
      adults: household[0].value,
      children16to18: household[1].value,
      childrenUnder16: household[2].value,
      numberOfVehicles: household[3].value,
      confirmMonthlySavingsContribution: sfs?.confirmMonthlySavingsContribution || false,
      createdBy: sfs?.createdBy || "",
      createdWhen: today,
      customersEmploymentStatus: employmentStatus || "UNK",
      employmentTitle: selectedEmployment?.occupationTitleId || employmentTitleIdMap.Unknown,
      employmentType: selectedEmployment?.occupationTypeId || employmentTypeIdMap.Unknown,
      homeStatus: homeStatus || "",
      incomeAndExpenditureStatus: "Completed", // Can be Partial
      monthlySavingsAmount: sfs?.monthlySavingsAmount || 0,
      notes: sfs?.notes || "",
      optOutSavingsContribution: sfs?.optOutSavingsContribution || true,
      partnersEmploymentStatus: partnerEmploymentStatus || "UNK",
      income: dumpSfsSection(sfsIncome),
      fixed: dumpSfsSection(sfsExpenditureFixed),
      fixedOther: sfs?.fixedOther || [],
      flexible: dumpSfsSection(sfsExpenditureFlexible),
      debts: dumpSfsSection(sfsAdditionalDebt, true),
      triggerFigures: sfs?.triggerFigures || {},
    }

    console.log("New SFS POST Object: ", newObj);

    if (accessToken) {
      postSfs(`${apiUrl}/standard-financial-statement`, newObj);
    }
  }


  /**
   * ETL SFS income data & total into state
   * @returns
   */
  const loadSfsIncome = (income) => {
    console.log("Load SFS income data: ", income);
    const defaultAmount = 0.00;
    const defaultFreq = "M";
    const newIncome = [
      { parent: "Income", category: "Income", title: "Salary/Wage", key: "customerSalaryWage", value: income?.customerSalaryWageAmount || defaultAmount, frequency: income?.customerSalaryWageFreq || defaultFreq },
      { parent: "Income", category: "Income", title: "Other earnings", key: "otherEarnings", value: income?.otherEarningsAmount || defaultAmount, frequency: income?.otherEarningsFreq || defaultFreq },
      { parent: "Income", category: "Income", title: "Partner salary/wage", key: "partnerSalaryWage", value: income?.partnerSalaryWageAmount || defaultAmount, frequency: income?.partnerSalaryWageFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Carers allowance", key: "carersAllowance", value: income?.carersAllowanceAmount || defaultAmount, frequency: income?.carersAllowanceFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Child benefit", key: "childBenefit", value: income?.childBenefitAmount || defaultAmount, frequency: income?.childBenefitFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Child tax credit", key: "childTaxCredit", value: income?.childTaxCreditAmount || defaultAmount, frequency: income?.childTaxCreditFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Children under 16", key: "childrenUnder16", value: income?.childrenUnder16Amount || defaultAmount, frequency: income?.childrenUnder16Freq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Council tax support", key: "councilTaxSupport", value: income?.councilTaxSupportAmount || defaultAmount, frequency: income?.councilTaxSupportFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Disability benefits", key: "disabilityBenefits", value: income?.disabilityBenefitsAmount || defaultAmount, frequency: income?.disabilityBenefitsFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Employment & support allowance / SSP", key: "emplymentSupportAllowanceorSSP", value: income?.emplymentSupportAllowanceorSSPAmount || defaultAmount, frequency: income?.emplymentSupportAllowanceorSSPFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Income support", key: "incomeSupport", value: income?.incomeSupportAmount || defaultAmount, frequency: income?.incomeSupportFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Jobseekers allowance (contribution based)", key: "jobseekersAllowanceContributionBased", value: income?.jobseekersAllowanceContributionBasedAmount || defaultAmount, frequency: income?.jobseekersAllowanceContributionBasedFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Jobseekers allowance (income based)", key: "jobseekersAllowanceIncomeBased", value: income?.jobseekersAllowanceIncomeBasedAmount || defaultAmount, frequency: income?.jobseekersAllowanceIncomeBasedFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Local housing allowance / Housing benefits", key: "localHousingAllowanceHousingBenefit", value: income?.localHousingAllowanceHousingBenefitAmount || defaultAmount || defaultAmount, frequency: income?.localHousingAllowanceHousingBenefitFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Other benefits tax credits", key: "otherBenefitsTaxCredits", value: income?.otherBenefitsTaxCreditsAmount || defaultAmount, frequency: income?.otherBenefitsTaxCreditsFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Universal credit", key: "universalCredit", value: income?.universalCreditAmount || defaultAmount, frequency: income?.universalCreditFreq || defaultFreq },
      { parent: "Income", category: "Benefits", title: "Working tax credit", key: "workingTaxCredit", value: income?.workingTaxCreditAmount || defaultAmount, frequency: income?.workingTaxCreditFreq || defaultFreq },
      { parent: "Income", category: "Pensions", title: "Other pensions", key: "otherPensions", value: income?.otherPensionsAmount || defaultAmount, frequency: income?.otherPensionsFreq || defaultFreq },
      { parent: "Income", category: "Pensions", title: "Pensions credit", key: "pensionCredit", value: income?.pensionCreditAmount || defaultAmount, frequency: income?.pensionCreditFreq || defaultFreq },
      { parent: "Income", category: "Pensions", title: "Private or work pensions", key: "privateorWorkPensions", value: income?.privateorWorkPensionsAmount || defaultAmount, frequency: income?.privateorWorkPensionsFreq || defaultFreq },
      { parent: "Income", category: "Pensions", title: "State pensions", key: "statePensions", value: income?.statePensionsAmount || defaultAmount, frequency: income?.statePensionsFreq || defaultFreq },
      { parent: "Income", category: "Other", title: "Boarders or lodgers", key: "boardersorLodgers", value: income?.boardersorLodgersAmount || defaultAmount, frequency: income?.boardersorLodgersFreq || defaultFreq },
      { parent: "Income", category: "Other", title: "Maintenance or child support", key: "maintenanceorChildSupport", value: income?.maintenanceorChildSupportAmount || defaultAmount, frequency: income?.maintenanceorChildSupportFreq || defaultFreq },
      { parent: "Income", category: "Other", title: "Non-dependents contributions", key: "nonDependentsContributions", value: income?.nonDependentsContributionsAmount || defaultAmount, frequency: income?.nonDependentsContributionsFreq || defaultFreq },
      { parent: "Income", category: "Other", title: "Other income", key: "otherIncome", value: income?.otherIncomeAmount || defaultAmount, frequency: income?.otherIncomeFreq || defaultFreq },
      { parent: "Income", category: "Other", title: "Student loans & grants", key: "studentLoansandGrants", value: income?.studentLoansandGrantsAmount || defaultAmount, frequency: income?.studentLoansandGrantsFreq || defaultFreq },
    ];
    console.log("Load SFS transformed income data: ", newIncome);
    setSfsIncome(newIncome);
    return newIncome;
  }

  /**
   * ETL SFS fixed expenditure data & total into state
   * @returns
   */
  const loadSfsExpenditureFixed = (fixed) => {
    console.log("Load SFS fixed expenditure data: ", fixed);
    const defaultAmount = 0.00;
    const defaultFreq = "M";
    const newFixed = [
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Appliance & furniture rental", key: "applianceFurnitureRental", value: fixed?.applianceFurnitureRentalAmount || defaultAmount, frequency: fixed?.applianceFurnitureRentalFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Council tax/rates", key: "councilTaxRates", value: fixed?.councilTaxRatesAmount || defaultAmount, frequency: fixed?.councilTaxRatesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Ground rent & service charges", key: "groundRentServiceCharges", value: fixed?.groundRentServiceChargesAmount || defaultAmount, frequency: fixed?.groundRentServiceChargesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Other costs", key: "homeOtherCosts", value: fixed?.homeOtherCostsAmount || defaultAmount, frequency: fixed?.homeOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Mortgage", key: "mortgage", value: fixed?.mortgageAmount || defaultAmount, frequency: fixed?.mortgageFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Mortgage endowment", key: "mortgageEndowment", value: fixed?.mortgageEndowmentAmount || defaultAmount, frequency: fixed?.mortgageEndowmentFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Rent", key: "rent", value: fixed?.rentAmount || defaultAmount, frequency: fixed?.rentFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "Secured loans", key: "securedLoans", value: fixed?.securedLoansAmount || defaultAmount, frequency: fixed?.securedLoansFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Home & Contents", title: "TV licence", key: "tvLicence", value: fixed?.tvLicenceAmount || defaultAmount, frequency: fixed?.tvLicenceFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Adult-care costs", key: "adultCareCosts", value: fixed?.adultCareCostsAmount || defaultAmount, frequency: fixed?.adultCareCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Other costs", key: "careOtherCosts", value: fixed?.careOtherCostsAmount || defaultAmount, frequency: fixed?.careOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Child maintenance", key: "childMaintenanceorChildSupport", value: fixed?.childMaintenanceorChildSupportAmount || defaultAmount, frequency: fixed?.childMaintenanceorChildSupportFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Childcare costs", key: "childcareCosts", value: fixed?.childcareCostsAmount || defaultAmount, frequency: fixed?.childcareCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Dentistry & opticians", key: "dentistryandOpticians", value: fixed?.dentistryandOpticiansAmount || defaultAmount, frequency: fixed?.dentistryandOpticiansFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Care & Health", title: "Prescriptions & medicine", key: "prescriptionsandMedicines", value: fixed?.prescriptionsandMedicinesAmount || defaultAmount, frequency: fixed?.prescriptionsandMedicinesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Buildings & content", key: "buildingsandContent", value: fixed?.buildingsandContentAmount || defaultAmount, frequency: fixed?.buildingsandContentFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Health insurance", key: "healthInsurance", value: fixed?.healthInsuranceAmount || defaultAmount, frequency: fixed?.healthInsuranceFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Life insurance", key: "lifeInsurance", value: fixed?.lifeInsuranceAmount || defaultAmount, frequency: fixed?.lifeInsuranceFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Mortgage payment protection", key: "mortgagePaymentProtection", value: fixed?.mortgagePaymentProtectionAmount || defaultAmount, frequency: fixed?.mortgagePaymentProtectionFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Other costs", key: "pensionOtherCosts", value: fixed?.pensionOtherCostsAmount || defaultAmount, frequency: fixed?.pensionOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Pensions & Insurance", title: "Pension payments", key: "pensionPayments", value: fixed?.pensionPaymentsAmount || defaultAmount, frequency: fixed?.pensionPaymentsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Utilities", title: "Electricity", key: "electricity", value: fixed?.electricityAmount || defaultAmount, frequency: fixed?.electricityFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Utilities", title: "Gas", key: "gas", value: fixed?.gasAmount || defaultAmount, frequency: fixed?.gasFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Utilities", title: "Other costs (coal, oil etc.)", key: "utilOtherCosts", value: fixed?.utilOtherCostsAmount || defaultAmount, frequency: fixed?.utilOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Utilities", title: "Other expenditure", key: "utilOtherExpenditure", value: fixed?.utilOtherExpenditureAmount || defaultAmount, frequency: fixed?.utilOtherExpenditureFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Water", title: "Water supply", key: "waterSupply", value: fixed?.waterSupplyAmount || defaultAmount, frequency: fixed?.waterSupplyFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Water", title: "Water waste", key: "waterWaste", value: fixed?.waterWasteAmount || defaultAmount, frequency: fixed?.waterWasteFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "School Costs", title: "After-school clubs & school trips", key: "afterSchoolClubsandSchoolTrips", value: fixed?.afterSchoolClubsandSchoolTripsAmount || defaultAmount, frequency: fixed?.afterSchoolClubsandSchoolTripsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "School Costs", title: "Other costs", key: "schoolOtherCosts", value: fixed?.schoolOtherCostsAmount || defaultAmount, frequency: fixed?.schoolOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "School Costs", title: "School uniform", key: "schoolUniform", value: fixed?.schoolUniformAmount || defaultAmount, frequency: fixed?.schoolUniformFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Professional Costs", title: "Professional fees", key: "prefessionalFees", value: fixed?.prefessionalFeesAmount || defaultAmount, frequency: fixed?.prefessionalFeesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Professional Costs", title: "Professional courses", key: "professionalCourses", value: fixed?.professionalCoursesAmount || defaultAmount, frequency: fixed?.professionalCoursesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Professional Costs", title: "Other costs", key: "profressionalOtherCosts", value: fixed?.profressionalOtherCostsAmount || defaultAmount, frequency: fixed?.profressionalOtherCostsFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Professional Costs", title: "Union fees", key: "unionFees", value: fixed?.unionFeesAmount || defaultAmount, frequency: fixed?.unionFeesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Breakdown cover", key: "breakdownCover", value: fixed?.breakdownCoverAmount || defaultAmount, frequency: fixed?.breakdownCoverFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Car insurance", key: "carInsurance", value: fixed?.carInsuranceAmount || defaultAmount, frequency: fixed?.carInsuranceFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Fuel, parking, toll charges", key: "fuelParkingTollCharges", value: fixed?.fuelParkingTollChargesAmount || defaultAmount, frequency: fixed?.fuelParkingTollChargesFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Hire purchase", key: "hirePurchase", value: fixed?.hirePurchaseAmount || defaultAmount, frequency: fixed?.hirePurchaseFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "MOT & ongoing maintenance", key: "moTandongoingMaintenance", value: fixed?.moTandongoingMaintenanceAmount || defaultAmount, frequency: fixed?.moTandongoingMaintenanceFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Public transport", key: "publicTransport", value: fixed?.publicTransportAmount || defaultAmount, frequency: fixed?.publicTransportFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Road tax", key: "roadTax", value: fixed?.roadTaxAmount || defaultAmount, frequency: fixed?.roadTaxFreq || defaultFreq },
      { parent: "Fixed Expenditure", category: "Transport & Travel", title: "Other costs (inc taxis)", key: "transportOtherCosts", value: fixed?.transportOtherCostsAmount || defaultAmount, frequency: fixed?.transportOtherCostsFreq || defaultFreq },
    ];
    console.log("Load SFS transformed fixed expenditure data: ", newFixed);
    setSfsExpenditureFixed(newFixed);
    return newFixed;
  }

  /**
   * ETL SFS flexible expenditure data & total into state
   * @returns
   */
  const loadSfsExpenditureFlexible = (flexible) => {
    console.log("Load SFS flexible expenditure data: ", flexible);
    const defaultAmount = 0.00;
    const defaultFreq = "M";
    const newFlexible = [
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Other costs", key: "communicationsOtherCosts", value: flexible?.clothingandFootwearAmount || defaultAmount, frequency: flexible?.clothingandFootwearFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Gifts (e.g. birthdays)", key: "gifts", value: flexible?.giftsAmount || defaultAmount, frequency: flexible?.giftsFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Hobbies, leisure or sport", key: "hobbiesLeisureOrSport", value: flexible?.hobbiesLeisureOrSportAmount || defaultAmount, frequency: flexible?.hobbiesLeisureOrSportFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Home phone", key: "homePhoneInternetTVPackage", value: flexible?.homePhoneInternetTVPackageAmount || defaultAmount, frequency: flexible?.homePhoneInternetTVPackageFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Mobile phone", key: "mobilePhone", value: flexible?.mobilePhoneAmount || defaultAmount, frequency: flexible?.mobilePhoneFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Newspapers, magazines etc.", key: "newspapersMagazinesStationaryPostage", value: flexible?.newspapersMagazinesStationaryPostageAmount || defaultAmount, frequency: flexible?.newspapersMagazinesStationaryPostageFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Communications & Leisure", title: "Pocket money", key: "pocketMoney", value: flexible?.pocketMoneyAmount || defaultAmount, frequency: flexible?.pocketMoneyFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Alcohol", key: "alcohol", value: flexible?.alcoholAmount || defaultAmount, frequency: flexible?.alcoholFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Groceries", key: "groceries", value: flexible?.groceriesAmount || defaultAmount, frequency: flexible?.groceriesFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "House repairs & maintenance", key: "houseRepairsAndMaintenance", value: flexible?.houseRepairsAndMaintenanceAmount || defaultAmount, frequency: flexible?.houseRepairsAndMaintenanceFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Other costs", key: "housekeepingOtherCosts", value: flexible?.housekeepingOtherCostsAmount || defaultAmount, frequency: flexible?.housekeepingOtherCostsFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Laundry & dry cleaning", key: "laundryandDryCleaning", value: flexible?.laundryandDryCleaningAmount || defaultAmount, frequency: flexible?.laundryandDryCleaningFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Nappies & baby items", key: "nappiesAndBabyItems", value: flexible?.nappiesAndBabyItemsAmount || defaultAmount, frequency: flexible?.nappiesAndBabyItemsFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "School meals & work meals", key: "schoolMealsAndMealsAtWork", value: flexible?.schoolMealsAndMealsAtWorkAmount || defaultAmount, frequency: flexible?.schoolMealsAndMealsAtWorkFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Smoking products", key: "smokingProducts", value: flexible?.smokingProductsAmount || defaultAmount, frequency: flexible?.smokingProductsFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Food & Housekeeping", title: "Vet bills", key: "vetBillsandPetInsurance", value: flexible?.vetBillsandPetInsuranceAmount || defaultAmount, frequency: flexible?.vetBillsandPetInsuranceFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Personal Costs", title: "Clothing & footwear", key: "clothingandFootwear", value: flexible?.clothingandFootwearAmount || defaultAmount, frequency: flexible?.clothingandFootwearFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Personal Costs", title: "Hairdressing", key: "hairdressing", value: flexible?.hairdressingAmount || defaultAmount, frequency: flexible?.hairdressingFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Personal Costs", title: "Other costs", key: "personalOtherCosts", value: flexible?.personalOtherCostsAmount || defaultAmount, frequency: flexible?.personalOtherCostsFreq || defaultFreq },
      { parent: "Flexible Expenditure", category: "Personal Costs", title: "Toiletries", key: "toiletries", value: flexible?.toiletriesAmount || defaultAmount, frequency: flexible?.toiletriesFreq || defaultFreq },
    ];
    console.log("Load SFS transformed flexible expenditure data: ", newFlexible);
    setSfsExpenditureFlexible(newFlexible);
    return newFlexible;
  }

  /**
   * ETL SFS additional debt data & total into state
   * @returns
   */
  const loadSfsAdditionalDebt = (debt) => {
    console.log("Load SFS additional debt data: ", debt);
    const defaultAmount = 0.00;
    const defaultFreq = "M";
    const newDebts = [
      { parent: "Additional Debt", category: "Priority Debts", title: "Council tax", key: "councilTaxArrears", balance: debt?.councilTaxArrearsOutstandingAmount || defaultAmount, value: debt?.councilTaxArrearsRepaymentAmount || defaultAmount, frequency: debt?.councilTaxArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Hire purchase", key: "hirePurchase", balance: debt?.hirePurchaseOutstandingAmount || defaultAmount, value: debt?.hirePurchaseRepaymentAmount || defaultAmount, frequency: debt?.hirePurchaseFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Maintenance", key: "maintenance", balance: debt?.maintenanceOutstandingAmount || defaultAmount, value: debt?.maintenanceRepaymentAmount || defaultAmount, frequency: debt?.maintenanceFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Magistrate fines", key: "migistrateFines", balance: debt?.migistrateFinesOutstandingAmount || defaultAmount, value: debt?.migistrateFinesRepaymentAmount || defaultAmount, frequency: debt?.migistrateFinesFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Mortgage arrears", key: "mortgageArrears", balance: debt?.mortgageArrearsOutstandingAmount || defaultAmount, value: debt?.mortgageArrearsRepaymentAmount || defaultAmount, frequency: debt?.mortgageArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Other arrears", key: "otherArrears", balance: debt?.otherArrearsOutstandingAmount || defaultAmount, value: debt?.otherArrearsRepaymentAmount || defaultAmount, frequency: debt?.otherArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Other secured loans", key: "otherSecuredLoans", balance: debt?.otherSecuredLoansOutstandingAmount || defaultAmount, value: debt?.otherSecuredLoansRepaymentAmount || defaultAmount, frequency: debt?.otherSecuredLoansFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Rent arrears", key: "rentArrears", balance: debt?.rentArrearsOutstandingAmount || defaultAmount, value: debt?.rentArrearsRepaymentAmount || defaultAmount, frequency: debt?.rentArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Priority Debts", title: "Utilities arrears", key: "utilitiesArrears", balance: debt?.utilitiesArrearsOutstandingAmount || defaultAmount, value: debt?.utilitiesArrearsRepaymentAmount || defaultAmount, frequency: debt?.utilitiesArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Non Priority Debts", title: "Credit card arrears", key: "creditCardArrears", balance: debt?.creditCardArrearsOutstandingAmount || defaultAmount, value: debt?.creditCardArrearsRepaymentAmount || defaultAmount, frequency: debt?.creditCardArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Non Priority Debts", title: "Other debt arrears", key: "otherDebtArrears", balance: debt?.otherDebtArrearsOutstandingAmount || defaultAmount, value: debt?.otherDebtArrearsRepaymentAmount || defaultAmount, frequency: debt?.otherDebtArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Non Priority Debts", title: "Overdrafts", key: "overdrafts", balance: debt?.overdraftsOutstandingAmount || defaultAmount, value: debt?.overdraftsRepaymentAmount || defaultAmount, frequency: debt?.overdraftsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Non Priority Debts", title: "Store card arrears", key: "storeCardArrears", balance: debt?.storeCardArrearsOutstandingAmount || defaultAmount, value: debt?.storeCardArrearsRepaymentAmount || defaultAmount, frequency: debt?.storeCardArrearsFreq || defaultFreq },
      { parent: "Additional Debt", category: "Non Priority Debts", title: "Unsecured loan arrears", key: "unsecuredLoansArrears", balance: debt?.unsecuredLoansArrearsOutstandingAmount || defaultAmount, value: debt?.unsecuredLoansArrearsRepaymentAmount || defaultAmount, frequency: debt?.unsecuredLoansArrearsFreq || defaultFreq },

    ];
    console.log("Load SFS additional debt data: ", newDebts);
    setSfsAdditionalDebt(newDebts);
    return newDebts;
  }

  const getCategoryTotals = ((data, categoryName) => {
    return data.filter(({category}) => category === categoryName).reduce((total, obj) =>
      total + getMonthlyAmount({"value": obj.value, "frequency": obj.frequency}), 0)}
  );

 const categoryMaxValues ={
  "Income": 100000,
  "Benefits": 10000,
  "Pensions": 10000,
  "Other": 10000,
  }

  const fixedValue = {
    "Home & Contents": 10000,
    "Pensions & Insurance": 10000,
    "Water": 10000,
    "Professional Costs": 10000,
    "Care & Health": 10000,
    "Utilities": 10000,
    "School Costs": 10000,
    "Transport & Travel": 10000
  }

const flexValue = {
    "Communications & Leisure": 10000,   
    "Food & Housekeeping": 10000,
    "Personal Costs": 10000,
    "Savings": 10000
  }

const addDebtValue = {
    "Priority Debts": 10000,   
    "Non Priority Debts": 10000,
  }


  /**
   * SFS category totals
   * @returns
   */
  const loadSfsTotals = (income, fixed, flexible, debts) => {
    console.log("Load SFS totals data");
    const defaultAmount = 0.00;
    const newSfsTotals = {
      categories: [
        { id: "income", parent: "Income", name: "Income", value: income ? getCategoryTotals(income, "Income") : defaultAmount },
        { id: "benefits", parent: "Income", name: "Benefits", value: income ? getCategoryTotals(income, "Benefits") : defaultAmount },
        { id: "pensions", parent: "Income", name: "Pensions", value: income ? getCategoryTotals(income, "Pensions") : defaultAmount },
        { id: "other", parent: "Income", name: "Other", value: fixed ? getCategoryTotals(income, "Other") : defaultAmount },
        { id: "home-contents", parent: "Fixed Expenditure", name: "Home & Contents", value: fixed ? getCategoryTotals(fixed, "Home & Contents") : defaultAmount },
        { id: "care-health", parent: "Fixed Expenditure", name: "Care & Health", value: fixed ? getCategoryTotals(fixed, "Care & Health") : defaultAmount },
        { id: "pensions-insurance", parent: "Fixed Expenditure", name: "Pensions & Insurance", value: fixed ? getCategoryTotals(fixed, "Pensions & Insurance") : defaultAmount },
        { id: "utilities", parent: "Fixed Expenditure", name: "Utilities", value: fixed ? getCategoryTotals(fixed, "Utilities") : defaultAmount },
        { id: "water", parent: "Fixed Expenditure", name: "Water", value: fixed ? getCategoryTotals(fixed, "Water") : defaultAmount },
        { id: "school-costs", parent: "Fixed Expenditure", name: "School Costs", value: fixed ? getCategoryTotals(fixed, "School Costs") : defaultAmount },
        { id: "professional-costs", parent: "Fixed Expenditure", name: "Professional Costs", value: fixed ? getCategoryTotals(fixed, "Professional Costs") : defaultAmount },
        { id: "transport-travel", parent: "Fixed Expenditure", name: "Transport & Travel", value: fixed ? getCategoryTotals(fixed, "Transport & Travel") : defaultAmount },
        { id: "other-costs", parent: "Fixed Expenditure", name: "Other Cost", value: fixed ? getCategoryTotals(fixed, "Other Cost") : defaultAmount },
        { id: "comms-leisure", parent: "Flexible Expenditure", name: "Communications & Leisure", value: flexible ? getCategoryTotals(flexible, "Communications & Leisure") : defaultAmount },
        { id: "food-house", parent: "Flexible Expenditure", name: "Food & Housekeeping", value: flexible ? getCategoryTotals(flexible, "Food & Housekeeping") : defaultAmount },
        { id: "personal-costs", parent: "Flexible Expenditure", name: "Personal Costs", value: flexible ? getCategoryTotals(flexible, "Personal Costs") : defaultAmount },
        { id: "savings", parent: "Flexible Expenditure", name: "Savings", value: flexible ? getCategoryTotals(flexible, "Savings") : defaultAmount },
        { id: "priority-debt", parent: "Additional Debt", name: "Priority Debts", value: debts ? getCategoryTotals(debts, "Priority Debts") : defaultAmount },
        { id: "non-priority-debt", parent: "Additional Debt", name: "Non Priority Debts", value: debts ? getCategoryTotals(debts, "Non Priority Debts") : defaultAmount },
      ],
      totals: [
        { id: "income", name: "Income", value: income.reduce((total, obj) => total + getMonthlyAmount({frequency: obj.frequency, value: obj.value}), 0.00) },
        { id: "fixed-expenditure", name: "Fixed Expenditure", value: fixed.reduce((total, obj) => total + getMonthlyAmount({frequency: obj.frequency, value: obj.value}), 0.00) },
        { id: "flexible-expenditure", name: "Flexible Expenditure", value: flexible.reduce((total, obj) => total + getMonthlyAmount({frequency: obj.frequency, value: obj.value}), 0.00) },
        { id: "additional-debt", name: "Additional Debt", value: debts.reduce((total, obj) => total + getMonthlyAmount({frequency: obj.frequency, value: obj.value}), 0.00) },
      ]
    };
    console.log("Load SFS totals data: ", newSfsTotals);
    setSfsTotals(newSfsTotals);
  };

  const changeCategory = (category) => {
    if (category) {
      // Rebuild category objects updating selected flags
      const newUniqueCategories = categories.map((item, idx) => item.name === category.name ? 
        { ...category, selected : true } : { ...item, selected: false });
      setCategories(newUniqueCategories);
      setSelectedCategory(category.name);
    } 
  }

  const getMonthlyAmount = (item) => {
    // Adjust a monetary value to a monthly amount by frequency
    let amount = 0;
    switch(item.frequency) {
      case "M":
        amount = item.value; 
        break;
      case "W":
        amount = (item.value*52)/12;
        break;
      case "F":
        amount = (item.value*26)/12;
        break;
      case "4W":
        amount = (item.value*13)/12;
        break;
      case "Q":
        amount = (item.value*4.33333)/12;
        break;
      case "A":
        amount = item.value/12;
        break;
      default:
        break;
    };    
    return amount;
  };

  const setSfsCategoryTotal = (newItem, newValues) => {

    // Update summary total for affected category
    const category = newItem.category;
    const parentCategory = newItem.parent;
    let newTotal = 0.00;
    let newParentTotal = 0.00;

    newValues.forEach((item) => {   
      // Calculate new total for category
      if (item.category === category) {
        newTotal += getMonthlyAmount(item)
      }
      // Calculate new total for parent category
      if (item.parent === parentCategory) {
        newParentTotal += getMonthlyAmount(item)
      }
    });

    // Update totals for affected category and parent
    setSfsTotals({
      ...sfsTotals,
      categories: sfsTotals.categories.map(
        (item) => item.name === category ? { ...item, value: newTotal } : item),
      totals: sfsTotals.totals.map(
        (item) => item.name === parentCategory ? { ...item, value: newParentTotal } : item),
    });
  }

  // SFS Stepper
  const [ activeStep, setActiveStep ] = useState(0);

  useEffect(() => {
    // When parent totals change, recalculate disposable income
    if (!(sfsTotals === null)) {
      // Set the sfs loaded flag if required
      if (!sfsLoaded) {
        setSfsLoaded(true);
      };
      // Recalculate disposable income
      let newDisposableIncome = 0.00;
      if (sfsTotals.totals) {
        sfsTotals.totals.map((item) => item.name === "Income" ? 
          newDisposableIncome += item.value : newDisposableIncome -= item.value );
      };
      setDisposableIncome(newDisposableIncome);
    }
  }, [sfsTotals]);

  const contextValue = {
    sfsLoaded,
    setSfsLoaded,
    sfsMode,
    setSfsMode,
    sfsCreatedDate,
    setSfsCreatedDate,
    monthsSinceReview,
    setMonthsSinceReview,
    disposableIncome,
    sfsIncome,
    setSfsIncome,
    sfsExpenditureFixed,
    setSfsExpenditureFixed,
    sfsExpenditureFlexible,
    setSfsExpenditureFlexible,
    sfsAdditionalDebt,
    setSfsAdditionalDebt,
    loadSfsData,
    saveSfsData,
    categories,
    employmentTitleIdMap,
    employmentTypeIdMap,
    setCategories,
    changeCategory,
    selectedCategory,
    frequencies,
    setSelectedCategory,
    employmentStatus,
    setEmploymentStatus,
    employmentTypeId,
    setEmploymentTypeId,
    employmentTitleId,
    setEmploymentTitleId,
    selectedEmployment,
    setSelectedEmployment,
    setPartnerEmploymentStatus,
    partnerEmploymentStatus,
    homeStatus,
    setHomeStatus,
    household,
    setHousehold,
    sfsTotals,
    setSfsTotals,
    setSfsCategoryTotal,
    activeStep,
    setActiveStep,
    useGetSfs,
    postSfs,
    useGetOccupationTypes,
    useGetOccupationTitles,
    categoryMaxValues,
    fixedValue,
    flexValue,
    addDebtValue,
    employmentStatuses,
    homeStatuses,
  }

  return (
    <SfsContext.Provider value={ contextValue }>
      {children}
    </SfsContext.Provider>
  );
}

export default SfsProvider;