import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CookieIcon from '@mui/icons-material/Cookie';
import { useCookie } from "../../context/CookieProvider";

const CookieConsentDialog = ({ open, onClose, onAccept, onDecline }) => {
  const { setCookie } = useCookie();

  const handleAccept = () => {
    setCookie("google_analytics", "accepted", 365, "/");
    if (onAccept) onAccept();
    onClose();
  };

  const handleDecline = () => {
    setCookie("google_analytics", "declined", 365, "/");
    if (onDecline) onDecline();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ textAlign: "center", padding: "20px" }}>
      <CookieIcon sx={{ width: "100px", height: "100px", marginLeft: "41%", color: 'primary.main' }} />
      <DialogTitle>Cookie Consent</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "#000" }}>
          This website has optional cookies which will be applied to your computer with your consent.
          These Cookies are performance and analytical cookies designed to track and measure your engagement with our website, any data captured by these cookies are promptly anonymised.
          For more information, please visit our cookies policy by <a href="/CookiePolicy" target="_blank" style={{ color: '#005969', textDecoration: "underline" }}>clicking here.</a>
          <br /><br />
          Do you accept these cookies operating on your device?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={handleAccept} sx={{ backgroundColor: 'primary.dark', borderRadius: '0px', marginRight: "15px" }}>
          Accept
        </Button>
        <Button onClick={handleDecline} sx={{ color: 'primary.dark', borderColor: 'primary.dark', borderRadius: '0px' }} variant="outlined">
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentDialog;
