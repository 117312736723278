import {
  Box,
  Typography,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";


function ArrangementCardForm({ consent, setConsent }) {
  /* The create repayment card method form component */

  const cardPaymentOptions = [
    "Online through our website",
    "Calling our automated IVR system",
    "Contacting a member of our dedicated staff",
  ];

  return (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        p: 2,
      }}
    >
      <Typography component='h1' fontWeight='bold'>
        Paying by card
      </Typography>

      <Box textAlign='center' px={3} pt={2}>
        <Typography textAlign='left' paddingBottom={2}>
          When setting up a payment plan using a payment card, please note that
          payments will need to be made manually.
        </Typography>

        <Typography textAlign='left'>
          You can conveniently make these payments:
        </Typography>

        <Box paddingX={5}>
          <List sx={{ listStyleType: "circle" }}>
            {cardPaymentOptions.map((item, idx) => (
              <ListItem key={idx} disablePadding sx={{ display: "list-item" }}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography textAlign='left'>
          Whether you prefer the ease of online transactions or the assurance of
          speaking with a real person, we offer multiple options to ensure that
          managing your payments is as convenient as possible. Our team is here
          to assist you every step of the way, so don't hesitate to reach out if
          you have any questions or need further assistance.
        </Typography>
      </Box>

      {/* Payment method confirmation */}
      <Box
        id='declaration'
        sx={{ display: "flex", flexDirection: "row", pl: 2 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id='declaration-checkbox'
            checked={consent}
            onChange={() => {
              setConsent(!consent);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box id='declaration-statement' padding={0.5}>
          <Typography
            component='h2'
            variant='p'
            sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
          >
            I confirm that I am the account holder and have the authority to set
            up card payments on this account.
          </Typography>
        </Box>
      </Box>
      {/* End of payment method confirmation */}
    </Card>
  );
}

export default ArrangementCardForm;
