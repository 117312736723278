import { Container, Box, Typography } from "@mui/material";

import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { useSfs } from "../../context/SfsProvider";

const ArrangementInfoCard = () => {
  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  const { planMode, lastSavedPlan, paymentFrequencies = {} } = useArrangement();
  const { disposableIncome } = useSfs();

  return (
    <Box
      sx={{
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box
        sx={{
          pb: 1,
          borderBottom: "1px solid lightgrey",
          backgroundColor: "white",
        }}
      >
        <Container sx={{ width: "100%" }}>
          <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
            {account?.creditorName}
          </Typography>
          <Typography fontSize={13}>Reference number:</Typography>
          <Typography id='reference-num' fontSize={16} fontWeight={"bold"}>
            {account?.accountReference}
          </Typography>
        </Container>
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          component='label'
          htmlFor='balance'
          fontSize={13}
          paddingY={0.5}
        >
          Balance
        </Typography>
        <Box
          id='balance'
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{`£${account?.currentBalance?.toFixed(2)}`}</Box>

        <Typography
          component='label'
          htmlFor='disposable-income'
          fontSize={13}
          paddingY={0.5}
        >
          Disposable income
        </Typography>
        <Box
          id='disposable-income'
          sx={{
            backgroundColor: "#E2E2E2",
            color: disposableIncome > 0 ? "green" : "purple",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{`£${disposableIncome?.toFixed(2)}`}</Box>

        {planMode === "amend" && (
          <>
            <Typography
              component='label'
              htmlFor='repayment-amount'
              fontSize={13}
              paddingY={0.5}
            >
              Current repayment amount
            </Typography>
            <Box
              id='repayment-amount'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >{`£${lastSavedPlan?.instalmentAmount?.toFixed(2)}`}</Box>

            <Typography
              component='label'
              htmlFor='repayment-day'
              fontSize={13}
              paddingY={0.5}
            >
              Current repayment day
            </Typography>
            <Box
              id='repayment-day'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {lastSavedPlan?.paymentFrequency === "monthly"
                ? lastSavedPlan?.monthlyDay
                : lastSavedPlan?.weeklyDay}
            </Box>

            <Typography
              component='label'
              htmlFor='repayment-frequency'
              fontSize={13}
              paddingY={0.5}
            >
              Current repayment frequency
            </Typography>
            <Box
              id='repayment-frequency'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {paymentFrequencies[lastSavedPlan?.paymentFrequency]}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ArrangementInfoCard;
