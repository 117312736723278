import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Container,
  Box,
  Stack,
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";

import SettlementHeadline from "./SettlementHeadline";
import SettlementAffordability from "./SettlementAffordability";

import { useSettlement } from "../../../context/SettlementProvider";

const handleSubmit = (event) => {
  event.preventDefault();
};

function onChange(e) {
  return null;
}

function SettlementForm() {
  /* The create settlement form component */

  const {
    settlementPlan,
    updateSettlementPlan,
    settlementPaymentMethods,
    settlementPaymentFrequencies,
    selectedOption,
    calculateSettlementStartDate,
  } = useSettlement();

  const [ errorMessage, setErrorMessage ] = useState(null);

  function setPlanPaymentMethod(paymentMethod) {
    setErrorMessage(null);
    updateSettlementPlan({ ...settlementPlan, paymentMethod });
  }

  function setPlanWeeklyDay(weeklyDay) {
    setErrorMessage(null);
    updateSettlementPlan({ ...settlementPlan, weeklyDay });
  }

  function setPlanMonthlyDay(monthlyDay) {
    setErrorMessage(null);
    updateSettlementPlan({ ...settlementPlan, monthlyDay });
  }

  useEffect(() => {
    // Recalculate payment start date when frequency or method changes
    const newStartDate = calculateSettlementStartDate(
      settlementPlan?.paymentMethod, settlementPlan?.monthlyDay);
    updateSettlementPlan({ ...settlementPlan, dateCreated: newStartDate, nextPaymentDate: newStartDate });
  }, [
    settlementPlan?.paymentFrequency,
    settlementPlan?.monthlyDay,
    settlementPlan?.weeklyDay,
    settlementPlan?.paymentMethod,
  ]);

  return settlementPlan ? (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 3,
      }}
    >
      {/* Arrangement calculator section */}
      <Container
        sx={{
          marginY: 2,
        }}
      >
        <SettlementHeadline setErrorMessage={setErrorMessage} />
      </Container>
      {/* End of arrangement calculator section */}

      <Box component='form' onSubmit={handleSubmit} noValidate>
        {/* Calculator error display */}
        <Box sx={{ mx: 3 }}>
          <Typography sx={{ color: "red" }}>
            {errorMessage && `* ${errorMessage}`}
          </Typography>
        </Box>
        <Stack
          sx={{
            paddingX: 1,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          {/* Arrangement form left column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component='label'
              htmlFor='instalments'
              fontWeight='bold'
            >
              {`Number of £${settlementPlan?.instalmentAmount?.toFixed(2)} instalments`}
            </Typography>
            <TextField
              margin='dense'
              required
              fullWidth
              size='small'
              id='instalments'
              name='instalments'
              value={settlementPlan?.numberOfInstalments}
              autoFocus
              InputProps={{
                readOnly: true,
                style: {
                  borderRadius: "0px",
                  backgroundColor: "#E2E2E2",
                },
              }}
            />

            <Typography
              component='label'
              htmlFor='frequency'
              fontWeight={"bold"}
              sx={{ mt: { xs: 4, md: 2 } }}
            >
              Payment frequency
            </Typography>
            <Select
              disabled
              sx={{ mt: 1, borderRadius: "0px" }}
              id='frequency'
              size='small'
              value={settlementPlan?.paymentFrequency}
            >
              {Object.keys(settlementPaymentFrequencies).map((key) => (
                <MenuItem key={key} value={key}>
                  {settlementPaymentFrequencies[key]}
                </MenuItem>
              ))}
            </Select>

            <Typography
              component='label'
              htmlFor='method'
              fontWeight={"bold"}
              sx={{ mt: { xs: 4, md: 2 } }}
            >
              Payment method
            </Typography>
            <Select
              disabled={selectedOption === "1"}
              sx={{ mt: 1, borderRadius: "0px" }}
              id='method'
              size='small'
              value={settlementPlan?.paymentMethod}
              onChange={(event) => setPlanPaymentMethod(event.target.value)}
            >
              <MenuItem value={settlementPaymentMethods["UK DIRECT DEBIT"]}>Direct Debit</MenuItem>
              <MenuItem value={settlementPaymentMethods["DEBIT CARD PAYMENT"]}>Card Payment</MenuItem>
            </Select>

          </CardContent>
          {/* End of arrangement form left column */}

          {/* Arrangement form right column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography component='label' htmlFor='amount' fontWeight={"bold"}>
              Final payment amount
            </Typography>
            <TextField
              margin='dense'
              required
              InputProps={{
                style: {
                  borderRadius: 0,
                  backgroundColor: "#E2E2E2",
                },
              }}
              fullWidth
              size='small'
              id='amount'
              name='amount'
              value={`£${settlementPlan?.finalPaymentAmount?.toFixed(2)}`}
              autoFocus
              onChange={onChange}
            />

            <>
              <Typography
                component='label'
                htmlFor='pay-day'
                fontWeight={"bold"}
                sx={{ mt: { xs: 4, md: 2 }, fontWeight: "bold" }}
              >
                Pay on day
              </Typography>
              {settlementPlan?.paymentFrequency !== "monthly" && (
                <Select
                  sx={{ mt: 1, borderRadius: "0px" }}
                  id='pay-weekday'
                  size='small'
                  value={settlementPlan?.weeklyDay}
                  onChange={(event) => setPlanWeeklyDay(event.target.value)}
                >
                  <MenuItem value={"Monday"}>Monday</MenuItem>
                  <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                  <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                  <MenuItem value={"Thursday"}>Thursday</MenuItem>
                  <MenuItem value={"Friday"}>Friday</MenuItem>
                </Select>
              )}

              {settlementPlan?.paymentFrequency === "monthly" && (
                <Select
                  sx={{ mt: 1, borderRadius: "0px" }}
                  id='pay-day'
                  size='small'
                  value={settlementPlan?.monthlyDay}
                  onChange={(event) => setPlanMonthlyDay(event.target.value)}
                >
                  {Array.from({ length: 28 }, (_, i) => i + 1).map(
                    (item, idx) => (
                      <MenuItem key={idx} value={item}>
                        Day {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}

              {/* Payment date message */}
              <Box sx={{ pt: 1 }}>
                <Typography sx={{ fontSize: 14 }}>
                  * Your new repayment plan will start on {" "}
                  <strong>{dayjs(settlementPlan?.dateCreated).format("DD/MM/YYYY")}</strong>{"."}
                  {settlementPlan?.paymentFrequency === "monthly" ?
                    " If this date falls on a weekend or bank holiday, the payment will be due the following working day." : ""}
                </Typography>
              </Box>
            </>
          </CardContent>
          {/* End of arrangement form right column */}
        </Stack>

        {/* Affordability section */}
        <Box sx={{ paddingX: 3 }}>
          <SettlementAffordability />
        </Box>
        {/* End of affordability section */}

      </Box>
    </Card>
  ) : null;
}

export default SettlementForm;
