import { createContext, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import environment from "../env";
import { useAuth } from "./AuthProvider";
import useSWRImmutable from "swr/immutable";

const SettlementContext = createContext();

/**
 * Settlement custom hook
 * */
export const useSettlement = () => useContext(SettlementContext);

const SettlementProvider = ({ children }) => {

  const { accessToken } = useAuth();

  const [ settlementOptions, setSettlementOptions ] = useState(null);
  const [ selectedOption, setSelectedOption ] = useState(1);
  const [ settlementOffer, setSettlementOffer ] = useState(null);
  const [ settlementDiscount, setSettlementDiscount ] = useState(null);
  const [ settlementSaving, setSettlementSaving ] = useState(null);

  const [ settlementPlan, setSettlementPlan ] = useState(null);

  const noCreditorNoPlan = "No, and I dont have a plan in place";
  const furtherBorrowing = "Further borrowing e.g. Loans";

  const question1Options = [
    { id: "priority-bills-yes", label: "Yes", value: "Yes" },
    { id: "priority-bills-no-with-plan", label: "No, but I have a plan in place", value: "No, but I have a plan in place" },
    { id: "priority-bills-no", label: noCreditorNoPlan, value: noCreditorNoPlan },
  ];
  const [ affordabilityQuestion1, setAffordabilityQuestion1 ] = useState(null);

  const question2Options = [
    { id: "source-employment-income", label: "Employment Income", value: "Employment Income" },
    { id: "source-savings", label: "Savings", value: "Savings" },
    { id: "source-further-borrowing", label: furtherBorrowing, value: furtherBorrowing },
    { id: "source-inheritance", label: "Inheritance", value: "Inherhitance" },    
    { id: "source-gift", label: "Gift", value: "Gift" },
    { id: "source-pension-retirement", label: "Pension and Retirement Funds", value: "Pension and Retirement Funds" },
    { id: "source-government-payments", label: "Government Payments", value: "Government Payments" },    
    { id: "source-legal-settlements", label: "Legal Settlements", value: "Legal Settlements" },
    { id: "source-sale-assets", label: "Sale of Assets", value: "Sale of Assets" },    
    { id: "source-other-sources", label: "Other Sources", value: "Other Sources" },
  ];
  const [ affordabilityQuestion2, setAffordabilityQuestion2 ] = useState(null);

  const question3Options = [
    { id: "creditors-no", label: "Yes", value: "Yes" },
    { id: "creditors-yes-with-plan", label: "No, but I have a plan in place", value: "No, but I have a plan in place" },
    { id: "creditors-yes-no-plan", label: noCreditorNoPlan, value: noCreditorNoPlan },
    { id: "creditors-na", label: "N/A", value: "N/A" },
  ];
  const [ affordabilityQuestion3, setAffordabilityQuestion3 ] = useState(null);

  const apiKey = environment.CUSTOMER_PORTAL_API_KEY;
  const apiUrl = environment.CUSTOMER_PORTAL_API;

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": apiKey,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const settlementPaymentMethods = {
    "DEBIT CARD PAYMENT": 5,
    "UK DIRECT DEBIT": 11,
  };

  const settlementPaymentFrequencies = {
    monthly: "Monthly",
  };

  /**
   * Get settlement SWR hook
   * @returns 
   */
  function useGetSettlementOffer() {
    const url = accessToken ? `${apiUrl}/arrangement/settlement` : null;
    console.info("Get settlement request", {defaultOptions, url})
    const { data, error, isLoading } = useSWRImmutable([url, defaultOptions])
    console.info("Get settlement response", { data, error, isLoading })
    return {
      settlement: data,
      error,
      isSettlementLoading: isLoading,
    }
  };

  /**
   * Create settlement options
   * @returns 
   */
  function createSettlementOptions(settlementOffer, defaultOption) {
    const newSettlementOptions = [
      {
        id: 1,
        label: "Pay now",
        instalmentAmount: settlementOffer,
        selected: true,
      },
      {
        id: 2,
        label: "Pay over 2 months",
        instalmentAmount: settlementOffer/2,
        selected: false,
      },
      {
        id: 3,
        label: "Pay over 3 months",
        instalmentAmount: settlementOffer/3,
        selected: false,
      }
    ];
    createSettlementPlan(newSettlementOptions[defaultOption-1], settlementOffer); // Create default plan
    setSettlementOptions(newSettlementOptions);
  };

  /**
   * Change selected settlement option
   * @returns 
   */
  function changeSelectedSettlementOption(newOption) {
    setSelectedOption(newOption);
    // Create new default settlement plan
    const newOptions = settlementOptions.map(option => {
      if (option.id == newOption) {
        createSettlementPlan(option, settlementOffer);
      };
      return { ...option, selected: option.id == newOption ? true : false }; 
    });
    setSettlementOptions(newOptions);
  };

  /**
   * Create settlement offer
   * @returns 
   */
  function createSettlementOffer(offer) {
    setSettlementOffer(offer);
  };

  /**
   * Create settlement discount
   * @returns 
   */
  function createSettlementDiscount(balance, offer) {
    setSettlementDiscount(100-(Math.floor((offer/balance)*100)));
    const saving = balance - offer;
    setSettlementSaving(saving.toFixed(2));
  };

  useEffect(() => {
    console.log("Settlement plan: ", settlementPlan);
  }, [settlementPlan]);

  /**
   * Create settlement plan
   * @returns 
   */
  function createSettlementPlan(option) {
    const currentDate = dayjs();
    const currentDay = currentDate.date();
    const newSettlementPlan = {
      dateCreated: currentDate,
      instalmentAmount: option.instalmentAmount,
      nextPaymentAmount: option.instalmentAmount,
      inPaymentWindow: false,
      numberOfInstalments: option.id,
      paymentsLeft: option.id,
      paidToDate: 0,
      paymentFrequency: "monthly",
      paymentFrequencyArgs: currentDay <= 28 ? String(currentDay) : "1",
      paymentMethod: settlementPaymentMethods["DEBIT CARD PAYMENT"],
      totalPayable: settlementOffer,
      totalRemaining: settlementOffer,
      sortCode: null,
      accountNumber: null,
      walletId: null,
      isPromiseToPay: true,
      endDate: null,
      weeklyDay: "Monday",
      monthlyPickOrdinal: null,
      monthlyPickDay: null,
      monthlyDay: currentDay <= 28 ? String(currentDay) : "1",
      finalPaymentAmount: option.instalmentAmount,
      type: null,
      settlementPercentage: 100-settlementDiscount,
      discount: null,
      status: null,
      isSettlement: false, // Only set on confirmation as will cause redirect
      sif: true,
    }
    setSettlementPlan(newSettlementPlan);
  };

  /**
   * Update settlement plan
   * @returns 
   */
  function updateSettlementPlan(newSettlementPlan) {
    setSettlementPlan(newSettlementPlan);
  };

  /**
   * Calculate settlement start date on load and when frequency
   * or method changes
   * @returns
   */
  const calculateSettlementStartDate = (paymentMethod, monthlyDay) => {
    // Start date cannot be before buffered date so start there
    const paymentBuffer = paymentMethod === 11 ? 10 : 0; // buffer if direct debit
    let newStartDate = dayjs().add(paymentBuffer, "day");
    if (paymentMethod && monthlyDay) {
      // Now add any required further days
      while (newStartDate.date() !== Number(monthlyDay)) {
        newStartDate = newStartDate.add("1", "day");
      }
    }
    return newStartDate;
  };

  useEffect(() => {
    if (settlementOffer) {
      createSettlementOptions(settlementOffer, selectedOption);
    }
  }, [settlementOffer]);

  const contextValue = {
    settlementPaymentMethods,
    settlementPaymentFrequencies,
    useGetSettlementOffer,
    createSettlementOptions,
    settlementOptions,
    changeSelectedSettlementOption,
    selectedOption,
    setSelectedOption,
    createSettlementOffer,
    settlementOffer,
    createSettlementDiscount,
    settlementDiscount,
    settlementSaving,
    createSettlementPlan,
    settlementPlan,
    updateSettlementPlan,
    affordabilityQuestion1,
    setAffordabilityQuestion1,
    question1Options,
    affordabilityQuestion2,
    setAffordabilityQuestion2,
    question2Options,
    affordabilityQuestion3,
    setAffordabilityQuestion3,
    question3Options,
    calculateSettlementStartDate,
  };

  return (
    <SettlementContext.Provider value={contextValue}>
      {children}
    </SettlementContext.Provider>
  );
};

export default SettlementProvider;
