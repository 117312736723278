import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const frequencies = {
  'W': 'Weekly',
  'M': 'Monthly',
  'F': 'Fortnightly',
  'A': 'Annually',
  '4W': '4-Weekly',
  'Q': 'Quarterly',
};

const statementHeaders = {
  'income': 'Income',
  'fixed': 'Fixed Expenditure',
  'flexible': 'Flexible Expenditure',
  'debts': 'Additional Debt',
};

const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};


const processData = (data) => {
  return Object.keys(data).map(key =>{
    const name = data[key]["title"] ? data[key]["title"] : data[key]["key"] ;
    const freq = frequencies[data[key]["frequency"]] || "";
    const amount = data[key]["value"] || 0;

    return [name, freq, `£${amount.toFixed(2)}`]
  })
}


const generateFinancialStatement = async (
  sfsIncome, sfsExpenditureFlexible, sfsAdditionalDebt,
  sfsExpenditureFixed, party, account, clientLogo, 
  sfsTotals, disposableIncome) => {
  const doc = new jsPDF();
  const statementTypes = ["income", "fixed", "flexible", "debts"];
  const info = 'This document is provided for reference and informational purposes only. It is not intended to serve as an official regulatory document. Please do not use this document for any formal financial or legal purposes.';
  const wrappedInfo = doc.splitTextToSize(info.trim(), 270);
  
  const customerDetails = [
    ['Customer Name', `${party?.customerName.first} ${party?.customerName.last}` || ""],
    ['Account Reference', account?.accountReference || ""],
    ['My Disposable Income (monthly)', disposableIncome > 0 ? `+ £${disposableIncome.toFixed(2)}`: `- £${Math.abs(disposableIncome).toFixed(2)}` || ""],
    ['Date', new Date().toLocaleString().split(',')[0]],
  ];

  try {
    const logo = await loadImage(clientLogo);
    doc.setFillColor(199, 228, 226);
    doc.rect(0, 0, 250, 25, 'F');
    doc.addImage(logo, 'PNG', 10, 5, 65, 14);
  } catch (error) {
    console.error('Error loading logo:', error);
    doc.text('Company logo', 10, 10);
  }

  doc.setFontSize(11);
  doc.text(wrappedInfo, 10, 40);
  doc.autoTable({
    body: customerDetails,
    startY: 60,
    theme: 'striped',
    margin: { top: 20 },
    willDrawCell: (data) => {
      if (data.row.section === 'head' || data.cell.text === '') {
        data.cell.styles.fillColor = null;
        data.cell.text = '';
        data.cell.styles.lineWidth = 0;
      }
    },
  });

  statementTypes.forEach((type) => {
    let processedData = []; 

    switch(type) {
      case "income":
        processedData = processData(sfsIncome);
        break;
      case "fixed":
        processedData = processData(sfsExpenditureFixed);
        break;
      case "flexible":
        processedData = processData(sfsExpenditureFlexible);
        break;
      case "debts":
        processedData = processData(sfsAdditionalDebt);
        break;
      default:
        return processedData;
    }
    
    
    if(Object.keys(processedData).length === 0){
      return;
    }
    const sectionTextPos = doc.autoTable.previous.finalY + 15;
    let totalCellPos = {label:0, total:0};
    let continuingTable = false;
    let currentPage = doc.internal.getCurrentPageInfo().pageNumber;

    doc.setFontSize(14).setFont('helvetica', 'bold');
    doc.text(`My ${statementHeaders[type]}`, 10, sectionTextPos);

    doc.autoTable({
      head: [[`${statementHeaders[type]}`, 'Frequency', 'Amount']],
      body: processedData,
      startY: sectionTextPos + 5,
      theme: 'plain',
      headStyles: { fontStyle: 'bold', fillColor: [0, 86, 105], textColor: [255, 255, 255] },
      margin: { top: 20 },
      didDrawCell: (data) => {
        const y = data.cell.y + data.cell.height;

        totalCellPos.label = (data.cell.x - data.cell.padding('left')) - (data.column.width + data.cell.padding('left'));
        totalCellPos.total = data.cell.x + data.cell.padding('left');
        
        if (data.section === 'body') {
          doc.setLineWidth(0.2);
          doc.line(data.cell.x, y, data.cell.x + data.cell.width, y);
        }

        if (data.section === 'head' || data.row.index === 0 && continuingTable) {
          data.cell.text = ["","","" ];
          data.cell.styles = { fillColor: null, textColor: null, lineWidth: 0 };
        }
      },
      
      didDrawPage: (data) => {
        const newPage = doc.internal.getCurrentPageInfo().pageNumber;
        if (newPage !== currentPage) {
          continuingTable = true;
          currentPage = newPage;
          doc.setFontSize(10).setFont('helvetica', 'italic');
          doc.text(`My ${statementHeaders[type]} (continued)`, 10, 15);
        }
      },
      
    });
    let finalY = doc.autoTable.previous.finalY;
    const total = sfsTotals?.totals.find((item) => item.name === statementHeaders[type]).value;
    
    doc.setFontSize(12).setFont('helvetica', 'bold');
    
    doc.text('Total:', totalCellPos.label, finalY + 5);
    doc.text(`£${total.toFixed(2)}`, totalCellPos.total, finalY + 5);
  });

  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10);
  }
  doc.save('financial-statement.pdf');
};
export default generateFinancialStatement;