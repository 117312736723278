import * as XLSX from 'xlsx';

const formatDateForFilename = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

export const generateTransactionExcel = (transactions) => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
        ['Date', 'Description', 'Amount'],
        ...transactions.map((transaction) => [
            transaction.datePaid,
            transaction.payMethod,
            Number(transaction.totalPaid).toFixed(2),
        ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

    let fileStartDate = '';
    let fileEndDate = '';

    if (transactions.length > 0) {
        const dates = transactions.map(transaction => new Date(transaction.datePaid));
        fileStartDate = formatDateForFilename(new Date(Math.min(...dates)));
        fileEndDate = formatDateForFilename(new Date(Math.max(...dates)));
    } else {
        fileStartDate = formatDateForFilename(new Date());
        fileEndDate = fileStartDate;
    }

    const filename = `transactions_${fileStartDate}_to_${fileEndDate}.xlsx`;
    XLSX.writeFile(workbook, filename);
};
