import React, { useEffect, useState } from "react";
import {
    Box, Container, LinearProgress, Typography, Button
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CookieConsentDialog from "../account/CookieConsentDialog";
import { useNav } from '../../context/NavProvider';
import { useClient } from "../../context/ClientProvider";
import { useCookie } from "../../context/CookieProvider";

function createData(name, type, purpose, retention, description, personal) {
    return { name, type, purpose, retention, description, personal };
}

const rows = [
    createData("session_id", "Necessary (Session)", "Manages user session", "Until browser is closed", "Ensures smooth navigation and session management", "N/A"),
    createData("cookie_consent", "Necessary (Persistent)", "Stores user's cookie consent status", "1 year", "Remembers user's cookie preferences", "N/A"),
    createData("_ga", "Analytics (Persistent)", "Google Analytics - User distinction", "1 year", "Tracks user behaviour across the site for analytics", "Yes - IP(encrypted)"),
    createData("user_preferences", "Persistent", "Stores user preferences", "1 year", "Remembers user settings for future visits", "N/A"),
];

const CookiePolicy = () => {
    const { setShowFooter } = useNav();
    const [isLoading, setIsLoading] = useState(true);
    const { setCookie } = useCookie();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { useGetConfig } = useClient();
    const { clientConfig } = useGetConfig();
    const currentUrl = window.location.hostname;

    useEffect(() => {
        if (!isLoading) {
            setShowFooter(true);
        } else {
            setShowFooter(false);
        }
        return () => {
            setShowFooter(false);
        };
    }, [isLoading, setShowFooter]);

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleManage = () => {
        setDialogOpen(true);
    };

    const handleAcceptCookies = () => {
        setCookie("google_analytics", "accepted", 365, "/");
        setDialogOpen(false);
    };

    const handleDeclineCookies = () => {
        setCookie("google_analytics", "declined", -1, "/");
        setDialogOpen(false);
    };

    return (
        <>
            {isLoading && <LinearProgress />}

            {!isLoading && (
                <Container sx={{ mt: 4, mb: 7, paddingBottom: "350px" }}>
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Cookie Policy</Typography>
                        <Typography paddingTop={1} paddingBottom={3}>We at "{clientConfig?.name}" use cookies on this website <a href="/" style={{ color: 'blue' }}>{currentUrl}</a>
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>What is a cookie?</Typography>
                        <Typography paddingTop={1} paddingBottom={3} >
                            Cookies are small files that contain text which are downloaded on to your device and in some cases allow a website ‘to remember’ the user and their choices.
                            Cookies and other tracing technologies are widely used to make websites work as intended, analyse website traffic, supporting log in attempts,
                            track users browsing data, and provide information to the owners of how the site is used.
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>What is our basis for processing personal data via our cookies?</Typography>
                        <Typography paddingTop={1} paddingBottom={3} >
                            Where a cookie seeks to process your personal data, we operate on the lawful basis of consent for the purposes of this processing.
                            Therefore, your acceptance of our cookies includes acceptance of the limited personal data processing activity that takes place and is outlined in this policy.
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Why do we use Cookies?</Typography>
                        <Typography paddingTop={1} paddingBottom={3}>
                            We use cookies for the following purposes:
                        </Typography>
                        <Typography>
                            Our <b>Strictly necessary</b> cookies are necessary for our website to function and cannot be switched off.
                            These cookies are mainly set in response to actions made by you. For example, logging in or filling in forms.
                            You can set your browser to block these cookies but please note that some parts of our site may not work.
                            These cookies do not store any personal identifiable information and do not require your consent to operate.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            Our <b>Performance cookies</b> count visits and traffic sources to measure and improve the performance of the site.
                            They help to understand what pages are the most and least popular and how visitors move around the site.
                            The personal data we collect, including your I.P address, from these cookies is aggregated and anonymous.
                            These cookies require your consent to operate.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            Our <b>Session cookies</b> are temporary files that expire once you close your browser.
                            They track your activity during a single session helping us analyse trends and performance.
                            These cookies do collect personal data, but like performance cookies, this is anonymised and aggregated.
                            Theses cookies require your consent to operate.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            Our <b>Persistent cookies</b> are similar to session cookies, yet the cookie file remains on your device for a set period of time (please see our ‘for how long are cookies stored for’ section for more information).
                            These cookies can be manually deleted from your device but allow us to recognise returning users to our website.
                            These cookies help us ‘remember’ you and allow us to analyse long-term trends in your interactions with our website.
                            These cookies require your consent to operate.
                        </Typography>

                        <Typography variant="h6" paddingTop={1} paddingBottom={3} sx={{ fontWeight: 'bold' }}>
                            For how long are our cookies stored?
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={3}>
                            Our cookies are stored for varying lengths of time on your browser or device.
                            Session cookies are stored in your device until you close your browser.
                            Persistent cookies have an expiration date and when this date has passed, the cookie is deleted when you return to our website.
                            You will find the retention time for each cookie in our table further below.
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'secondary.light' }}>
                                        <TableCell align="left">Cookie Name</TableCell>
                                        <TableCell align="left">Type of cookie</TableCell>
                                        <TableCell align="left">Purpose</TableCell>
                                        <TableCell align="left">Retention Time</TableCell>
                                        <TableCell align="left">Description</TableCell>
                                        <TableCell align="left">Personal Data Captured</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="left" component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.type}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.purpose}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.retention}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.description}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.personal}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="h6" paddingTop={4} paddingBottom={3} sx={{ fontWeight: 'bold' }}>
                            How to accept or withdraw your cookie consent
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            First time users and users who have manually deleted our cookies or have had their cookies expire, will be greeted by our cookies notification upon visiting this site.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            The pop-up requires you to ‘accept’ or ‘decline’ the cookies we operate on our website, excluding those we class as strictly necessary.
                            By selecting accept you give consent to cookies usage which would mean the notification will not appear on repeat visits.
                            Declining cookies prevents anything other than strictly necessary cookies operating on your device.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            You can manage your cookie consent at any time in the “Cookie Policy” at the bottom of this website. You can accept all our cookies or reject all optional cookies. As mentioned above, this does not apply to strictly necessary cookies.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={3}>
                            Press the button below to access the Cookie Settings, where you can manage your cookie preferences, deciding whether to allow or decline non-essential cookies. (Strictly necessary cookies are always enabled.)
                        </Typography>
                        <Button onClick={handleManage} sx={{ color: 'primary.dark', borderColor: 'primary.dark', borderRadius: '0px' }} variant="outlined">
                            Manage my cookies
                        </Button>
                        <Typography variant="h6" paddingTop={4} paddingBottom={3} sx={{ fontWeight: 'bold' }}>
                            More information and contacts
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            If you have any questions about the processing of your personal data, please read our privacy notice on this website where you also can find all your data subject's rights, our contact information and information regarding how you can exercise your data subject's rights.
                        </Typography>
                        <Typography paddingTop={1} paddingBottom={1}>
                            Text last updated: 29 October 2024
                        </Typography>
                    </Box>
                </Container>
            )}
            <CookieConsentDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAccept={handleAcceptCookies}
                onDecline={handleDeclineCookies}
            />
        </>
    );
};

export default CookiePolicy;
