import { useEffect } from "react";
import { Container, Box, Typography, LinearProgress } from "@mui/material";

import PaymentCardList from "../payment/tokens/PaymentCardList";

import { useNav } from "../../context/NavProvider";
import { usePayment } from "../../context/PaymentProvider";

import DeletePaymentCardDialog from "../payment/tokens/DeletePaymentCardDialog";

import ScriptLoader from "../account/ScriptLoader";


/**
 * Manage Cards page component
 * */
function ManageCardsPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const {
    useGetPaymentTokens,
    paymentTokens,
    loadPaymentTokens,
    paymentTokensLoaded,
  } = usePayment();

  const { data, isTokensLoading } = useGetPaymentTokens();

  useEffect(() => {
    if (!isTokensLoading && !paymentTokensLoaded) {
      loadPaymentTokens(data.tokens);
    };
  }, [isTokensLoading, paymentTokensLoaded]);

  if (isTokensLoading) {
    return <LinearProgress />;
  };

  return paymentTokensLoaded && (
    <>
      <ScriptLoader /> {/* Load scripts based on consent */}
      <Container sx={{ mt: 4, paddingBottom: "450px" }}>
        <Box>
          <Typography fontSize={24} fontWeight={"bold"} paddingTop={1} paddingBottom={3}>
              Manage saved cards
          </Typography>
          {paymentTokens.length > 0 ? (
            <>
              <Typography variant="body1" gutterBottom paddingBottom={3}>
                You can easily view and manage your saved cards, with the option to remove,
                giving you full control over your payment methods and ensuring your information stays current and secure.
              </Typography>
              <PaymentCardList paymentTokens={paymentTokens} />
            </>
          ) : (
            <Typography>
              You currently do not have any saved cards on your account.
              To save a card, simply make a payment and choose the option to save your card for future use.
              Once saved, you’ll be able to view and manage your cards, including the option to remove them at any time,
              giving you full control over your payment methods.
            </Typography>
          )}
        </Box>
      </Container>
      <DeletePaymentCardDialog />
      {setShowFooter(true)}
    </>
  );
}

export default ManageCardsPage;
