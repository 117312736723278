import { Container, Box, Typography } from "@mui/material";

import { useUtils } from "../../../context/UtilsProvider";
import { useAccount } from "../../../context/AccountProvider";
import { useSettlement } from "../../../context/SettlementProvider";

/**
 * The settlement payment method info card component
 * @returns 
 */
const SettlementMethodInfoCard = () => {

  const { capitaliseWords } = useUtils();
  
  const { useGetAccount } = useAccount();
  const { settlementPlan, settlementPaymentMethods } = useSettlement();

  const { account } = useGetAccount();

  return (
    <Box
      sx={{
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box
        sx={{
          pb: 1,
          borderBottom: "1px solid lightgrey",
          backgroundColor: "white",
        }}
      >
        <Container sx={{ width: "60%", display: "inline-block" }}>
          <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
            {account?.creditorName}
          </Typography>
          <Typography fontSize={13}>Reference number:</Typography>
          <Typography fontSize={16} fontWeight={"bold"}>
            {account?.accountReference}
          </Typography>
        </Container>
        {settlementPlan?.paymentMethod === settlementPaymentMethods["UK DIRECT DEBIT"] &&
          <Container sx={{ display: "inline-block", width: "40%" }}>
            <Box
              component='img'
              alt='Direct Debit Logo'
              src='/images/direct-debit.png'
              sx={{
                width: "100px",
                height: "30px",
              }}
            />
          </Container>}
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          component='label'
          htmlFor='balance'
          fontSize={13}
          paddingY={0.5}
        >
          Repayment plan amount
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            pt: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{settlementPlan?.instalmentAmount.toFixed(2)}</Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Repayment date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {settlementPlan?.dateCreated.format("DD/MM/YYYY")}
        </Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan payment method
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          {capitaliseWords(settlementPlan?.paymentMethod ? Object.keys(settlementPaymentMethods).find(
            key => settlementPaymentMethods[key] === settlementPlan.paymentMethod) : "")}
        </Box>

      </Box>
    </Box>
  );
};

export default SettlementMethodInfoCard;
