import React from "react";
import { Stack, Box, Typography, TextField, Select, MenuItem, Button } from "@mui/material";
import ScrollToTop from "../nav/ScrollToTop";
import { NumericFormat } from "react-number-format";
import { useSfs } from "../../context/SfsProvider";

const SfsInputRow = ({ item, setItem, maxValue }) => {
  const { frequencies } = useSfs();

  // Clear input field
  const clearField = () => {
    setItem({ ...item, value: 0.00 });
  };

  // Handle value changes
  const handleValueChange = (values) => {
    // Prevent setting value if it exceeds maxValue
    if (values.floatValue <= maxValue) {
      setItem({ ...item, value: values.floatValue });
    }
  };

  // Handle balance changes
  const handleBalanceChange = (values) => {
    // Prevent setting value if it exceeds maxValue
    if (values.floatValue <= maxValue) {
      setItem({ ...item, balance: values.floatValue });
    }
  };

  return item ? (
    <>
      <ScrollToTop />
      <Stack
        sx={{ mt: 2 }}
        spacing={{ md: 2 }}
        direction={{ xs: "column", md: "row" }}
      >
        <Box sx={{ width: { xs: "100%", md: "65%" }, borderRadius: "0px" }}>
          <Box paddingBottom={1}>
            <Typography
              id={`${item.title.toLowerCase().replace(/[ &/]/g, "-")}-amount-label`}
              fontWeight={"bold"}
              height={"20px"}
              htmlFor={"test"}
            >
              {item.title}
            </Typography>
          </Box>

          <Box>
            <NumericFormat
              id={`${item.title.toLowerCase().replace(/[ &/]/g, "-")}-amount`}
              sx={{ backgroundColor: "#FFF" }}
              customInput={TextField}
              fullWidth
              size='small'
              InputProps={{ style: { borderRadius: 0 } }}
              value={item.value !== undefined ? item.value : ''}
              decimalSeparator='.'
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=','
              prefix='£'
              allowNegative={false}
              isAllowed={(values) => values.floatValue <= maxValue}
              onValueChange={handleValueChange}
            />
          </Box>
          
          {/* Clear button */}
          <Box sx={{ mt: 1 }}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={clearField}
            >
              Clear Field
            </Button>
          </Box>
        </Box>

        <Box sx={{ width: { xs: "100%", md: "35%" } }}>
          <Select
            id={`${item.title.toLowerCase().replace(/[ &/]/g, "-")}-amount-frequency`}
            onChange={(event) => {
              setItem({ ...item, frequency: event.target.value });
            }}
            fullWidth
            size={"small"}
            value={item.frequency}
            sx={{
              mt: { xs: "8px", md: "28px" },
              borderRadius: "0px",
              backgroundColor: "#FFF",
            }}
          >
            {Object.keys(frequencies).map((key, idx) => (
              <MenuItem key={idx} value={key}>
                {frequencies[key]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>

      {item.parent === "Additional Debt" && (
        <Box sx={{ width: { xs: "100%", md: "63.2%" }, borderRadius: "0px" }}>
          <Box paddingY={1}>
            <Typography
              id={`${item.title.toLowerCase().replace(/[ &/]/g, "-")}-balance-label`}
              fontWeight={"bold"}
              height={"20px"}
              htmlFor={"test"}
            >
              {`${item.title} balance`}
            </Typography>
          </Box>
          <Box>
            <NumericFormat
              id={`${item.title.toLowerCase().replace(/[ &/]/g, "-")}-balance`}
              sx={{ backgroundColor: "#FFF" }}
              customInput={TextField}
              fullWidth
              size='small'
              InputProps={{ style: { borderRadius: 0 } }}
              value={item.balance !== undefined ? item.balance : ''}
              decimalSeparator='.'
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=','
              prefix='£'
              allowNegative={false}
              isAllowed={(values) => values.floatValue <= maxValue}
              onValueChange={handleBalanceChange}
            />
          </Box>
        </Box>
      )}
    </>
  ) : null;
};

export default SfsInputRow;
