import { Container, Box, Stack, Typography, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";


import SettlementConfirmationInfoCard from "./SettlementConfirmationInfoCard";

import { useArrangement } from "../../../context/ArrangementProvider";
import { useSettlement } from "../../../context/SettlementProvider";

import ScrollToTop from "../../nav/ScrollToTop";

/** 
 * The settlement plan confirmation page
 * */
function SettlementConfirmation({ activeStep, steps }) {

  const {
    lastSavedPlan,
    setLastSavedPlan,
  } = useArrangement();

  const {
    selectedOption,
    settlementPlan,
    updateSettlementPlan,
  } = useSettlement();
  
  const navigate = useNavigate();
  const handleClick = () => {
    updateSettlementPlan({ ...settlementPlan, isSettlement: true });
    setLastSavedPlan({ ...lastSavedPlan, isSettlement: true });
    navigate(selectedOption === "1" ? "/payment" : "/");
  };

  return (
    <>
      <ScrollToTop />
      <Typography
        variant="h5"
        component="label"
        htmlFor="email"
        fontWeight={"bold"}
      >
        Settlement Plan
      </Typography>
      <Stack
        sx={{
          mt: 2,
          mb: 5,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box
          id='payment-plan-container'
          sx={{ width: "100%", textAlign: "center", pt: 2, pb: 4 }}
        >
          <CheckCircle color='success' sx={{ fontSize: 72, mb: 2 }} />

          <Typography fontWeight={"bold"}>Repayment plan confirmed</Typography>

          <Typography sx={{ py: 1, px: 1 }}>
            Thank you for setting up a settlement repayment plan.
          </Typography>

          <Typography sx={{ py: 1, px: 1 }}>
            We can confirm that your account is now on a settlement plan.
          </Typography>

          <Typography sx={{ py: 1, px: 1 }}>
            Once all agreed payments are completed, your credit file will be 
            updated to show this debt as "partially satisfied", indicating that
            a settlement was reached and the full balance was not paid.
          </Typography>

          <Container sx={{ py: 2 }}>
            {
              /* Stepper process complete so render home link */
              activeStep === steps.length - 1 && (
                <Button
                  id={selectedOption === "1" ? "make-payment-btn" : "return-home-btn"}
                  onClick={handleClick}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: 0,
                    width: { xs: "15em", lg: "20em" },
                    backgroundColor: "primary.dark",
                    color: "primary.contrastText",
                    borderColor: "primary.contrastText",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      color: "primary.dark",
                      borderColor: "primary.dark",
                    },
                  }}
                >
                  {selectedOption === "1" ? "Make a payment" : "Return home"}
                </Button>
              )
            }
          </Container>
        </Box>

        <Box sx={{ width: "100%" }}>
          <SettlementConfirmationInfoCard />
        </Box>
      </Stack>
    </>
  );
}

export default SettlementConfirmation;
