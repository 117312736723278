import { useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import DateInput from "../DateInput";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import useSWRMutation from "swr/mutation";

import { useClient } from "../../context/ClientProvider";
import { useUtils } from "../../context/UtilsProvider";
import { useAuth } from "../../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

export default function IdvLoginCard() {
  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();
  const {
    formState,
    setFormState,
    onChange,
    formError,
    setFormError,
    createAlert,
    alertSeverityTypes,
  } = useUtils();

  const { postIdvAuth, startSession } = useAuth();
  const navigate = useNavigate();
  const { trigger, isMutating } = useSWRMutation("/auth/idv", postIdvAuth);
  const [checked, setChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    try {
      const date = new Date(formState.dateOfBirth);
      if (isNaN(date)) {
        throw new Error("Invalid date");
      }
      const formattedDate = format(date, "yyyy-MM-dd");
      const response = await trigger({
        ...formState,
        dateOfBirth: formattedDate,
        postcode: formState.postcode.toUpperCase(),
      });
      console.info("post idv auth response: ", response);
      startSession(response.authenticationResult);
      createAlert("IDV authentication successful", alertSeverityTypes.success);
      navigate("/");
    } catch (error) {
      const message =
        error.message === "Invalid date"
          ? "Invalid date of birth. Please enter a valid date."
          : "Hmm... something's not right. Please try again";
      console.error("post idv auth error", error);
      setFormError(message);
      createAlert(message, alertSeverityTypes.error);
      setButtonDisabled(false);
    }
  };

  return (
    clientConfig && (
      <Card
        sx={{
          maxWidth: { md: "80%" },
          margin: "0px auto",
          borderRadius: "0",
          position: "relative",
          bottom: { xs: "auto", md: "300px" },
          boxShadow: "-9px 10px 4px -1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Container
          sx={{
            marginTop: "25px",
          }}
        >
          <Typography
            component='h1'
            variant='h5'
            fontWeight={"bold"}
            marginBottom={1}
          >
            Log in
          </Typography>
          <Typography component='h2' variant='p'>
            We've been busy improving your online account. Login to take a look
            around...
          </Typography>
        </Container>

        <Box
          component='form'
          onSubmit={handleSubmit}
          noValidate
          sx={{ margin: "0px" }}
        >
          <Stack
            sx={{
              paddingX: 1,
            }}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography component='label' htmlFor='email' fontWeight={"bold"}>
                {clientConfig?.name} reference number
              </Typography>
              <TextField
                margin='dense'
                required
                fullWidth
                id='accountReference'
                label={"Enter your " + clientConfig?.name + " reference number"}
                name='accountReference'
                autoFocus
                onChange={onChange}
              />
              <Typography
                component='label'
                htmlFor='password'
                fontWeight={"bold"}
                sx={{ mt: { xs: 4, md: 2 } }}
              >
                Postcode
              </Typography>
              <TextField
                margin='dense'
                required
                fullWidth
                name='postcode'
                label='Enter your postcode'
                id='postcode'
                value={formState.postcode || ""}
                onChange={(e) => {
                  const uppercasedValue = e.target.value.toUpperCase();
                  setFormState({ ...formState, postcode: uppercasedValue });
                }}
              />
            </CardContent>

            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold" }}
                component='label'
                htmlFor='dateOfBirth'
              >
                Date of birth
              </Typography>
              <DateInput
                value={formState.dateOfBirth}
                sx={{ display: "block", my: 1, width: "100%" }}
                onChange={(value) => {
                  setFormState({ ...formState, dateOfBirth: value });
                }}
              />
            </CardContent>
          </Stack>

          <Container
            sx={{
              marginBottom: "10px",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                marginRight: { xs: "8px", sm: 0 },
                marginBottom: { xs: "8px", sm: 0 },
              }}
            />
            <Typography
              component='h2'
              variant='p'
              sx={{
                display: "inline-block",
                verticalAlign: {
                  xs: "middle",
                  sm: "middle",
                  md: "baseline",
                  lg: "baseline",
                },
                maxWidth: { xs: "88%" },
                marginLeft: { xs: "0", sm: "8px" },
              }}
            >
              Tick this box to agree to confirm you've read & accepted our{" "}
              <Link
                to={clientConfig?.layout?.links?.terms}
                style={{ color: "#007CCB" }}
                target='_blank'
              >
                Terms and Conditions
              </Link>{" "}
              &{" "}
              <Link
                to={clientConfig?.layout?.links?.privacy}
                style={{ color: "#007CCB" }}
                target='_blank'
              >
                Privacy Notice
              </Link>
            </Typography>
          </Container>

          <Container
            sx={{
              position: "relative",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              type='submit'
              variant='contained'
              sx={{
                mb: 2,
                marginLeft: "1%",
                borderRadius: "0",
                width: { xs: "60%", md: "15%" },
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
              disabled={
                !(
                  checked &&
                  formState.accountReference &&
                  formState.dateOfBirth &&
                  formState.postcode &&
                  !buttonDisabled
                )
              }
            >
              Login
            </Button>
            {isMutating && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            <Typography
              sx={{ ml: 2, display: "inline-block", mb: { xs: 2 } }}
              color='error'
            >
              {formError && `* ${formError}`}
            </Typography>
          </Container>
        </Box>
      </Card>
    )
  );
}
