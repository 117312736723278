import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import ScrollToTop from "../nav/ScrollToTop";
import SfsCategoryButton from "./SfsCategoryButton";
import SfsSummary from "./SfsSummary";
import SfsInputRow from "./SfsInputRow";

import { useSfs } from "../../context/SfsProvider";

function SfsIncome({ handleNext, handleBack }) {
  const {
    sfsIncome,
    setSfsIncome,
    setSfsCategoryTotal,
    categories,
    setCategories,
    changeCategory,
    selectedCategory,
    setSelectedCategory,
    categoryMaxValues,
  } = useSfs();

  const setItem = (newItem) => {
    const newIncomeValues = sfsIncome.map((item) =>
      item.category === newItem.category && item.title === newItem.title
        ? newItem
        : item
    );
    setSfsIncome(newIncomeValues);
    setSfsCategoryTotal(newItem, newIncomeValues);
  };

  useEffect(() => {
    if (sfsIncome) {
      const uniqueCategoryList = sfsIncome
        .map((item) => item.category)
        .filter((value, index, self) => self.indexOf(value) === index);
      const uniqueCategories = uniqueCategoryList.map((item, idx) => ({
        name: item,
        selected: idx === 0 ? true : false,
      }));
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0].name);
    }
  }, []);

  return sfsIncome && categories ? (
    <>
      <ScrollToTop />
      <Typography fontSize={22} fontWeight={"bold"} paddingBottom={2}>
        Income
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={8}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box id='income-container' sx={{ width: { xs: "100%", md: "55%" } }}>
          {/* Left column */}
          {/* Sub category buttons */}
          <Box sx={{ pb: 2 }}>
            {/* Sub category buttons */}
            {categories?.map((category, idx) => {
              return (
                <SfsCategoryButton
                  key={idx}
                  category={category}
                  changeCategory={changeCategory}
                />
              );
            })}
          </Box>

          <Box>
            {/* Sub category Inputs */}
            {sfsIncome?.map((item, idx) =>
              item.category === selectedCategory ? (
                <SfsInputRow
                  key={idx}
                  item={item}
                  setItem={setItem}
                  maxValue={categoryMaxValues[item.category]}
                />
              ) : null
            )}
          </Box>
          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "45%" } }}>
          {/* Right column */}
          <SfsSummary id='monthly-income-sum' title='Monthly Income Summary' />

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 2, pb: 4 }}>
            <Button
              id='back-btn'
              onClick={handleBack}
              variant='outlined'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              Back
            </Button>

            <Button
              id='save-continue-btn'
              onClick={handleNext}
              variant='contained'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Next
            </Button>
          </Box>
          {/* End of right column */}
        </Box>
      </Stack>
    </>
  ) : null;
}

export default SfsIncome;
