import { 
  Card,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2


function AboutAccountWidget({ account }) {

  const NULL_VALUE = "Unspecified";

  return (
    <Grid>
      <Card
        id="my-account-container"
        sx={{
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
          borderRadius: 0
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          paddingTop={1.2}
          marginLeft={2}
        >
          My account
        </Typography>
        <TableContainer sx={{ px: 1, pb: 2 }}>
          <Table
            sx={{
              minWidth: "100%",
            }}
            size="small"
            aria-label="about account table"
          >
            <TableBody>
              <TableRow
                key="accountNumber"
                sx={{ "td, th": { border: 0 }, "th": { color:"#FFF" }, "td": { color: "#FFF" } }}
              >
                <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">Account number:</TableCell>
                <TableCell id="account-num" align="right">{account?.accountReference || NULL_VALUE}</TableCell>
              </TableRow>
              <TableRow
                key="originalCreditor"
                sx={{ "td, th": { border: 0 }, "th": { color:"#FFF" }, "td": { color: "#FFF" } }}
              >
                <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">Original creditor:</TableCell>
                <TableCell id="original-creditor" align="right">{account?.creditorName || NULL_VALUE}</TableCell>
              </TableRow>
              <TableRow
                key="creditorRef"
                sx={{ "td, th": { border: 0 }, "th": { color:"#FFF" }, "td": { color: "#FFF" } }}
              >
                <TableCell sx={{ fontWeight: "bold" }} component="th" scope="row">Creditor reference number:</TableCell>
                <TableCell id="cred-ref-num" align="right">{account?.creditorReference || NULL_VALUE}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Grid>
  )
}

export default AboutAccountWidget;