import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ActionButton({
  id,
  buttonColor,
  buttonTextColor,
  buttonTitle,
  outlined = false,
  hoverColor,
  hoverTextColor,
  target = "/",
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (target.startsWith("tel:")) {
      window.location.href = target;
    } else {
      navigate(target);
    }
  };

  return (
    <Button
      id={id}
      onClick={handleClick}
      variant={outlined ? "outlined" : "contained"}
      sx={{
        textTransform: "none",
        borderRadius: 0,
        width: { xs: "15em", lg: "20em" },
        backgroundColor: outlined ? "transparent" : buttonColor,
        color: buttonTextColor,
        borderColor: buttonTextColor,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          backgroundColor: hoverColor,
          color: hoverTextColor,
          borderColor: hoverTextColor,
        },
        ...(outlined && {
          border: `1px solid ${buttonTextColor}`,
        }),
      }}
    >
      {buttonTitle}
    </Button>
  );
}

export default ActionButton;
