import { useState, useEffect } from "react";
import { Box, Typography, Select, MenuItem, Checkbox } from "@mui/material";

import { usePayment } from "../../../context/PaymentProvider";


function SelectPaymentCard({ selectedCard, setSelectedCard, saveNewCard, setSaveNewCard }) {

  const { paymentTokens, maxTokens } = usePayment();

  const [ hideCardSelect, setHideCardSelect ] = useState(false);
  const [ hideSaveCardCheckbox, setHideSaveCardCheckbox ] = useState(false);

  useEffect(() => {
    // Select first payment card by default
    if (paymentTokens.length > 0) {
      setSelectedCard(paymentTokens[0]);
      if (paymentTokens.length === maxTokens) {
        // Dont offer save card option if maximum saved cards reached
        setHideSaveCardCheckbox(true);
      };
    };
  }, []);

  return paymentTokens.length > 0 && selectedCard && (
    <>
      <Box sx={{ pt: 3, pb: 1, width: "100%" }}>
        <Typography fontSize={16} fontWeight='bold'>
          Which card would you like to pay with?
        </Typography>
      </Box>

      {!hideCardSelect &&
        <Box sx={{ width: { xs: "100%", md: "49%" } }}>
          <Select
            id='payment-cards-select'
            fullWidth
            displayEmpty
            value={selectedCard}
            onChange={(event) => setSelectedCard(event.target.value)}
            size='small'
            renderValue={(selected) => {
              return `${selected.card.brand} ${selected.card.mask}`}
            }
          >
            {paymentTokens.map((token) => (
              <MenuItem
                key={token.paymentTokenId}
                id={token.paymentTokenId}
                value={token}
              >
                {`${token.card.brand} ${token.card.mask}`}
              </MenuItem>
            ))}
          </Select>
        </Box>      
      }

      {/* Save new card confirmation */}
      {!hideSaveCardCheckbox &&
        <Box
          id='save-new-card-declaration'
          sx={{ ml: -1, display: "flex" }}
        >
          <Box sx={{ display: "flex" }}>
            <Checkbox
              id='save-new-card-declaration-checkbox'
              checked={saveNewCard}
              onChange={() => {
                setHideCardSelect(!hideCardSelect);
                setSaveNewCard(!saveNewCard);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box id='save-new-card-declaration-statement' padding={0.5}>
            <Typography
              sx={{ display: "inline-block", mt: 1, fontSize: 14 }}
            >
              Use a new card (with the option to save your card details).
            </Typography>
          </Box>
        </Box>
      }
      {/* End of save new card confirmation */}
    </>
  );
};

export default SelectPaymentCard;