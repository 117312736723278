import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Box, Typography, Stack  } from "@mui/material";

import ChangePaymentMethodSelect from "./ChangePaymentMethodSelect";
import ArrangementCardForm from "./ArrangementCardForm";
import ArrangementDirectDebitForm from "./ArrangementDirectDebitForm";
import ArrangementStandingOrder from "./ArrangementStandingOrder";
import ArrangementBankTransfer from "./ArrangementBankTransfer";
import ArrangementCheque from "./ArrangementCheque";
import ArrangementPostalOrder from "./ArrangementPostalOrder";

import { useArrangement } from "../../context/ArrangementProvider";


function ChangePaymentMethod({ account, handleComplete }) {

  const {
    paymentMethods,
    calculateStartDate,
    calculateEndDate,
    plan,
    setPlan,
    savePlanData,
  } = useArrangement();

  const [ consent, setConsent ] = useState(false);
  const [ selectedMethod, setSelectedMethod ] = useState(null);
  const [ currentSortCode, setCurrentSortCode ] = useState(null);
  const [ currentAccountNumber, setCurrentAccountNumber ] = useState(null);
  const [ walletId, setWalletId ] = useState(null);  

  const [ errorMessage, setErrorMessage ] = useState(null);

  const SORT_CODE_LENGTH = 6;
  const ACCOUNT_NUMBER_LENGTH = 8;

  useEffect(() => {
    // Set default selected method to existing method if supported, otherwise direct debit
    let paymentMethod = plan?.paymentMethod ? parseInt(plan?.paymentMethod) : 11;
    setSelectedMethod(paymentMethod);
    setConsent(false); // Reset consent on page load
    // Hold the existing wallet ID
    setWalletId(plan?.walletId);
  }, []);

  const savePaymentMethod = () => {
    // Triggered when Continue is pressed in step 1
    // Validate sort code and account number
    if (selectedMethod === paymentMethods["UK DIRECT DEBIT"]) {
      if (currentSortCode === null || currentSortCode.length < SORT_CODE_LENGTH) {
        setErrorMessage(`Sort code must contain ${SORT_CODE_LENGTH} numbers.`);
        return;
      } else if (currentAccountNumber === null || currentAccountNumber.length < ACCOUNT_NUMBER_LENGTH) {
        setErrorMessage(`Account number must contain ${ACCOUNT_NUMBER_LENGTH} numbers.`);
        return;
      };
    };

    // Update bank account details before saving
    const newPlan = {
      ...plan,
      sortCode: currentSortCode,
      accountNumber: currentAccountNumber,
      walletId: selectedMethod === paymentMethods["UK DIRECT DEBIT"] && walletId ? walletId : null,
    }

    setPlan(newPlan);
    savePlanData(newPlan);

    handleComplete();
  };

  useEffect(() => {
    // Adjust plan based on selected payment method
    // Get new start date
    if (selectedMethod) {
      const newStartDate = calculateStartDate(
        selectedMethod,
        plan?.paymentFrequency,
        plan?.monthlyDay,
        plan?.weeklyDay
      );
      // Get new end date
      const newEndDate = calculateEndDate(
        newStartDate,
        plan?.paymentFrequency,
        plan?.numberOfInstalments,
      );
      // Adjust plan
      setPlan({
        ...plan,
        dateCreated: newStartDate,
        nextPaymentDate: newStartDate,
        endDate: newEndDate,
        paymentMethod: selectedMethod,
      });
    }
  }, [selectedMethod]);

  return (
    <>
      <Typography fontWeight={"bold"}>
        Change your payment method
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Left column */}
          <ChangePaymentMethodSelect
            account={account}
            consent={consent}
            setConsent={setConsent}
            setErrorMessage={setErrorMessage}
            setCurrentSortCode={setCurrentSortCode}
            setCurrentAccountNumber={setCurrentAccountNumber}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            savePaymentMethod={savePaymentMethod}
          />
          {/* End of left column */}
        </Box>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Right column */}
          {selectedMethod === 5 && <ArrangementCardForm
            consent={consent} setConsent={setConsent}
          />}
          {selectedMethod === 11 && <ArrangementDirectDebitForm
            consent={consent}
            setConsent={setConsent}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            currentSortCode={currentSortCode}
            setCurrentSortCode={setCurrentSortCode}
            currentAccountNumber={currentAccountNumber}
            setCurrentAccountNumber={setCurrentAccountNumber}
          />}
          {selectedMethod === 12 && <ArrangementStandingOrder
            account={account} setConsent={setConsent}
          />}
          {selectedMethod === 14 && <ArrangementBankTransfer
            account={account} setConsent={setConsent}
          />}
          {selectedMethod === 15 && <ArrangementCheque
            account={account} setConsent={setConsent}
          />}
          {selectedMethod === 31 && <ArrangementPostalOrder
            account={account} setConsent={setConsent}
          />}
          {/* End of right column */}
        </Box>
      </Stack>
    </>
  )
};

ChangePaymentMethod.propTypes = {
  account: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired,
};

export default ChangePaymentMethod;