import PropTypes from "prop-types";
import { useMemo } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";

import { useUtils } from "../../context/UtilsProvider";
import { useClient } from "../../context/ClientProvider";

import environment from "../../env";


function ArrangementCheque({ account, setConsent }) {

  const { capitaliseWords } = useUtils();
  const { clientAddress } = useClient();

  useMemo(() => {
    // Enable confirm button by default
    setConsent(true);
  }, []);

  return account && (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 2,
      }}
    >
      <Box>
        {/* Cheque payment details */}
        <CardContent>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
            Paying by cheque
          </Typography>
          <Typography>
            {`Please make your cheque payable to `}
            <Typography
              variant='span'
              color='black'
              fontWeight='bold'
            >
              {clientAddress[0]}
            </Typography>          
            {` ensuring that you write your ${capitaliseWords(environment?.CLIENT_NAME)} reference number `}
            <Typography
              variant='span'
              color='black'
              fontWeight='bold'
            >
              {account?.accountReference}
            </Typography>
            {` clearly on the reverse of your cheque and send it to us at the following address:`}
          </Typography>
          <Box
            id="address"
            sx={{ my: 2 }}            
          >
            {clientAddress.map((line, idx) => (
              <Typography key={idx}>
                {line}
              </Typography>
            ))}
          </Box>
        </CardContent>
        {/* End of cheque payment details */}
      </Box>
    </Card>
  );
};

ArrangementCheque.propTypes = {
  account: PropTypes.object.isRequired,
  setConsent: PropTypes.func.isRequired,
};

export default ArrangementCheque;