import React from 'react';
import { Box, List, ListItem, ListItemText, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from 'prop-types';

const UpdateContactInfoPage = ({ handleOpenUpdateMobileNumber, handleOpenUpdateHomeNumber, handleOpenUpdateEmail, handleBackToMain, email, mobileNumber, homeNumber }) => (
  <Box
  sx={{
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    height: "100%",
  }}
>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      mb: 2, 
      p: 1, 
    }}
  >
    <IconButton onClick={handleBackToMain} sx={{ mr: 1 }}>
      <ArrowBackIcon sx= {{color: "primary.dark"}} />
    </IconButton>
    <Typography variant="body1" fontWeight="bold">Update Contact Information</Typography>
  </Box>

  <List
    sx={{
      width: "100%",
      maxWidth: "350px",
      mx: "auto",
    }}
  >
    <ListItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        mb: 1,
        borderRadius: 1 
      }}
    >
      <ListItemText
        primary={
          <Typography variant="body2">
            Mobile Number
          </Typography>
        }
        secondary={
          <Typography variant="body1" fontWeight="bold">
            {mobileNumber}
          </Typography>
        } 
      />
      <Button
        variant="text"
        color="primary"
        onClick={handleOpenUpdateMobileNumber} 
      >
        Edit
      </Button>
    </ListItem>

    <ListItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        mb: 1,
        borderRadius: 1 
      }}
    >
      <ListItemText
        primary={
          <Typography variant="body2">
            Home Number
          </Typography>
        }
        secondary={
          <Typography variant="body1" fontWeight="bold">
            {homeNumber}
          </Typography>
        } 
      />
      <Button
        variant="text"
        color="primary"
        onClick={handleOpenUpdateHomeNumber} 
      >
        Edit
      </Button>
    </ListItem>

    <ListItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 1 
      }}
    >
      <ListItemText
        primary={
          <Typography variant="body2">
            Email Address
          </Typography>
        }
        secondary={
          <Typography variant="body1" fontWeight="bold">
            {email}
          </Typography>
        } 
      />
      <Button
        variant="text"
        color="primary"
        onClick={handleOpenUpdateEmail} 
      >
        Edit
      </Button>
    </ListItem>
  </List>
</Box>
);

UpdateContactInfoPage.propTypes = {
  handleOpenUpdateMobileNumber: PropTypes.func.isRequired,
  handleOpenUpdateHomeNumber: PropTypes.func.isRequired,
  handleOpenUpdateEmail: PropTypes.func.isRequired,
  handleBackToMain: PropTypes.func.isRequired,
  email: PropTypes.string,
  mobileNumber: PropTypes.string,
  homeNumber: PropTypes.string
};

export default UpdateContactInfoPage