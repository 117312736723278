// components/payment/PaymentFrame.js
import { useEffect } from "react";
import { Box, Stack, Typography, CircularProgress, Card, LinearProgress } from "@mui/material";

import PaymentInformation from "./PaymentInformation";
import ScrollToTop from "../nav/ScrollToTop";
import { usePayment } from "../../context/PaymentProvider";

import environment from "../../env";

/**
 * Payment frame component
 * @param {*} param0 
 * @returns 
 */
function PaymentFrame({ handleNext, paymentRequest }) {

  const { usePostPayment, paymentId, setPaymentId, setProviderResponse } = usePayment();

  const { payment, isLoading } = usePostPayment(paymentRequest);

  const flowCallbackFunction = (result) => {
    // Runs when initial iframe is loaded before payment submit
    console.log("FLOWBACK: ", result);
    alert(result.page);
  };

  const resultCallbackFunction = (response) => {
    // Runs after payment submitted
    console.log("Card payment worldpay response: ", response);
    setProviderResponse(response);
    handleNext();
  };

  useEffect(() => {
    if (payment?.url && payment?.paymentId) {
      setPaymentId(payment?.paymentId);
      //let callbackUrl = `${environment.CUSTOMER_PORTAL_URL}/payment/${payment?.paymentId}`;
      let customOptions = {
        url: payment?.url,
        type: 'iframe',
        inject: 'immediate',
        target: 'payment-frame',
        accessibility: true,
        debug: false,
        language: 'en',
        country: 'gb',
        //preferredPaymentMethod: 'VISA-SSL',
        //successURL: callbackUrl,
        //cancelURL: callbackUrl,
        //failureURL: callbackUrl,
        //pendingURL: callbackUrl,
        //errorURL: callbackUrl,
        resultCallback: resultCallbackFunction,
        //flowCallback: flowCallbackFunction,
        customisation: {
          "border": "none",
        }
      };
    
      console.log("Card payment iframe options: ", customOptions);
      console.log("Payment hub payment response: ", payment);
    
      let libraryObject = new window.WPCL.Library();
      libraryObject.setup(customOptions);      
    }
  }, [payment]);
 
  if (isLoading) {
    return <CircularProgress/>
  }

  return payment ? (
    <>
      <ScrollToTop />
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        {/* Payment iframe */}
        <Box sx={{ pb: 2, width: { xs: "100%", md: "70%" } }}>
          <Typography fontWeight='bold' textAlign='left' paddingBottom={1}>
            Make a payment
          </Typography>
          <Card fullWidth>
            <Box sx={{ height: 540}}>
              {isLoading && <CircularProgress />}
              <Box id="payment-frame"></Box>
            </Box>
          </Card>
        </Box>
        {/* End of payment iframe */}

        {/* Payment information */}
        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          <Typography fontWeight='bold' textAlign='left' paddingBottom={2}>
            Payment information
          </Typography>
          <PaymentInformation />
        </Box>
        {/* End of payment information */}
      </Stack>
    </>
  ) : null;
}

export default PaymentFrame;
