import { Box, Stack, Typography, Card, Container } from "@mui/material";
import { useArrangement } from "../../context/ArrangementProvider";
import { useAccount } from "../../context/AccountProvider";
import { useUtils } from "../../context/UtilsProvider";
import dayjs from "dayjs";

 /** 
  * Arrangement detail component
  * */
function ArrangementDetails({ setConsent }) {
  const { capitaliseWords } = useUtils();

  const { useGetAccount } = useAccount();
  const { useGetArrangement, plan, paymentMethods, paymentFrequencies } = useArrangement();

  const { account } = useGetAccount();
  const { arrangement } = useGetArrangement();

  // No declaration so set consent to true to enable button
  setConsent(true);

  return (
    <Card
      sx={{
        borderRadius: 5,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box sx={{ pb: 1, borderBottom: "1px solid lightgrey" }}>
        <Container sx={{ width: "100%" }}>
          <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
            {account?.creditorName}
          </Typography>
          <Typography fontSize={13}>Reference number:</Typography>
          <Typography id='reference-num' fontSize={16} fontWeight={"bold"}>
             {account?.accountReference}
          </Typography>
        </Container>
      </Box>
      {/* End of header section */}

      <Box sx={{ p: 2 }}>
        <Stack
          sx={{
            paddingX: 1,
          }}
          spacing={2}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          {/* Arrangement details left column */}
          <Box
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography component='label' htmlFor='amount' fontSize={13}>
              New payment amount
            </Typography>
            <Box
              id='amount'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >{`£${plan?.instalmentAmount?.toFixed(2)}`}</Box>

            <Typography
              component='label'
              htmlFor='end-date'
              fontSize={13}
              paddingTop={2}
            >
              New estimated end date
            </Typography>
            <Box
              id='end-date'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >{plan?.endDate?.format("DD/MM/YYYY")}</Box>

            <Typography
              component='label'
              htmlFor='frequency'
              fontSize={13}
              paddingTop={2}
            >
              New payment frequency
            </Typography>
            <Box
              id='frequency'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {capitaliseWords(paymentFrequencies[plan?.paymentFrequency])}
            </Box>
          </Box>
          {/* End of arrangement details left column */}

          {/* Arrangement details right column */}
          <Box
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography component='label' htmlFor='start-date' fontSize={13}>
              New plan start date
            </Typography>
            <Box
              id='start-date'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >{plan?.dateCreated?.format("DD/MM/YYYY")}</Box>

            <Typography
              component='label'
              htmlFor='method'
              fontSize={13}
              paddingTop={2}
            >
              New payment method
            </Typography>
            <Box
              id='method'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {capitaliseWords(plan?.paymentMethod ? Object.keys(paymentMethods).find(key => paymentMethods[key] === plan.paymentMethod) : "")}
            </Box>

            <Typography
              component='label'
              htmlFor='payment-day'
              fontSize={13}
              paddingTop={2}
            >
              New pay on day
            </Typography>
            <Box
              id='payment-day'
              sx={{
                backgroundColor: "#E2E2E2",
                height: "34px",
                borderRadius: 0,
                border: "1px solid #BBBBBB",
                px: 1,
                py: 0.5,
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {plan?.paymentFrequency === "monthly" ? plan?.monthlyDay : plan?.weeklyDay}
            </Box>

          </Box>
          {/* End of arrangement details right column */}
        </Stack>
      </Box>
    </Card>
  );
}

export default ArrangementDetails;
