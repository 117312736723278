import {
  Box,
  Stack,
  Typography,
  TextField,
  Card,
  CardContent,
  Checkbox,
} from "@mui/material";
import { PatternFormat } from "react-number-format";

import { useArrangement } from "../../context/ArrangementProvider";

const handleSubmit = (event) => {
  event.preventDefault();
};

function ArrangementDirectDebitForm({
    consent,
    setConsent,
    errorMessage,
    setErrorMessage,
    currentSortCode,
    setCurrentSortCode,
    currentAccountNumber,
    setCurrentAccountNumber,
  }) {
  /* The create repayment DD method form component */

  const { lastSavedPlan } = useArrangement();

  const handleSortCodeChange = (event) => {
    setErrorMessage(null);
    if (event.target.value.length <= 6) {
      setCurrentSortCode(event.target.value.trim());
    }
  };

  const handleAccountNumberChange = (event) => {
    setErrorMessage(null);
    if (event.target.value.length <= 8) {
      setCurrentAccountNumber(event.target.value.trim());
    }
  };

  return lastSavedPlan && (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 2,
      }}
    >
      <Box component='form' onSubmit={handleSubmit} noValidate>
        <Stack
          sx={{
            paddingX: 1,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          {/* Direct debit form existing mandate left column */}
          {(lastSavedPlan?.sortCode && lastSavedPlan?.accountNumber) &&
          <CardContent
            sx={{
              textAlign: "left",
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
              Current details
            </Typography>
            <Typography
              component='label'
              htmlFor='sortCode'
              fontWeight={"bold"}
            >
              Sort code
            </Typography>
            <TextField
              disabled
              margin='dense'
              required
              id='sortCode'
              name='sortCode'
              value={lastSavedPlan?.sortCode}
              sx={{ backgroundColor: "lightgrey" }}
            />

            <Typography
              component='label'
              htmlFor='accountNumber'
              fontWeight={"bold"}
              sx={{ mt: 1 }}
            >
              Account number
            </Typography>
            <TextField
              disabled
              margin='dense'
              required
              id='accountNumber'
              name='accountNumber'
              value={lastSavedPlan?.accountNumber}
              sx={{ mb: 1, backgroundColor: "lightgrey" }}
            />
          </CardContent>
          }
          {/* End of direct debit form existing mandate left column */}

          {/* Direct debit form right column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
              Enter new details
            </Typography>
            <Typography
              component='label'
              htmlFor='sortCode'
              fontWeight={"bold"}
            >
              Sort code
            </Typography>
            <PatternFormat
              id='sortCode'
              customInput={TextField}
              name='sortCode'
              margin='dense'
              required
              format="######"
              value={currentSortCode}
              valueIsNumericString={true}
              onChange={handleSortCodeChange}
              InputProps={{
                style: {color: "darkgrey", borderRadius: 0, pb: 1, mb: 1 },
              }}
              placeholder='Enter your 6 digit sort code'
            />

            <Typography
              component='label'
              htmlFor='accountNumber'
              fontWeight={"bold"}
              sx={{ mt: 1 }}
            >
              Account number
            </Typography>
            <PatternFormat
              id='accountNumber'
              name='accountNumber'
              customInput={TextField}
              margin='dense'
              required
              format="########"
              value={currentAccountNumber}
              valueIsNumericString={true}
              onChange={handleAccountNumberChange}
              InputProps={{
                style: {color: "darkgrey", borderRadius: 0, pb: 1, mb: 1 },
              }}
              placeholder='Enter your 8 digit account number'
            />
          </CardContent>
          {/* End of direct debit form right column */}
        </Stack>

        {/* Direct debit details error display */}
        <Box sx={{ mx: 3 }}>
          <Typography sx={{ color: "red" }}>
            {errorMessage && `* ${errorMessage}`}
          </Typography>
        </Box>

        {/* Payment method confirmation */}
        <Box
          id='declaration'
          sx={{ mx: 2, display: "flex", flexDirection: "row" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              id='declaration-checkbox'
              checked={consent}
              onChange={() => {
                setConsent(!consent);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box id='declaration-statement' padding={0.5} marginRight={2}>
            <Typography
              component='h2'
              variant='p'
              sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
            >
              I confirm that I am the account holder and have the authority to
              set up Direct Debit payments on this account. For further details, please refer to the Direct Debit Guarantee.
            </Typography>
          </Box>
        </Box>
        {/* End of payment method confirmation */}
      </Box>
    </Card>
  );
}

export default ArrangementDirectDebitForm;