import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

import { usePayment } from "../../../context/PaymentProvider";

function DeletePaymentCardDialog() {

  const {
    removePaymentToken,
    showDeleteCardDialog,
    toggleDeleteCardDialog,
    selectedPaymentCard,
    selectPaymentCard,
  } = usePayment();

  const handleCancelDialog = () => {
    selectPaymentCard(null);
    toggleDeleteCardDialog();
  };

  const handleConfirmRemove = () => {
    if (selectedPaymentCard) {
      removePaymentToken(selectedPaymentCard.paymentTokenId);
      console.log("Payment card removed with ID:", selectedPaymentCard.paymentTokenId);
      selectPaymentCard(null);
      toggleDeleteCardDialog();
    };
  };

  return (
    <Dialog
      open={showDeleteCardDialog}
      onClose={toggleDeleteCardDialog}
      sx={{ textAlign: "center", padding: "20px" }}
    >
      <PriorityHighOutlinedIcon
        sx={{
            width: "55px",
            height: "55px",
            mt: "20px",
            mx: "auto",
            borderColor: 'primary.main',
            borderWidth: "5px",
            borderRadius: "50%",
            display: "block",
        }}
      />

      <DialogTitle sx={{ fontSize: "30px", fontWeight: "600", textAlign: "center" }}>
        Remove Card
      </DialogTitle>

      <DialogContent sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
        <DialogContentText sx={{ color: "#000" }}>
          Are you sure you want to permanently remove your saved card from your account? Once deleted, we will no longer store any information related to this card.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
        <Button
          onClick={handleConfirmRemove}
          variant="contained"
          sx={{
            backgroundColor: 'primary.dark',
            borderRadius: '0px',
            mr: "15px"
          }}
        >
          Remove
        </Button>
        <Button
          onClick={handleCancelDialog}
          variant="outlined"
          sx={{
            color: 'primary.dark',
            borderColor: 'primary.dark',
            borderRadius: '0px'
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePaymentCardDialog;