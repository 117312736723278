import React from 'react';
import { Box, Card, Typography, List, ListItem, ListItemText, Button, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';

function getInitials(fullName) {
    if(!fullName){
        return;
    }
    const names = fullName.split(' '); 
    const initials = names.map(name => name.charAt(0).toUpperCase()); 
    return initials.join(''); 
  }

const MainPage = ({ party, account, handleOpenSettings, handleOpenUpdateContactInfo, handleSignOut, clientConfig }) => (
  <>
  <Card
        sx={{
          width: "100%",
          maxWidth: "100%",
          p: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            width: 64,
            height: 64,
            backgroundColor: "primary.dark",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {getInitials(party?.customerName.first + " " + party?.customerName.last)}
          </Typography>
        </Box>
        <Typography variant="h6">{party?.customerName.first + " " + party?.customerName.last}</Typography>
        <Typography variant="body2">
          Account number: {account?.accountReference}
        </Typography>
      </Card>

      <List
        sx={{
          width: "100%",
          maxWidth: "350px",
          mx: "auto",
        }}
      >
        <ListItem button onClick={handleOpenUpdateContactInfo} sx={{ backgroundColor: "white", mb: 1, borderRadius: 1 }}>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                <AppSettingsAltOutlinedIcon sx={{color: "primary.dark", fontSize: "1rem", mr:1}}/>
                Update Contact Information
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.secondary" sx={{ml:3}}>
                You can update your details here.
              </Typography>
            }
          />
          <ChevronRightIcon sx={{ color: "primary.dark" }} />
        </ListItem>
        <ListItem button onClick={handleOpenSettings} color="action" sx={{ backgroundColor: "white", mb: 1, borderRadius: 1 }}>
          <ListItemText  
            primary={
              <Typography variant="body1" fontWeight="bold">
                <SettingsIcon sx={{color: "primary.dark", fontSize: "1rem", mr:1}}/>
                Settings
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.secondary" sx={{ml:3}}>
                Update your preferences here.
              </Typography>
            }
          />
          <ChevronRightIcon sx={{ color: "primary.dark" }} />
        </ListItem>
        <Divider />
        <ListItem button >
          <Button
            onClick={handleSignOut}
            fullWidth
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: clientConfig?.theme.primary,
              "&:hover": {
                backgroundColor: clientConfig?.theme.primary,
              },
            }}
          >
            Log out
          </Button>
        </ListItem>
      </List>
    </>
);

MainPage.propTypes = {
  party: PropTypes.shape({
    customerName: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
    }),
  }).isRequired,
  account: PropTypes.shape({
    accountReference: PropTypes.string,
  }).isRequired,
  handleOpenSettings: PropTypes.func.isRequired,
  handleOpenUpdateContactInfo: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  clientConfig: PropTypes.shape({
    theme: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MainPage