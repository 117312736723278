import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import MonetaryText from "../MonetaryText";
import ScrollToTop from "../nav/ScrollToTop";
import { useSfs } from "../../context/SfsProvider";

/**
 * SFS summarry totals component
 * @param {*} param0 
 * @returns 
 */
const SfsSummaryTotals = ({ title }) => {

  const { sfsTotals, disposableIncome } = useSfs();

  return sfsTotals ? (
    <><ScrollToTop />
    <Card sx={{ mb: 2, borderRadius: "0px" }}>
      <CardHeader title={title} />
      <CardContent>
        <TableContainer>
          <Table aria-label='totals summary table'>
            <TableBody>
              {sfsTotals.totals.map((item, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell sx={{ fontSize: 16, fontWeight: "bold" }}>My {item.name}</TableCell>
                    <TableCell sx={{ fontSize: 16, fontWeight: "bold", textAlign: "right" }}>
                      <MonetaryText value={item.value} />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell sx={{ fontSize: 20, fontWeight: "bold", color: "primary.dark" }}>My Disposable Income</TableCell>
                <TableCell
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "right",
                    color: disposableIncome > 0 ? "green" : "purple",
                  }}
                >
                  {`${disposableIncome > 0 ? "+ " : ""}£${disposableIncome.toFixed(2)}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      </Card>
    </>
  ) : null;
};

export default SfsSummaryTotals;
