import jsPDF from 'jspdf';
import 'jspdf-autotable';

const formatDateForFilename = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};

const getBase64ImageFromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
};

export const generateTransactionPdf = async (transactions, logoUrl, party, account) => {
    const doc = new jsPDF();
    const logo = await getBase64ImageFromUrl(logoUrl);
    const logoWidth = 40;
    const logoHeight = (logoWidth / 200) * 40;

    doc.setFillColor(199, 228, 226);
    doc.setDrawColor(199, 228, 226);
    doc.rect(0, 0, 250, 20, 'F');

    doc.addImage(logo, 'PNG', 10, 5, logoWidth, logoHeight);

    doc.setFontSize(12);
    doc.text('This document is provided for reference and informational purposes only.', 10, 30);
    doc.text('It is not intended to serve as an official regulatory statement.', 10, 35);

    let formattedFromDate = '';
    let formattedToDate = '';
    let customerInfoHeight = 0;

    if (transactions.length > 0 && party?.customerName) {
        const fullName = `${party.customerName.first} ${party.customerName.last}`;
        const accountRef = account.accountReference || 'N/A';

        const transactionDates = transactions.map(transaction => new Date(transaction.datePaid));
        const fromDate = new Date(Math.min(...transactionDates));
        const toDate = new Date(Math.max(...transactionDates));

        formattedFromDate = formatDateForFilename(fromDate);
        formattedToDate = formatDateForFilename(toDate);

        const customerInfo = [
            ['Customer Name:', fullName],
            ['Account Ref:', accountRef],
            ['From Date:', formattedFromDate],
            ['To Date:', formattedToDate],
        ];

        const customerInfoStartY = 50;
        customerInfo.forEach((row, index) => {
            const y = customerInfoStartY + index * 10;
            const fillColor = index % 2 === 0 ? [255, 255, 255] : [240, 240, 240];
            doc.setFillColor(...fillColor);
            doc.rect(10, y, 190, 10, 'F');
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(row[0], 12, y + 7);
            doc.text(row[1], 90, y + 7);
        });

        customerInfoHeight = customerInfoStartY + customerInfo.length * 10;
    }

    const transactionTableStartY = customerInfoHeight + 20;
    doc.autoTable({
        head: [['Date', 'Description', 'Amount']],
        body: transactions.map((transaction) => [
            formatDateForFilename(transaction.datePaid),
            String(transaction.payMethod),
            `£${Number(transaction.totalPaid).toFixed(2)}`,
        ]),
        startY: transactionTableStartY,
        theme: 'grid',
        headStyles: {
            fillColor: [0, 89, 105],
            textColor: [255, 255, 255],
            fontSize: 12,
            halign: 'center',
        },
        styles: {
            cellPadding: 5,
            fontSize: 10,
        },
    });

    const totalPages = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${totalPages}`, 10, doc.autoTable.previous.finalY + 10);

    const filename = `transactions_${formattedFromDate}_to_${formattedToDate}.pdf`;
    doc.save(filename);
};
