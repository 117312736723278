import React from "react";
import { useClient } from "../../context/ClientProvider";
import Container from "@mui/material/Container";

/**
 * Login banner
 * @returns
 */
export default function LoginBanner() {
  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();
  const bannerContainer = {
    backgroundSize: "cover",
    backgroundPosition: "center right",
    width: "100vw",
    maxWidth: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "11%",
  };
  return (
    <Container
      disableGutters
      style={bannerContainer}
      sx={{
        backgroundImage: {
          md: `url(${clientConfig?.layout.auth.banner})`,
          xs: `url(${clientConfig?.layout.auth.banner})`,
        },
        height: 350,
        backgroundSize: "cover",
        backgroundPosition: "center right",
        backgroundRepeat: "no-repeat",
      }}
    ></Container>
  );
}
