import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Button, Radio, Grid, FormControl, RadioGroup, Paper } from "@mui/material";
import DiscountIcon from '@mui/icons-material/Discount';

import MonetaryText from "../../MonetaryText";

import { useClient } from "../../../context/ClientProvider";
import { useSettlement } from "../../../context/SettlementProvider";


function Settlement({ balance }) {

  const navigate = useNavigate();

  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();

  const {
    useGetSettlementOffer,
    settlementOptions,
    changeSelectedSettlementOption,
    selectedOption,
    settlementOffer,
    createSettlementOffer,
    settlementDiscount,
    createSettlementDiscount,
    settlementSaving,
  } = useSettlement();

  const { settlement, isSettlementLoading } = useGetSettlementOffer();

  // Local palette for gradient shading
  const [ localPalette, setLocalPalette ] = useState(null); // Used for applying gradient

  const confirmSettlement = (event) => {
    navigate(`/settlement/${selectedOption}`);
  };

  const handleChange = (event) => {
    const newOption = event.target.value;
    changeSelectedSettlementOption(newOption);
  };

  useEffect(() => {
    if (settlement && settlement?.netPresentValue) {
      const newSettlementOffer = settlement?.netPresentValue;
      createSettlementOffer(newSettlementOffer);
      createSettlementDiscount(balance, newSettlementOffer);
    }
  }, [settlement]);

  useEffect(() => {
    if (clientConfig && clientConfig?.theme?.palette) {
      setLocalPalette(clientConfig.theme.palette);
    }
  }, [clientConfig]);

  return (
    localPalette &&
    !isSettlementLoading &&
    settlement &&
    (settlementOffer && settlementOffer > 0) &&
    (settlementDiscount && settlementDiscount > 0) &&
    settlementOptions) && (
    <Box
      id="settlement-container"
      sx={{
        background: `linear-gradient(to right bottom, ${localPalette.primary.main}, ${localPalette.secondary.main})`,
        color: "primary.contrastText",
        borderRadius: 0,
        m: 1,
      }}
    >
      <Box sx={{
        m: 1,
        p: 1.5,
        backgroundColor: "primary.dark", 
      }}>
        <Box id="settlement-header" sx={{ my: 0.75 }}>
          
          <Box sx={{ width: "80%", display: "inline-block" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 24,
                lineHeight: 0,
              }}
            >
              Settlement offer
            </Typography>
          </Box>
          <Box sx={{ width: "20%", textAlign: "right", display: "inline-block" }}>
            <DiscountIcon />
          </Box>
        </Box>

        <Box id="settlement-saving" sx={{ textAlign: "center", my: 1.5 }}>
          <Typography sx={{ fontSize: 26, fontWeight: "bold", pb: -1 }}>
            SAVE <MonetaryText value={settlementSaving}/>!
          </Typography>
        </Box>

        <Box id="settlement-message">
          <Typography sx={{ fontSize: 14 }}>
            Settle your account today with a <b>{settlementDiscount}%</b> discount on your current balance of{" "}
            <b>£{balance}</b>. Pay just <b>£{settlementOffer}</b> over 1 to 3 months!
          </Typography>
        </Box>

        <Box id="settlement-offer" sx={{ textAlign: "center", my: 1.5 }}>
          <Typography sx={{ fontSize: 46, fontWeight: "bold", pb: -1 }}>
            <MonetaryText value={settlementOffer}/>
          </Typography>
        </Box>

        <FormControl sx={{ width: "100%", pb: 4 }}>
          <RadioGroup
            name="settlement-options-radio-group"
            value={selectedOption}
            onChange={handleChange}
            sx={{ gap: 2, flexDirection: 'row' }}
          >
            <Grid sx={{ textAlign: "center" }} container spacing={2} id="settlement-options-container">
              {settlementOptions.map((option) => (
                <Grid key={option.id} item xs={4} sx={{ textAlign: "center" }}>
                  <Paper
                    component="label"
                    key={option.id}
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      boxShadow: "none",
                      borderRadius: '20px',
                      color: "#FFFFFF",
                      background: `linear-gradient(to bottom, ${localPalette.primary.main}, ${localPalette.primary.dark})`,
                    }}
                  >
                    <Radio
                      checked={option.selected}
                      value={option.id}
                      variant="soft"
                      size= "small"
                      sx={{
                        mb: 2,
                        backgroundColor: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "primary.light",
                        },
                        p: 1,                  
                      }} />

                    <Box sx={{ py: 1, height: { xs: "60px", sm: "40px" } }}>
                      <Typography sx={{ fontSize: 14, pb: -1 }}>
                        {option.label}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: -1 }}>
                        <MonetaryText value={option.instalmentAmount}/>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>

          </RadioGroup>
        </FormControl>

        <Box sx={{ pb: 2, textAlign: "center" }}>
          <Button
            onClick={confirmSettlement}
            variant='contained'
            disabled={false}
            sx={{
              textTransform: "none",
              borderRadius: 0,
              width: "80%",
              height: "45px",
              backgroundColor: "primary.contrastText",
              color: "primary.dark",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.dark",
              },
            }}
          >
            Confirm
          </Button>
        </Box>

      </Box>
    </Box>
  );
}

export default Settlement;