import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const UpdateHomeNumberPage = ({ handleBackToMain, handleInputChange, clientConfig, handleUpdateHomeNumber, error, contactMobileNumber: contactHomeNumber }) => {
  if(contactHomeNumber === "No Number"){
    contactHomeNumber = "";
  }
  return(
    <Box
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "background.default",
      height: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        mb: 2, 
        p: 1, 
      }}
    >
      <IconButton onClick={handleBackToMain} sx={{ mr: 1 }}>
        <ArrowBackIcon sx= {{color: "primary.dark"}}/>
      </IconButton>
      <Typography variant="body1" fontWeight="bold">Update my home number</Typography>
    </Box>

    <Typography variant="body2" sx={{ mb: 2 }}>
      Please enter your new home number in the field below to update your contact information and ensure you receive all important communications.
    </Typography>
    <Typography variant="body2" fontWeight="bold"
    sx={{
      display: "flex",
      alignItems: "left",
      width: "100%",
      mb: 1
    }}>Home number</Typography>
    <TextField  
      name="home"
      label="Enter your new home number." 
      error={Boolean(error)}
      helperText={error}
      value={contactHomeNumber}
      onChange={handleInputChange}
      sx={{
        display: "flex",
        alignItems: "left",
        width: "100%",
        mb: 1,
    }}>
    </TextField>
    <Button
          onClick={handleUpdateHomeNumber}
          fullWidth
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: clientConfig?.theme.primary,
            "&:hover": {
              backgroundColor: clientConfig?.theme.primary,
            },
          }}
        >
          Update home number
        </Button>
  </Box>
  );
}

UpdateHomeNumberPage.propTypes = {
  handleBackToMain: PropTypes.func.isRequired, 
  handleInputChange: PropTypes.func.isRequired,
  clientConfig: PropTypes.object, 
  handleUpdateHomeNumber: PropTypes.func.isRequired,
  error: PropTypes.string,
  contactHomeNumber: PropTypes.string
}


export default UpdateHomeNumberPage