import React, { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import LoginBanner from "../nav/LoginBanner";
import { useNav } from "../../context/NavProvider";
import { useUtils } from "../../context/UtilsProvider";
import { useAuth } from "../../context/AuthProvider";
import IdvLoginCard from "../auth/IdvLogInCard";
import ScriptLoader from "../account/ScriptLoader";

function IdvAuthPage() {

  const { setShowFooter } = useNav();
  setShowFooter(false);

  const { setFormState, formState } = useUtils();
  const { useGetAuthUser } = useAuth();
  const { user, isLoading } = useGetAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!formState) {
      setFormState({
        accountReference: "",
        postcode: "",
        dateOfBirth: "",
      });
    }
  }, [formState, setFormState]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (user) {
    navigate("/");
    return null;
  }

  return (
    <>
      <ScriptLoader />
      {formState && (
        <>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <LoginBanner />
          </Box>
          <Container sx={{ mt: 4, mb: 7 }}>
            <Box
              sx={{
                mt: 0,
              }}
            >
              <IdvLoginCard />
            </Box>
          </Container>
          {setShowFooter(true)}
        </>
      )}
    </>
  );
}

export default IdvAuthPage;
